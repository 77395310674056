import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Check } from './icons';

const Container = styled.div`
  display: flex;
  padding: 30px;
  font-size: 18px;
  font-weight: 300;
  background-color: rgba(211, 228, 229, 0.5);
  border-radius: 2px;
  align-items: center;
  border: solid 1px #60c6c3;
`;

const CheckBox = styled.div`
  position: relative;
  display: inline-block;
  min-width: 25px;
  width: 25px;
  height: 25px;
  background: #fff;
  margin-right: 15px;
  border: solid 1px #60c6c3;
  border-radius: 2px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 5px;
    left: 3px;
  }
`;

export default class SelectBox extends React.Component {
  static propTypes = {
    onToggle: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    children: PropTypes.node,
  };

  _renderChildrenWithProps = () => {
    const { onToggle, children } = this.props;
    return React.Children.map(children, child =>
      React.cloneElement(child, { onClick: onToggle })
    );
  };

  render() {
    const { checked, onToggle } = this.props;

    return (
      <Container {...this.props}>
        <CheckBox onClick={onToggle}>
          {checked && <Check width="21px" height="15px" />}
        </CheckBox>
        {this._renderChildrenWithProps()}
      </Container>
    );
  }
}
