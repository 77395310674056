import React, { useState } from 'react';

export interface ModalProps {
  isDisplaying: boolean;
  setIsDisplaying: (isDisplaying: boolean) => void;
  content: React.ReactNode;
  setShouldPreventDismiss: (shouldPreventDismiss: boolean) => void;
  setContent: (content: ModalContent) => void;
}

type ModalContentFun = () => React.ReactElement;

type ModalContent = React.ReactElement | ModalContentFun;

export const ModalContext = React.createContext({
  isDisplaying: false,
  setIsDisplaying: (_displaying: boolean) => {},
  setContent: (_content: () => React.ReactElement) => {},
  setShouldPreventDismiss: (_shouldPrevent: boolean) => {},
  content: <div />,
  shouldPreventDismiss: false,
});

const ModalProvider = props => {
  const [isDisplaying, setIsDisplaying] = useState(false);
  const [content, setContent] = useState(<div />);
  const [shouldPreventDismiss, setShouldPreventDismiss] = useState(false);

  const value = {
    isDisplaying,
    setShouldPreventDismiss,
    shouldPreventDismiss,
    setIsDisplaying: (value: boolean) => {
      setIsDisplaying(value);
    },
    content,
    setContent: (value: () => React.ReactElement) => {
      setContent(value);
    },
  };

  return <ModalContext.Provider value={value} {...props} />;
};

export default ModalProvider;
