'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PurchasesApi = exports.CasesApi = exports.AdminPurchasesApi = undefined;

var _adminPurchasesApi = require('./admin-purchases-api');

var _AdminPurchasesApi = _interopRequireWildcard(_adminPurchasesApi);

var _casesApi = require('./cases-api');

var _CasesApi = _interopRequireWildcard(_casesApi);

var _purchasesApi = require('./purchases-api');

var _PurchasesApi = _interopRequireWildcard(_purchasesApi);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.AdminPurchasesApi = _AdminPurchasesApi;
exports.CasesApi = _CasesApi;
exports.PurchasesApi = _PurchasesApi;