'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultPreneedPaymentPlanTasks = exports.defaultPreneedTrustTasks = exports.defaultPreneedDetailsTasks = exports.defaultPreneedPaperworkTasks = exports.defaultPreneedArrangementTasks = exports.defaultClosedTasks = exports.defaultAshesReceivedTasks = exports.defaultAshesMailedTasks = exports.defaultCremationCompletedTasks = exports.defaultPaperworkFiledTasks = exports.defaultDeathCertsOrderedTasks = exports.defaultCrematoryPreparedTasks = exports.defaultDeathRegisteredTasks = exports.defaultRemovalCompletedTasks = exports.TASK_DEFS = exports.STAGE_DEFS = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.allTasksForStageType = allTasksForStageType;
exports.defForType = defForType;
exports.readableType = readableType;
exports.getStageType = getStageType;
exports.readableStageType = readableStageType;
exports.stagesState = stagesState;
exports.blockersForStageType = blockersForStageType;
exports.blockersForTaskType = blockersForTaskType;
exports.getOrdinal = getOrdinal;
exports.getCurrentState = getCurrentState;
exports.createLists = createLists;
exports.isTaskComplete = isTaskComplete;
exports.isTaskSkippable = isTaskSkippable;
exports.taskAutomationCaption = taskAutomationCaption;
exports.isTaskHighlighted = isTaskHighlighted;

var _dateFns = require('date-fns');

var DateFns = _interopRequireWildcard(_dateFns);

var _util = require('../util');

var _constants = require('../constants');

var _checklistAction = require('./checklist-action');

var ChecklistAction = _interopRequireWildcard(_checklistAction);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// $FlowFixMe TODO: Remove this dep from tulip-core
var STAGE_DEFS = exports.STAGE_DEFS = {
  paid: {},
  arrangement_completed: {},
  removal_dispatched: {},
  removal_completed: {},
  death_registered: {},
  death_certs_ordered: {},
  ready_for_cremation: {}, // Paperwork filled;
  paperwork_filed: {},
  cremation_completed: {},
  ashes_mailed: {},
  ashes_scattered_at_sea: {},
  ashes_received: {},
  closed: {},
  // pre-need
  preneed_arrangement_signed: {},
  preneed_arrangement: {},
  preneed_paperwork_sent: {},
  preneed_trust_received: {},
  preneed_details_sent: {},
  // preneed-payment-plan
  preneed_payment_plan: {},
  preneed_activation: {},
  preneed_welcome_package: {}
};


var STAGE_BLOCKERS = [{ subject: 'removal_dispatched', reliesOn: ['paid'] }, { subject: 'removal_completed', reliesOn: ['removal_dispatched'] }, { subject: 'death_registered', reliesOn: ['paid'] }, { subject: 'ready_for_cremation', reliesOn: ['death_registered'] }, { subject: 'death_certs_ordered', reliesOn: ['death_registered'] }, { subject: 'paperwork_filed', reliesOn: ['death_registered'] }, { subject: 'cremation_completed', reliesOn: ['paperwork_filed'] }, { subject: 'ashes_mailed', reliesOn: ['cremation_completed'] }, { subject: 'ashes_received', reliesOn: ['ashes_mailed'] }, { subject: 'closed', reliesOn: ['ashes_received'] },
// preneed
{ subject: 'preneed_arrangement_signed', reliesOn: ['paid'] }, { subject: 'preneed_arrangement', reliesOn: ['preneed_arrangement_signed'] }, { subject: 'preneed_paperwork_sent', reliesOn: ['preneed_arrangement'] }, { subject: 'preneed_trust_received', reliesOn: ['preneed_arrangement'] }, { subject: 'preneed_details_sent', reliesOn: ['preneed_paperwork_sent'] }];

var TASK_BLOCKERS = [{ subject: 'afcts_sent', reliesOn: ['afcts_signed'] }, { subject: 'ra_signed', reliesOn: ['afcts_sent'] }, { subject: 'payment_285_sent', reliesOn: ['ra_signed'] }, { subject: 'commission_received', reliesOn: ['payment_285_sent'] }, { subject: '24th_payment_complete', reliesOn: ['payment_285_sent'] }];

function inAreas() {
  var areas = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  return function (purchase) {
    if (!purchase.state) {
      return false;
    }
    var areaStates = areas.map(function (area) {
      return area.state.name;
    });
    return areaStates.includes(purchase.state);
  };
}

function decedentLocationTypeIn() {
  var locations = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  return function (purchase) {
    return locations.includes(purchase.decedentLocationType);
  };
}

function _atNeedType() {
  return function (purchase) {
    return (
      // purchase is activated
      purchase.activatedAt !== null && purchase.purchaseType === _constants.PURCHASE_TYPE.PRENEED ||
      // or has default at-need type
      [_constants.PURCHASE_TYPE.IMMEDIATELY, _constants.PURCHASE_TYPE.SOON].includes(purchase.purchaseType)
    );
  };
}

function preneedType() {
  return function (purchase) {
    return [_constants.PURCHASE_TYPE.PRENEED].includes(purchase.purchaseType);
  };
}

function applyConditions() {
  var conditionHandlers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  // every handler must return a boolean
  return function (purchase) {
    return conditionHandlers.every(function (handler) {
      return handler(purchase);
    });
  };
}

var TASK_CONDITIONS = {
  isDeprecated: function isDeprecated() {
    return false;
  },
  atNeedType: function atNeedType(purchase) {
    return applyConditions([_atNeedType()])(purchase);
  },
  inCalOrFLOrNYNJArea: function inCalOrFLOrNYNJArea(purchase) {
    return applyConditions([_atNeedType(), inAreas([_constants.AREAS.northernCalifornia, _constants.AREAS.southernCalifornia, _constants.AREAS.floridaGulfCoast, _constants.AREAS.floridaSpaceCoast])])(purchase);
  },
  inCalArea: function inCalArea(purchase) {
    return applyConditions([_atNeedType(), inAreas([_constants.AREAS.northernCalifornia, _constants.AREAS.southernCalifornia])])(purchase);
  },
  inWashingtonArea: function inWashingtonArea(purchase) {
    return applyConditions([_atNeedType(), inAreas([_constants.AREAS.washingtonSeattle])])(purchase);
  },
  inCalOrFlOrNYNJAreaAndNotReleaseFromCoroner: function inCalOrFlOrNYNJAreaAndNotReleaseFromCoroner(purchase) {
    return applyConditions([_atNeedType(), inAreas([_constants.AREAS.northernCalifornia, _constants.AREAS.southernCalifornia, _constants.AREAS.floridaSpaceCoast, _constants.AREAS.floridaGulfCoast]), decedentLocationTypeIn(['another_funeral_home', 'funeral_home', 'hospital', 'nursing_home', 'residence'])])(purchase);
  },
  releaseFromCoroner: function releaseFromCoroner(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['coroner'])])(purchase);
  },
  releaseFromFacility: function releaseFromFacility(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['another_funeral_home', 'coroner', 'funeral_home', 'hospital'])])(purchase);
  },
  releaseFromFuneralHome: function releaseFromFuneralHome(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['another_funeral_home', 'funeral_home'])])(purchase);
  },
  releaseFromFuneralHomeOrHospital: function releaseFromFuneralHomeOrHospital(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['another_funeral_home', 'funeral_home', 'hospital'])])(purchase);
  },
  releaseFromFuneralHomeOrNursingHomeOrResidence: function releaseFromFuneralHomeOrNursingHomeOrResidence(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['another_funeral_home', 'funeral_home', 'nursing_home', 'residence'])])(purchase);
  },
  releaseFromHospital: function releaseFromHospital(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['hospital'])])(purchase);
  },
  releaseFromNursingHomeOrResidence: function releaseFromNursingHomeOrResidence(purchase) {
    return applyConditions([_atNeedType(), decedentLocationTypeIn(['nursing_home', 'residence'])])(purchase);
  },
  isHandDelivery: function isHandDelivery(purchase) {
    return applyConditions([function (purchase) {
      return purchase.deliveryOption === _constants.DELIVERY_OPTION.HAND_DELIVERY;
    }, _atNeedType()])(purchase);
  },
  isMailDelivery: function isMailDelivery(purchase) {
    return applyConditions([function (purchase) {
      return purchase.deliveryOption === _constants.DELIVERY_OPTION.MAIL;
    }, _atNeedType()])(purchase);
  },
  isPickUp: function isPickUp(purchase) {
    return applyConditions([function (purchase) {
      return purchase.deliveryOption === _constants.DELIVERY_OPTION.IN_PERSON_COLLECTION;
    }, _atNeedType()])(purchase);
  },
  isScatterAtSea: function isScatterAtSea(purchase) {
    return applyConditions([function (purchase) {
      return purchase.deliveryOption === _constants.DELIVERY_OPTION.SCATTER_AT_SEA;
    }, _atNeedType()])(purchase);
  },
  isPreneedUpfront: function isPreneedUpfront(purchase) {
    return applyConditions([preneedType(), function (purchase) {
      return purchase.hasInstallments === false;
    }])(purchase);
  },
  isPreneedInstallments: function isPreneedInstallments(purchase) {
    return applyConditions([preneedType(), function (purchase) {
      return purchase.hasInstallments === true;
    }])(purchase);
  }
};

var TASK_DEFS = exports.TASK_DEFS = {
  payment_info_provided: {
    stage: 'paid',
    isDefault: true
  },
  payment_collected: {
    stage: 'paid',
    isDefault: true
  },
  force_show_in_active: {
    stage: 'paid',
    label: 'Collected not paid',
    isDefault: true
  },

  paperwork_signed: {
    stage: 'arrangement_completed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  nok_id_received: {
    stage: 'arrangement_completed',
    label: 'NOK ID received',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  coroner_release_signed: {
    stage: 'arrangement_completed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },
  coroner_fees_collected: {
    stage: 'arrangement_completed',
    label: 'Coroner\'s fees collected from family',
    skippable: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },
  dpoh_received: {
    stage: 'arrangement_completed',
    label: 'DPOH received',
    skippable: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  release_with_facility: {
    stage: 'removal_dispatched',
    label: 'Release sent to facility/facility already has signed release',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFacility
  },
  release_authorized_with_removal: {
    stage: 'removal_dispatched',
    label: 'Release sent to removal company / informed that facility already has release',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFuneralHomeOrHospital
  },
  verbal_release: {
    stage: 'removal_dispatched',
    conditionHandler: TASK_CONDITIONS.releaseFromNursingHomeOrResidence,
    isDefault: false
  },
  removal_company_dispatched: {
    stage: 'removal_dispatched',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  coroner_fees_paid: {
    label: "Coroner's fees paid by Tulip",
    stage: 'removal_dispatched',
    skippable: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },
  confirm_we_have_valid_nok_dpoah: {
    stage: 'removal_dispatched',
    label: 'Confirm we have valid NOK/DPOAH',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  confirm_payment_for_tulip_services_has_been_collected: {
    stage: 'removal_dispatched',
    label: 'Confirm payment for Tulip services has been collected',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  take_payment_for_coroner_fees_if_applicable: {
    stage: 'removal_dispatched',
    label: 'Take payment for coroner fees (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  collect_dispatch_info_from_authorized_personnel: {
    stage: 'removal_dispatched',
    label: 'Collect dispatch info from authorized personnel',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFuneralHomeOrNursingHomeOrResidence
  },

  determine_urgency_of_dispatch: {
    stage: 'removal_dispatched',
    label: 'Determine urgency of dispatch',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  determine_transport_partner: {
    stage: 'removal_dispatched',
    label: 'Determine transport partner',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  determine_which_release_form_or_verbal_release_is_required_by_f: {
    stage: 'removal_dispatched',
    label: 'Determine which release form or verbal release is required by facility. If Tulip release form is used, make sure Signing Package is complete',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFacility
  },

  prepare_release_form_with_nok_info_if_applicable: {
    stage: 'removal_dispatched',
    label: 'Prepare release form with NOK info (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  send_release_form_out_for_signature_if_not_yet_signed: {
    stage: 'removal_dispatched',
    label: 'Send release form out for signature (if not yet signed)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  send_signed_release_form_and_confirm_delivery_if_applicable: {
    stage: 'removal_dispatched',
    label: 'Send signed release form and confirm delivery (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromHospital
  },

  upload_signed_release_form_to_documents_if_not_in_signing_packa: {
    stage: 'removal_dispatched',
    label: 'Upload signed release form to Documents (if not in Signing Package)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  pull_authorization_for_release_page_from_signing_package_send_s: {
    stage: 'removal_dispatched',
    label: 'Pull Authorization for Release page from Signing Package, send signed release form, and confirm delivery (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFuneralHome
  },

  call_facility_to_verify_dcd_is_ready_confirm_pickup_time_hours: {
    stage: 'removal_dispatched',
    label: 'Call facility to verify DCD is ready, confirm pickup time/hours with facility, ask for room # if DCD is not in morgue, and ask for any special instructions for transport partner',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromHospital
  },

  send_signed_release_form_to_facility_and_confirm_delivery_if_ap: {
    stage: 'removal_dispatched',
    label: 'Send signed release form to facility and confirm delivery (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  call_facility_to_verify_dcd_is_ready_schedule_confirm_pickup_ti: {
    stage: 'removal_dispatched',
    label: 'Call facility to verify DCD is ready, schedule/confirm pickup time/hours with facility, and confirm address of DCD location',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromFuneralHome
  },

  call_facility_to_verify_dcd_is_ready_verify_fee_total_if_applic: {
    stage: 'removal_dispatched',
    label: "Call facility to verify DCD is ready, verify fee total (if applicable), verify our NOK matches with facility's records",
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  contact_family_to_collect_additional_coroner_fee_if_applicable: {
    stage: 'removal_dispatched',
    label: 'Contact family to collect additional coroner fee (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  check_pickup_time_hours_of_facility: {
    stage: 'removal_dispatched',
    label: 'Check pickup time/hours of facility',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  alert_relevant_personnel_to_make_coroner_payment: {
    stage: 'removal_dispatched',
    label: 'Alert relevant personnel to make coroner payment',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromCoroner
  },

  email_transport_partner_to_dispatch_with_any_special_instructio: {
    stage: 'removal_dispatched',
    label: 'Email transport partner to dispatch with any special instructions in subject line and any necessary release forms attached (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  call_transport_partner_to_dispatch_confirm_receipt_of_dispatch: {
    stage: 'removal_dispatched',
    label: 'Call transport partner to dispatch, confirm receipt of dispatch, and ask for ETA (if applicable)',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  add_dispatch_info_email_url_and_name_of_confirming_transport_me: {
    stage: 'removal_dispatched',
    label: 'Add dispatch info (email URL) and name of confirming transport member in case comments',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  add_asd_dispatch_link_in_case_comments: {
    stage: 'removal_dispatched',
    label: 'Add ASD dispatch link in case comments',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.releaseFromNursingHomeOrResidence
  },

  confirm_delivery_of_dcd_to_crematory: {
    stage: 'removal_completed',
    label: 'Confirm delivery of DCD to crematory',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  decedent_in_our_care: {
    stage: 'removal_completed',
    label: 'Upload First Call Sheet',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  messaged_decedent_in_care: {
    stage: 'removal_completed',
    label: 'Message family to inform them that DCD is in our care',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    automationCaption: 'Send email to contacts'
  },

  // california/FL/NY/NJ-located death_cert tasks
  worksheet_faxed_to_doctor: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner
  },
  worksheet_received_from_doctor: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner
  },
  vitals_entered_into_edrs: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    label: 'Vitals entered into EDRS'
  },
  causes_entered_into_edrs: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner,
    label: 'Causes entered into EDRS'
  },
  causes_approved_by_county: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner
  },
  attestation_sent_to_doctor: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner
  },
  attestation_faxed_to_state: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isNOTreleasefromCoroner,
    label: 'Attestation signed and faxed to state'
  },
  personal_info_submitted: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inCalOrFLOrNYNJArea,
    label: 'Personal information validated and record submitted to county'
  },
  permit_issued_and_sent: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inCalOrFLOrNYNJArea,
    label: 'Permit issued by county and sent to state'
  },
  death_cert_working_copy_saved: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inCalOrFLOrNYNJArea
  },

  // Washington-located death_cert tasks
  enter_vitals_into_WDRS: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  vitals_entered_into_WDRS: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  submit_medical_portion_to_certifier: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  approve_demographics: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  medical_portion_is_approved_the_deputy_registrar: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  burial_transit_permit_is_printed: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },
  death_cert_working_copy_saved_wdrs: {
    stage: 'death_registered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.inWashingtonArea
  },

  admin_sent_to_crematory: {
    stage: 'ready_for_cremation',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    label: 'Permit, authorization for cremation & working copy of DC sent to crematory'
  },
  messaged_cremation_date: {
    stage: 'ready_for_cremation',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    label: 'Template: Messaged family to let know cremation date',
    automationCaption: 'Send email to contacts'
  },

  death_certs_ordered: {
    stage: 'death_certs_ordered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    label: 'Death certs ordered from county'
  },
  sent_check_for_permit: {
    stage: 'death_certs_ordered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  messaged_death_certs_ordered: {
    stage: 'death_certs_ordered',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    label: 'Template: Messaged family to let know death certs ordered',
    automationCaption: 'Send email to contacts'
  },

  statement_files: {
    stage: 'paperwork_filed',
    label: 'Statement of goods and services filed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  death_cert_filed: {
    stage: 'paperwork_filed',
    label: 'Working copy of death certificate filed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  signing_package_filed: {
    stage: 'paperwork_filed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  id_dpoh_filed: {
    stage: 'paperwork_filed',
    label: 'ID/DPOH filed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },
  condolence_card_sent: {
    stage: 'paperwork_filed',
    label: 'Condolence card sent to family',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  cremation_performed: {
    stage: 'cremation_completed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  ashes_mailed: {
    stage: 'ashes_mailed',
    label: 'Ashes mailed to family',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isMailDelivery
  },
  ashes_ready_for_pickup: {
    stage: 'ashes_mailed',
    label: 'PICK UP: Ashes ready for pickup from crematorium',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPickUp
  },
  messaged_ashes_mailed: {
    stage: 'ashes_mailed',
    label: 'Messaged family to let know ashes mailed + tracking number',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isMailDelivery,
    automationCaption: 'Send email to contacts'
  },
  messaged_delivery_date: {
    stage: 'ashes_mailed',
    label: 'HAND DELIVERY: Messaged family to let know delivery date',
    highlighted: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isHandDelivery
  },
  delivered_by_hand: {
    stage: 'ashes_mailed',
    label: 'HAND DELIVERY: Ashes delivered by hand',
    highlighted: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isHandDelivery
  },
  verify_scatter_at_sea: {
    stage: 'ashes_mailed',
    label: 'Notify family that ashes will be scattered at sea with no witness and no family present',
    highlighted: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isScatterAtSea
  },

  family_phoned_re_ashes: {
    stage: 'ashes_received',
    label: 'Phone family to check ashes arrived',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isMailDelivery
  },
  family_phoned_re_ashes_pick_up: {
    stage: 'ashes_received',
    label: 'PICK UP: Phone family to check ashes were picked up',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPickUp
  },
  family_phoned_check_happy: {
    stage: 'ashes_received',
    label: 'HAND DELIVERY: Phoned family to check they are happy',
    highlighted: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isHandDelivery
  },
  messaged_review_email: {
    stage: 'ashes_received',
    label: 'Template: Sent review email',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isDeprecated
  },
  delighted_contact_added: {
    stage: 'ashes_received',
    label: 'Add to contact via Delighted list',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType,
    automationCaption: 'Send email address to Delighted'
  },
  ashes_scattered_at_sea: {
    stage: 'ashes_received',
    label: 'Ashes successfully scattered at sea',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isScatterAtSea
  },
  family_phoned_ashes_scattered_at_sea: {
    stage: 'ashes_received',
    label: "SCATTER AT SEA: Notify family to let them know that their loved one's ashes were successfully scattered at sea",
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isScatterAtSea
  },

  closed: {
    stage: 'closed',
    autoComplete: true,
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.atNeedType
  },

  // PRE-NEED
  // arrangement
  preneed_sign_package_signed: {
    label: 'Signing Package signed',
    stage: 'preneed_arrangement_signed',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  preneed_sign_package_checked: {
    label: 'Signing Package checked',
    stage: 'preneed_arrangement',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  preneed_sign_package_filed: {
    label: 'Signing Package filed',
    stage: 'preneed_arrangement',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  preneed_statement_filed: {
    label: 'Statement of Goods and Services filed',
    stage: 'preneed_arrangement',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },

  // paperwork
  emailed_trust: {
    label: 'Emailed trust paperwork to AFCTS',
    stage: 'preneed_paperwork_sent',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  emailed_global: {
    label: 'Mailed global paperwork to RA',
    stage: 'preneed_paperwork_sent',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  // trust
  trust_received: {
    label: 'Trust paperwork received',
    stage: 'preneed_trust_received',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },

  // details sent
  trust_conf: {
    label: 'Email: trust confirmation to the family',
    stage: 'preneed_details_sent',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  trust_filed: {
    label: 'File trust paperwork',
    stage: 'preneed_details_sent',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },
  global_filed: {
    label: 'File global paperwork',
    stage: 'preneed_details_sent',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedUpfront
  },

  // preneed payment plan
  afcts_signed: {
    label: 'AFCTS Paperwork signed and checked',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  },
  afcts_sent: {
    label: 'Send AFCTS new payment plan contract',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  },
  ra_signed: {
    label: 'RA Paperwork Signed and checked',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  },
  payment_285_sent: {
    label: 'Send RA contract & payment [$285]',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  },
  commission_received: {
    label: '$110 commission received from RA',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  },
  '24th_payment_complete': {
    label: '24th Payment complete',
    stage: 'preneed_payment_plan',
    isDefault: false,
    conditionHandler: TASK_CONDITIONS.isPreneedInstallments
  }
};

var taskDefKeys = Object.keys(TASK_DEFS);

function allTasksForStageType(stageType) {
  return Object.entries(TASK_DEFS)
  // $FlowFixMe
  .filter(function (_ref) {
    var _ref2 = (0, _slicedToArray3.default)(_ref, 2),
        value = _ref2[1];

    return value.stage === stageType;
  })
  // $FlowFixMe
  .map(function (_ref3) {
    var _ref4 = (0, _slicedToArray3.default)(_ref3, 1),
        key = _ref4[0];

    return key;
  });
}

var defaultRemovalCompletedTasks = exports.defaultRemovalCompletedTasks = allTasksForStageType('removal_completed');
var defaultDeathRegisteredTasks = exports.defaultDeathRegisteredTasks = allTasksForStageType('death_registered');
var defaultCrematoryPreparedTasks = exports.defaultCrematoryPreparedTasks = allTasksForStageType('ready_for_cremation');
var defaultDeathCertsOrderedTasks = exports.defaultDeathCertsOrderedTasks = allTasksForStageType('death_certs_ordered');
var defaultPaperworkFiledTasks = exports.defaultPaperworkFiledTasks = allTasksForStageType('paperwork_filed');
var defaultCremationCompletedTasks = exports.defaultCremationCompletedTasks = allTasksForStageType('cremation_completed');
var defaultAshesMailedTasks = exports.defaultAshesMailedTasks = allTasksForStageType('ashes_mailed');
var defaultAshesReceivedTasks = exports.defaultAshesReceivedTasks = allTasksForStageType('ashes_received');
var defaultClosedTasks = exports.defaultClosedTasks = allTasksForStageType('closed');
var defaultPreneedArrangementTasks = exports.defaultPreneedArrangementTasks = allTasksForStageType('preneed_arrangement_signed').concat(allTasksForStageType('preneed_arrangement'));
var defaultPreneedPaperworkTasks = exports.defaultPreneedPaperworkTasks = allTasksForStageType('preneed_paperwork_sent');
var defaultPreneedDetailsTasks = exports.defaultPreneedDetailsTasks = allTasksForStageType('preneed_details_sent');
var defaultPreneedTrustTasks = exports.defaultPreneedTrustTasks = allTasksForStageType('preneed_trust_received');
var defaultPreneedPaymentPlanTasks = exports.defaultPreneedPaymentPlanTasks = allTasksForStageType('preneed_payment_plan');

function defForType(type) {
  return TASK_DEFS[type] || null;
}

function readableType(type) {
  var taskDef = TASK_DEFS[type];
  if (taskDef && taskDef.label) return taskDef.label;
  return (0, _util.capitalize)(type).replace(/_/g, ' ');
}

function getStageType(checklistTask) {
  var taskDef = TASK_DEFS[checklistTask.type];
  return taskDef ? taskDef.stage : null;
}

function readableStageType(type) {
  var stageDef = STAGE_DEFS[type];
  if (stageDef && stageDef.label) return stageDef.label;
  return (0, _util.capitalize)(type).replace(/_/g, ' ');
}

/**
 * For each stage, return its status, and the date of the action that resolved
 * it (otherwise null).
 */
function stagesState(tasks) {
  return tasks.reduce(function (carry, task) {
    var stageType = getStageType(task);

    if (stageType === null) return carry;

    if (carry[stageType] == null) {
      carry[stageType] = {
        result: 'skipped',
        resolvedAt: null
      };
    }

    // If the stage is already marked as uncompleted, we can leave it as is
    if (carry[stageType].result === null) {
      return carry;
    }

    // Now incorporate the current task
    var taskState = getCurrentState(task);

    if (taskState.status === null) {
      // Uncompleted tasks override everything
      carry[stageType] = {
        result: null,
        resolvedAt: null
      };
    } else if (taskState.status === 'completed') {
      // Completed overrides skipped
      carry[stageType] = {
        result: 'completed',
        resolvedAt: DateFns.max(taskState.recordedAt, carry[stageType].resolvedAt)
      };
    } else if (taskState.status === 'skipped' && carry[stageType].result !== 'completed') {
      carry[stageType] = {
        result: 'skipped',
        resolvedAt: DateFns.max(taskState.recordedAt, carry[stageType].resolvedAt)
      };
    }
    return carry;
  }, {});
}

function tasksState(tasks) {
  var states = {};
  tasks.forEach(function (task) {
    return states[task.type] = getCurrentState(task);
  });

  return states;
}

function blockersForStageType(type) {
  var data = STAGE_BLOCKERS.find(function (b) {
    return b.subject === type;
  });
  if (!data) return [];
  return data.reliesOn;
}

function blockersForTaskType(type) {
  var data = TASK_BLOCKERS.find(function (b) {
    return b.subject === type;
  });
  if (!data) return [];
  return data.reliesOn;
}

/**
 * Not really safe as Object.keys doesn't guarantee order, but let's try anyway
 */
function getOrdinal(task) {
  return taskDefKeys.indexOf(task.type);
}

function getCurrentState(task) {
  var checklistActions = task.checklistActions;


  if (checklistActions.length === 0) {
    return {
      status: null,
      recordedById: null,
      recordedAt: null
    };
  }

  // TODO: Calculate it properly
  var latestAction = checklistActions.reduce(function (carry, action) {
    return DateFns.isAfter(action.recordedAt, carry.recordedAt) ? action : carry;
  });

  return {
    status: latestAction.status,
    recordedById: latestAction.recordedById,
    recordedAt: latestAction.recordedAt
  };
}

/**
 * Create the tasks list as available/completed/upcoming.
 */
function createLists(tasks) {
  var tasksByAvailability = {
    finished: [],
    available: [],
    blocked: []
  };

  tasks.sort(function (a, b) {
    return getOrdinal(a) - getOrdinal(b);
  });

  var ss = stagesState(tasks);
  var ts = tasksState(tasks);
  tasks.forEach(function (task) {
    // New Checklist Tasks should be filtered to avoid breaking this old stuff
    var isNewTask = !TASK_DEFS[task.type];

    if (isNewTask) {
      return;
    }

    var _getCurrentState = getCurrentState(task),
        status = _getCurrentState.status;

    if (status != null) {
      tasksByAvailability.finished.push(task);
    } else {
      // Work out if it's blocked
      var stageType = getStageType(task);
      var stageBlockers = blockersForStageType(stageType);

      var isStageBlocked = stageBlockers.reduce(function (carry, blocker) {
        if (carry || !ss[blocker]) {
          return carry;
        }
        return ss[blocker].result === null;
      }, false);
      var taskBlockers = blockersForTaskType(task.type);
      var isTaskBlocked = taskBlockers.reduce(function (carry, blocker) {
        if (carry || !ts[blocker]) {
          return carry;
        }
        ts[blocker].status === null;
      }, false);

      if (isStageBlocked || isTaskBlocked) {
        tasksByAvailability.blocked.push(task);
      } else {
        tasksByAvailability.available.push(task);
      }
    }
  });

  return tasksByAvailability;
}

function isTaskComplete(purchase, taskType) {
  var task = purchase.checklistTasks.find(function (task) {
    return task.type === taskType;
  });

  if (!task) {
    return false;
  }

  var taskState = getCurrentState(task);
  return taskState.status === 'completed';
}

function isTaskSkippable(taskType) {
  return TASK_DEFS[taskType].skippable || false;
}

function taskAutomationCaption(taskType) {
  return TASK_DEFS[taskType].automationCaption || null;
}

function isTaskHighlighted(taskType) {
  return TASK_DEFS[taskType].highlighted || false;
}