// @flow
import React from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '../../globalStyles';
import { Link } from 'tulip-ui';

type Props = {
  title?: string,
  byline?: string,
  body?: string,
  link?: Object,
  image?: object,
  className?: string,
};

export default function NewsCard({
  title,
  byline,
  body,
  link,
  image,
  className,
}: Props) {
  const Wrapper = styled.div`
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
  `;

  const HeadingLink = styled(Link)`
    font-family: Tiempos Headline;
    color: ${({ theme }) => theme.darkBlue};
    text-decoration: none;
    font-size: 23px;
    line-height: 1.4;
    margin: 0;
  `;

  const StyledLink = styled(Link)`
    font-size: 16px;
    color: ${({ theme }) => theme.darkBlue};
    text-decoration: none;
    font-weight: 700;
  `;

  const ImageContainer = styled.div`
    height: 273px;
    @media (max-width: ${BREAKPOINTS.SMALL}px) {
      height: 190px;
    }
  `;

  const FeaturedImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
  `;

  const TextWrapper = styled.div`
    padding: 32px;
    @media (max-width: ${BREAKPOINTS.SMALL}px) {
      padding: 20px;
    }
  `;

  const P = styled.p`
    font-size: 16px;
    line-height: 1.625;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: calc(3 * 1.625); /* 3 lines * line height */
  `;

  return (
    <Wrapper className={className}>
      {image && image.src ? (
        <ImageContainer>
          <Link href={link.url} target={link.target}>
            <FeaturedImage
              src={image.src}
              alt={image.alt ? image.alt : title}
            />
          </Link>
        </ImageContainer>
      ) : null}
      <TextWrapper>
        <HeadingLink href={link.url} target={link.target}>
          {title}
        </HeadingLink>
        <p>By {byline}</p>
        <P dangerouslySetInnerHTML={{ __html: body }} />
        <StyledLink href={link.url} target={link.target}>
          + Continue Reading
        </StyledLink>
      </TextWrapper>
    </Wrapper>
  );
}
