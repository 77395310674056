import * as React from 'react';
import Router from 'next/router';
import { Button } from 'tulip-ui';
import PropTypes from 'prop-types';

const LinkButton = ({ to, ...rest }) => (
  <Button
    onClick={e => {
      Router.push(to);
      e.preventDefault();
      e.stopPropagation();
    }}
    {...rest}
  />
);

LinkButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default LinkButton;
