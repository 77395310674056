// @flow
import * as React from 'react';
import Link from 'components/Link';
import { withRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { mediaQueries, Link as UILink, Button } from 'tulip-ui';
import PropTypes from 'prop-types';

import FullScreenMenu, { SpanishMenu } from './FullScreenMenu';
import PhoneNumber from './PhoneNumber';

const ITEMS = [
  {
    name: 'Our service',
    url: '/our-service',
    target: '_self',
  },
  {
    name: 'Pricing',
    url: '/pricing',
    target: '_self',
  },
  {
    name: 'Plan ahead',
    defaultTo: '/plan-ahead',
    activeLinks: ['/plan-ahead', '/soon'],
    target: '_self',
    render() {
      return (
        <React.Fragment>
          {/* <DropdownDescriptionContainer>
            <DropdownTitle>Plan ahead</DropdownTitle>
            <p>
              Plan ahead for peace of mind. Our prepaid plan allows you to lock
              in today’s price for services in the future. Our imminent need
              arrangements are for families expecting a loved one to pass in the
              next six months.
            </p>
          </DropdownDescriptionContainer> */}
          <DropdownLinkContainer>
            <DropDownLinkWrapper>
              <Link to="/plan-ahead">
                <DropDownLink>Prepaid plans</DropDownLink>
                <DropDownDescription>
                  Establish your final wishes and give your family peace of
                  mind.
                </DropDownDescription>
              </Link>
            </DropDownLinkWrapper>
            <DropDownLinkWrapper>
              <Link to="/soon">
                <DropDownLink>Imminent need</DropDownLink>
                <DropDownDescription>
                  Plan ahead so you or your family can focus on what matters.
                </DropDownDescription>
              </Link>
            </DropDownLinkWrapper>
          </DropdownLinkContainer>
        </React.Fragment>
      );
    },
  },
  {
    name: 'Service area',
    url: '/locations',
    target: '_self',
  },
  {
    name: 'Resources',
    url: '/resources',
    target: '_self',
    defaultTo: '/resources/',
    activeLinks: ['/resources/', '/resources/professionals/', '/faq'],
    render() {
      return (
        <React.Fragment>
          {/* <DropdownDescriptionContainer>
            <DropdownTitle>Plan ahead</DropdownTitle>
            <p>
              Plan ahead for peace of mind. Our prepaid plan allows you to lock
              in today’s price for services in the future. Our imminent need
              arrangements are for families expecting a loved one to pass in the
              next six months.
            </p>
          </DropdownDescriptionContainer> */}
          <DropdownLinkContainer>
            <DropDownLinkWrapper>
              <Link to="/resources/">
                <DropDownLink>Tulip Cares</DropDownLink>
              </Link>
            </DropDownLinkWrapper>
            <DropDownLinkWrapper>
              <Link to="/resources/professionals/">
                <DropDownLink>Tulip Cares Professional</DropDownLink>
              </Link>
            </DropDownLinkWrapper>
            <DropDownLinkWrapper>
              <Link to="/faq">
                <DropDownLink>FAQs</DropDownLink>
              </Link>
            </DropDownLinkWrapper>
          </DropdownLinkContainer>
        </React.Fragment>
      );
    },
  },
  {
    name: 'Reviews',
    url: '/reviews',
    target: '_self',
  },
  {
    name: 'Obituaries',
    url: 'https://www.tulip.joinobit.com/',
    target: '_blank',
  },
];
const DropDownDescription = styled.p`
  font-family: Brown;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1b2952;
  margin: 0 0 41px 0;
`;
const DropDownLink = styled.p`
  font-family: Brown;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1b2952;
  margin: 0 0 8px 0;
`;
const DropDownLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HEADER_XXLARGE_BREAKPOINT = 1390;

const Wrapper = styled.div`
  height: 65px;
  // position: fixed;
  // background-color: white;
  // width:100%;

  ${mediaQueries.xlarge`
    height: 64px;
  `};
`;

const HeaderWrapper = styled('header')`
  align-items: center;
  background: ${({ theme }) => theme.colors.plainColor};
  // background-color: white;
  box-sizing: border-box;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 0 16px;
  position: fixed;
  // left: 50%;
  // transform: translate(-50%);
  top: 0;
  width: 100%;
  // max-width: 1600px;
  // margin: 0 auto;
  z-index: 999999;

  ${mediaQueries.xlarge`
    height: 64px;
    padding: 0 16px 0 38px;
  `};
`;

const AreaLeft = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;

  ${mediaQueries.xlarge`
    height: 64px;
    justify-content: flex-start;
  `};
`;

const LogoLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mediaQueries.xlarge`
    margin-right: 40px;
  `};
`;

const Logo = styled('img')`
  height: 34px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.85;
  }

  ${mediaQueries.xlarge`
    height: 43px;
  `};
`;

const HeaderNav = styled.nav`
  display: none;
  height: 100%;

  ${mediaQueries.xlarge`
    display: flex;
  `};
`;

const LeftLink = styled(Link).attrs({
  active: ({ pathname, to }) => pathname && pathname === to,
})`
  ${({ active, theme }) => css`
    align-items: center;
    border-top: 7px solid ${theme.colors.plainColor};
    border-bottom: ${({ active, theme }) =>
      `7px solid ${
        active ? theme.colors.primaryButtonBackground : theme.colors.plainColor
      }`};
    box-sizing: border-box;
    color: ${active ? theme.colors.linkDark : theme.colors.linkBase};
    display: flex;
    flex-direction: column;
    font-size: 16px;
    height: 100%;
    justify-content: center;
    margin-right: 32px;
    transition: color 0.2s;
    ${theme.fonts.text.fontFamily};
    ${active
      ? theme.fonts.text.fontWeight.regular
      : theme.fonts.text.fontWeight.light};
    &:hover {
      color: ${theme.textHard};
    }
    @media (max-width: ${HEADER_XXLARGE_BREAKPOINT}px) {
      font-size: 16px;
      margin-right: 12px;
    }
  `};
`;

const AreaRight = styled.div`
  align-items: center;
  display: none;
  background-color: white;

  ${mediaQueries.xlarge`
    display: flex;
  `};

  @media (max-width: ${HEADER_XXLARGE_BREAKPOINT}px) {
    a {
      font-size: 16px;
    }
  }
`;

const Call = styled('a')`
  color: ${({ theme }) => theme.colors.linkDark};
  font-size: 24px;
  margin-right: 15px;
  transition: color 0.2s;
  min-width: 230px;

  ${({ theme }) => theme.fonts.text.fontFamily};
  ${({ theme }) => theme.fonts.text.fontWeight.regular};

  &:hover {
    color: ${({ theme }) => theme.textSoft};
  }

  @media (max-width: ${HEADER_XXLARGE_BREAKPOINT}px) {
    font-size: 24px;
    margin-right: 16px;
  }
`;

const CallCheckout = styled(Call)`
  color: ${({ theme }) => theme.colors.bodyTextBaseDark};
  font-size: 16px;

  > img {
    width: 14px;
    padding: 5px;
    background: ${({ theme }) => theme.textSpecial};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 34px;
  }
`;

const MenuToggle = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(/img/menu.svg);
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;

  ${p =>
    p.isOpen
      ? `
          background-image: url(/img/menu-close.svg);
          background-size: 22px 22px;
        `
      : ''};


      ${mediaQueries.xlarge`
        display: none;
      `};
  }
`;

const Dropdown = styled.div`
  ${({ isActive, theme }) => css`
    box-sizing: border-box;
    color: ${isActive ? theme.colors.linkDark : theme.colors.linkBase};
    cursor: default;
    display: flex;
    justify-content: center;
    padding-right: 32px;
    ${theme.fonts.text.fontFamily};
    ${isActive
      ? theme.fonts.text.fontWeight.regular
      : theme.fonts.text.fontWeight.light};

    > a {
      display: flex;
      height: calc(100% - 14px);
      align-items: center;
      font-size: 16px;
      border-bottom: 7px solid
        ${isActive
          ? theme.colors.primaryButtonBackground
          : theme.colors.plainColor};
      border-top: 7px solid ${theme.colors.plainColor};
    }

    &:hover {
      > a {
        color: ${theme.textHard};
        border-bottom: 7px solid ${theme.colors.primaryButtonBackground};
      }
    }

    @media (max-width: ${HEADER_XXLARGE_BREAKPOINT}px) {
      padding-right: 12px;
    }
  `};
`;

const DropdownWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 64px;
  color: ${({ theme }) => theme.textSoft};
  font-size: 20px;
  border-top: 1px solid #dff9f7;
  // border-bottom: 1px solid #dff9f7;
  @media (max-width: ${HEADER_XXLARGE_BREAKPOINT}px) {
    font-size: 18px;
  }
`;

const DropdownBox = styled.div`
  display: flex;
  position: relative;
  left: 300px;
  background: #fff;
  width: 400px;

  ${Dropdown}:not(:hover) & {
    display: none;
  }

  a {
    display: block;
    line-height: 34px;
    padding: 5px 0px 5px 0px;

    &:hover {
      color: ${({ theme }) => theme.textHard};
    }
  }
`;

const DropdownBoxResources = styled.div`
  display: flex;
  position: relative;
  left: 565px;
  background: #fff;
  width: 400px;

  ${Dropdown}:not(:hover) & {
    display: none;
  }

  a {
    display: block;
    line-height: 34px;
    padding: 5px 0px 5px 0px;

    &:hover {
      color: ${({ theme }) => theme.textHard};
    }
  }
`;

const DropdownText = styled(Link)`
  display: block;

  ${Dropdown}:hover & {
    color: ${({ theme }) => theme.textHard};
  }
`;

const DropdownLinkContainer = styled.div`
  margin-left: 75px;
  margin-top: 32px;
  max-height: 250px;

  display: flex;
  flex-direction: column;
  align-items: space-between;

  a {
    line-height: 100%;
    // margin-bottom: 50px;
    margin-right: 50px;
    font-weight: 300;
    font-size: 16px;
    position: relative;
    color: ${({ theme }) => theme.textHard};
    cursor: pointer;
    // &:hover {
    //   &:before,
    //   :after {
    //     border-bottom-color: ${({ theme }) => theme.textSoft};
    //     border-right-color: ${({ theme }) => theme.textSoft};
    //     border-top-color: ${({ theme }) => theme.textSoft};
    //   }
    // }
    // &:before,
    // :after {
    //   position: absolute;
    //   content: ' ';
    // }
    // &:before {
    //   top: 11px;
    //   left: -19px;
    //   width: 30px;
    //   height: 1px;
    //   border-bottom: 1px solid ${({ theme }) => theme.textHard};
    //   border-radius: 5px;
    // }
    // &:after {
    //   top: 7px;
    //   left: 0;
    //   width: 10px;
    //   height: 10px;
    //   transform: rotate(45deg);
    //   border-top: 1px solid ${({ theme }) => theme.textHard};
    //   border-right: 1px solid ${({ theme }) => theme.textHard};
    }
  }
`;

const HeaderItem = ({ pathname, headerItem }) => {
  if (headerItem.render && headerItem.activeLinks) {
    return (
      <Dropdown
        isActive={
          pathname && headerItem.activeLinks.some(link => link === pathname)
        }
      >
        {headerItem.defaultToExternal ? (
          <a href={headerItem.defaultToExternal}>{headerItem.name}</a>
        ) : (
          <DropdownText to={headerItem.defaultTo}>
            {headerItem.name}
          </DropdownText>
        )}
        <DropdownWrapper>
          {headerItem.name === 'Plan ahead' ? (
            <DropdownBox>{headerItem.render()}</DropdownBox>
          ) : headerItem.name === 'Resources' ? (
            <DropdownBoxResources>{headerItem.render()}</DropdownBoxResources>
          ) : null}
        </DropdownWrapper>
      </Dropdown>
    );
  }

  return (
    <LeftLink
      to={headerItem.url}
      pathname={pathname}
      target={`${headerItem.target}`}
    >
      {headerItem.name}
    </LeftLink>
  );
};

HeaderItem.propTypes = {
  pathname: PropTypes.string,
  headerItem: PropTypes.object,
};

const SpanishHeader = ({ visible, children }) => (
  <Wrapper>
    <HeaderWrapper>
      <AreaLeft>
        <LogoLink to="/en">
          <Logo src="/img/header-logo.svg" />
        </LogoLink>
      </AreaLeft>
      <AreaRight>
        <Button width={212} mr={20} secondary href="/en">
          English
        </Button>
        <PhoneNumber
          phoneNumber={{
            formatted: '(844) 238-3448',
            normal: '8442383448',
          }}
          render={({ formatted, href }) => (
            <Button width={212} href={href}>
              Hablenos a {formatted}
            </Button>
          )}
        />
      </AreaRight>
      {children}
    </HeaderWrapper>
    <SpanishMenu visible={visible} />
  </Wrapper>
);

SpanishHeader.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
};

class Header extends React.PureComponent<Props, State> {
  constructor() {
    super();
    this.state = {
      menuVisible: false,
    };
  }

  showHeaderItem = () => {
    const {
      router: { pathname },
    } = this.props;
    if (!this.props.hideHeaderItems) {
      return ITEMS.map(headerItem => (
        <HeaderItem
          key={headerItem.name}
          pathname={pathname}
          headerItem={headerItem}
          target={headerItem.target}
        />
      ));
    }
  };

  showPhoneNumbers = () => {
    const {
      router: { pathname },
    } = this.props;
    if (!this.props.hideHeaderItems) {
      return (
        <PhoneNumber
          phoneNumber={
            pathname === '/home'
              ? {
                  formatted: '(844) 445-3655',
                  normal: '8444453655',
                }
              : null
          }
          render={({ formatted, href }) => (
            <Call href={href}>Call {formatted}</Call>
          )}
        />
      );
    }
  };

  render() {
    const {
      router: { pathname },
      isSpanish,
    } = this.props;
    const { menuVisible } = this.state;
    const isCheckoutMenu = pathname.startsWith('/checkout/');
    const isOrderSuccess = pathname.startsWith('/order-success');

    if (isSpanish) {
      return (
        <SpanishHeader
          visible={menuVisible}
          onDismiss={() => this.setState({ menuVisible: false })}
        >
          <MenuToggle
            isOpen={menuVisible}
            onClick={() => this.setState({ menuVisible: !menuVisible })}
          />
        </SpanishHeader>
      );
    }
    return (
      <>
        <Wrapper>
          <HeaderWrapper>
            <AreaLeft>
              <LogoLink
                to="/"
                onClick={() => this.setState({ menuVisible: false })}
              >
                <Logo src="/img/header-logo.svg" />
              </LogoLink>
              {!isCheckoutMenu && (
                <HeaderNav>{this.showHeaderItem()}</HeaderNav>
              )}
            </AreaLeft>

            {isCheckoutMenu ? (
              <AreaRight>
                <PhoneNumber
                  render={({ formatted, href }) => (
                    <CallCheckout href={href}>
                      <img src="/img/brochure/phone.svg" />
                      Need help? Call our care team&nbsp;&nbsp;
                      <UILink>{formatted}</UILink>
                    </CallCheckout>
                  )}
                />
              </AreaRight>
            ) : (
              <AreaRight>
                {!isOrderSuccess && this.showPhoneNumbers()}
              </AreaRight>
            )}

            {!this.props.hideHeaderItems && (
              <MenuToggle
                isOpen={menuVisible}
                onClick={() => this.setState({ menuVisible: !menuVisible })}
              />
            )}
          </HeaderWrapper>

          <FullScreenMenu
            visible={menuVisible}
            onDismiss={() => this.setState({ menuVisible: false })}
          />
        </Wrapper>
      </>
    );
  }
}

export default withRouter(Header);
