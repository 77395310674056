// @flow
import axios from 'axios';
import { HOST } from '../config';

function prefixApiUrl(config) {
  config.url = `/api${config.url}`;
  config.baseURL = `https://${HOST}`;

  if (HOST.includes('localhost')) {
    config.baseURL = `http://${HOST}`;
  }

  return config;
}

function returnJson(response) {
  return response.data;
}

const tulipApi = axios.create();
tulipApi.interceptors.request.use(prefixApiUrl);
tulipApi.interceptors.response.use(returnJson);

const reviewsApi = axios.create();
reviewsApi.interceptors.response.use(returnJson);

export { tulipApi, reviewsApi };
