import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ to, ...props }) => {
  return (
    <NextLink href={to}>
      <a {...props} />
    </NextLink>
  );
};

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Link;
