import createMuiTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';

import { theme } from './globalStyles';

const createPaperworkThemeV2 = (options?: ThemeOptions) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: theme.darkBlue,
      },
    },
    typography: {
      fontFamily: [
        // 'Brown',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 16,
    },
    overrides: {
      MuiFormLabel: { root: { color: '#bdbdbd' } },
      MuiTextField: { root: { width: '100%' } },
      MuiInput: {
        root: {
          position: 'relative',
          boxSizing: 'border-box',
          borderRadius: 5,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#d5d7db',
          '&$error': {
            borderColor: '#ff6364',
            backgroundColor: '#ffeded',
          },
          fontSize: 16,
          padding: '2px 4px 2px 16px',
          width: '100%',
          height: '36px',
          fontFamily: 'Brown',
        },
        underline: {
          '&:before': {
            content: 'none',
          },
          '&:after': {
            content: 'none',
          },
        },
        input: {
          height: 'inherit',
          padding: 0,
          color: '#687491',
        },
      },
    },
    ...options,
  });
};

const createPaperworkTheme = (options?: ThemeOptions) => {
  return createMuiTheme({
    palette: {
      primary: {
        main: theme.brightBlue,
      },
    },
    typography: {
      fontFamily: [
        // 'Brown',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 16,
    },
    overrides: {
      MuiInput: {
        root: {
          position: 'relative',
          boxSizing: 'border-box',
          borderRadius: 2,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.lightMediumGrey,
          '&$error': {
            borderColor: theme.red,
          },
          fontSize: 16,
          padding: '13px 10px 13px 20px',
          fontWeight: 100,
          width: '200px',
          color: theme.mediumGrey,
          lineHeight: '24px',
          fontFamily: 'Brown',
        },
        underline: {
          '&:before': {
            content: 'none',
          },
          '&:after': {
            content: 'none',
          },
        },
        fullWidth: {
          width: '100% !important',
        },
        input: {
          height: 'inherit',
          padding: 0,
        },
      },
    },
    ...options,
  });
};

export const paperworkMaterialTheme = createPaperworkTheme();
export const paperworkMaterialThemeV2 = createPaperworkThemeV2();
