import React, { useEffect } from 'react';
import { PURCHASE_TYPE, DECEDENT_LOCATION_TYPE } from 'tulip-core';

import { StepProps } from '../StepWrapper';
import MultipleChoice from '../MultipleChoice';
import Buttons from '../Buttons';

import * as utils from '/util/registerEvent';

import {
  QuestionText,
  QuestionWrapperVisibilityHidden,
  StepSpinner,
} from '../styled';

interface Option {
  name: string;
  value: string;
}

const STEP_ID = 'DecedentLocation';

const DecedentLocation = (props: StepProps) => {
  const {
    updateQuoteData,
    stepForward,
    skip,
    presets,
    recordStepStarted,
    recordStepPresetSkipped,
  } = props;
  const {
    decedentLocationType,
    purchaseType,
    isPurchaserBeneficiary,
  } = props.quoteData;

  const presetExists = presets.decedentLocationType !== undefined;

  // AUTOMATIC CLICK TO GET A VALUE FOR THIS SCREEN, DEFAULT VALUE: HOSPITAL ******************
  const liArray = document.getElementsByTagName('li');
  let locatedElement: HTMLLIElement;

  function loopElements() {
    for (let i = 0; i < liArray.length; i++) {
      if (liArray[i].innerText === 'Hospital') {
        locatedElement = liArray[i];
        locatedElement.click();
      }
    }
  }
  // *********************************************************************************************
  useEffect(() => {
    // IF PURCHASE IS "AT NEED"
    if (!presets.purchaseType && purchaseType !== PURCHASE_TYPE.PRENEED) {
      recordStepStarted();

      loopElements();
      // TO NOT SHOW THIS SCREEN:
      skip();
    } else if (presetExists) {
      // Set pre-recorded step
      recordStepPresetSkipped({
        id: 'decedentLocationType',
        value: presets.decedentLocationType,
      });
    }
  }, []);

  if (
    purchaseType === PURCHASE_TYPE.PRENEED ||
    purchaseType === PURCHASE_TYPE.PRENEED_INSURANCE ||
    presetExists
  ) {
    skip(presetExists);
    return <StepSpinner />;
  }

  const options: Option[] = [
    {
      value: DECEDENT_LOCATION_TYPE.HOSPITAL,
      name: 'Hospital',
    },
    {
      value: DECEDENT_LOCATION_TYPE.RESIDENCE,
      name: 'Private residence',
    },
    {
      value: DECEDENT_LOCATION_TYPE.NURSING_HOME,
      name: 'Nursing home',
    },
    {
      value: DECEDENT_LOCATION_TYPE.CORONER,
      name: "Coroner's office",
    },
    {
      value: DECEDENT_LOCATION_TYPE.FUNERAL_HOME,
      name: 'Funeral Home',
    },
  ];

  const questionText = isPurchaserBeneficiary
    ? 'Where are you at present?'
    : 'Where is your loved one at present?';

  // we need to filter out options which dont' work for if the purchaser
  // is the beneficiary. eg: They're not in the coroner's office or a funeral
  // home unless something has gone _badly wrong_
  const filteredOptions =
    isPurchaserBeneficiary || purchaseType === PURCHASE_TYPE.SOON
      ? options.slice(0, 3)
      : options;

  return (
    <React.Fragment>
      <QuestionWrapperVisibilityHidden>
        <QuestionText>{questionText}</QuestionText>
        <MultipleChoice
          activeOptionValue={decedentLocationType}
          options={filteredOptions}
          onSelect={(newValue: string) => {
            updateQuoteData('decedentLocationType', newValue, STEP_ID);
            utils.sendStepCompletedEvent(STEP_ID, newValue);
            stepForward();
          }}
        />
      </QuestionWrapperVisibilityHidden>
      <Buttons hideContinue />
    </React.Fragment>
  );
};

DecedentLocation.displayName = STEP_ID;

export default DecedentLocation;
