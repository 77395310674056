import gql from 'graphql-tag';

import initApollo from '../apollo-wrapper/init-apollo';

const query = gql`
  query getPurchaseOptionsByPlaceId($placeId: String!) {
    geography {
      county(did: $placeId) {
        name
        immediately: purchaseOption(option: PACKAGE) {
          isAvailable
        }
        soon: purchaseOption(option: PACKAGE) {
          isAvailable
        }
        preneed: purchaseOption(option: PRE_NEED) {
          isAvailable
        }
      }
    }
  }
`;

const getPurchaseOptionsByPlaceId = async placeId => {
  const client = initApollo();

  return client.query({
    query: query,
    variables: {
      placeId,
    },
  });
};

export default getPurchaseOptionsByPlaceId;
