// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'tulip-ui';

const Crop = styled('div')`
  position: absolute;
  overflow: hidden;
  height: ${p => p.height}px;
  ${p => p.side}: 0;
  ${p => p.oppSide}: 50%;

  @media (max-width: ${p => p.breakpoint}px) {
    ${p => (p.hideImageCollapsed ? 'display: none' : '')};
    position: relative;
    left: 0;
    right: 0;
    height: ${p => p.collapsedHeight}px;
  }
`;

// TODO: Rounded bottom edge
const Bubble = styled('div')`
  position: absolute;
  overflow: hidden;
  top: -${p => p.bubbleOffset}px;
  bottom: -${p => p.bubbleOffset}px;
  left: 0;
  right: 0;
  border-top-${p => p.oppSide}-radius: ${p => p.radius}px;
  border-bottom-${p => p.oppSide}-radius: ${p => p.radius}px;
  transform: translateX(0);

  @media (max-width: 1320px) {
    transform: translateX(${p => (p.oppSide === 'left' ? '' : '-')}160px);
  }

  @media (max-width: ${p => p.breakpoint}px) {
    border-radius: 50%;
    left: -50%;
    right: -50%;
    bottom: 0;
    top: -50%;
    transform: translateX(0);
  }
`;

const Image = styled('div').attrs({
  role: 'img',
  'aria-label': props => props.label,
})`
  display: block;
  position: absolute;
  overflow: hidden;
  top: ${p => p.bubbleOffset}px;
  bottom: ${p => p.bubbleOffset}px;
  left: 0;
  right: 0;
  background: url(${p => p.url}) ${p => p.imageGravity};
  background-position: right middle;
  background-size: cover;

  ${mediaQueries.large`
    background-position: right bottom;

    ${({ backgroundPosition }) =>
      backgroundPosition &&
      css`
        background-position: ${backgroundPosition};
      `};
  `};

  @media (max-width: ${p => p.breakpoint}px) {
    left: 25%;
    right: 25%;
    bottom: 0;
    top: 33%;
  }
`;

type Props = {
  url: string,
  side?: 'left' | 'right',
  height?: number,
  collapsedHeight?: number,
  bubbleHeight?: number,
  hideImageCollapsed?: boolean,
  style?: Object,
  imageGravity?: string,
  breakpoint?: number,
  title?: string,
  backgroundPosition?: string,
};

const SideBubblePic = (props: Props) => {
  const {
    url,
    side = 'left',
    height = 500,
    collapsedHeight,
    bubbleHeight,
    hideImageCollapsed,
    style,
    imageGravity = 'center center',
    breakpoint = 1120,
    title = '',
    backgroundPosition,
  } = props;

  const virtualHeight = bubbleHeight || height;
  const childProps = {
    url,
    side,
    height,
    collapsedHeight: collapsedHeight || height / 2,
    bubbleHeight,
    hideImageCollapsed,
    imageGravity,
    breakpoint,
    virtualHeight,
    bubbleOffset: Math.floor((virtualHeight - height) / 2),
    oppSide: side === 'left' ? 'right' : 'left',
    radius: Math.floor(virtualHeight / 2),
    backgroundPosition,
  };

  return (
    <Crop {...childProps} style={style}>
      <Bubble {...childProps}>
        <Image {...childProps} label={title} />
      </Bubble>
    </Crop>
  );
};

export default SideBubblePic;
