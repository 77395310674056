import React, { useContext, useEffect } from 'react';

import { FormNavigationContext } from './providers/NavigationProvider';
import {
  QuoteFormContainer,
  QuoteFormContent,
  QuoteFormTitle,
  QuoteFormProgress,
  QuoteFormProgressWrapper,
} from './styled';
import StepWrapper, { Step } from './StepWrapper';

import * as utils from '/util/registerEvent';

// Import Steps
import ContinueArrangement from './steps/ContinueArrangement';
import PurchaseType from './steps/PurchaseType';
import DecedentLocation from './steps/DecedentLocation';
import DecedentWeight from './steps/DecedentWeight';
import DecedentImplant from './steps/DecedentImplant';
import Location from './steps/Location';
import ServiceUnavailable from './steps/ServiceUnavailable';
import ThankYou from './steps/ThankYou';
import CreateQuote from './steps/CreateQuote';
import Date from './steps/Date';
import PurchaseFor from './steps/PurchaseFor';
import PurchaserName from './steps/PurchaserName';
import PurchaserRelationship from './steps/PurchaserRelationship';
import InsuranceUnavailable from './steps/InsuranceUnavailable';

interface QFProps {
  setStepIds: (x: string[]) => void;
  className?: string;
  partnerCode: utils.code;
  referrer: utils.path;
  steps: Step[]; // Simple Functional Component type
}

interface FormProps {
  className?: string;
  partnerCode: utils.code;
  referrer: utils.path;
}

const TulipQuoteForm = (props: QFProps) => {
  const { steps, setStepIds, className, partnerCode, referrer } = props;
  // Shouldn't ever have no children but definitely preferable
  // to render nothing than error.
  if (!steps) {
    return null;
  }
  // The Navigation Context needs to know how many steps there are so
  // it can reason about whether it can go back / forward
  useEffect(() => {
    utils.sendFormStartedEvent('PLACEHOLDER_SESSION_ID', partnerCode, referrer);
    const stepIds = steps.map(child => child.displayName || child.name);
    setStepIds(stepIds);
  }, []);

  // We render whatever child is selected as the currentStep
  // and ensure it's provided the methods to update the quote
  // form data by wrapping it with StepWrapper
  const { currentStep } = useContext(FormNavigationContext);
  const StepToRender = steps[currentStep];
  const titleText =
    (StepToRender.titleText && StepToRender.titleText() !== undefined) ||
    'Instant online quote';
  return (
    <QuoteFormContainer className={className}>
      <QuoteFormTitle>{titleText}</QuoteFormTitle>
      <QuoteFormContent>
        {!StepToRender.hideProgressBar && (
          <QuoteFormProgressWrapper>
            <QuoteFormProgress progress={(currentStep + 1) / steps.length} />
          </QuoteFormProgressWrapper>
        )}
        <StepWrapper>{StepToRender}</StepWrapper>
      </QuoteFormContent>
    </QuoteFormContainer>
  );
};

const Form = (props: FormProps) => {
  return (
    <FormNavigationContext.Consumer>
      {({ setStepIds }) => (
        <TulipQuoteForm
          setStepIds={setStepIds}
          className={props.className}
          partnerCode={props.partnerCode}
          referrer={props.referrer}
          steps={[
            ContinueArrangement,
            PurchaseFor,
            PurchaseType,
            Location,
            PurchaserRelationship,
            InsuranceUnavailable,
            Date,
            ServiceUnavailable,
            ThankYou,
            DecedentLocation,
            DecedentWeight,
            DecedentImplant,
            PurchaserName,
            CreateQuote,
          ]}
        />
      )}
    </FormNavigationContext.Consumer>
  );
};

export default Form;
