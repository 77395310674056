// @flow
import compose from 'util/compose';

import React from 'react';
import { Button } from 'tulip-ui';
import { Purchase } from 'tulip-core/models';
import withContext from 'hoc/withContext';
import { QuoteFlowContext, QuoteFormContext } from 'context';
import { withRouter } from 'next/router';

import { type Props as ButtonProps } from './Button';

import { sendFormContinuedEvent } from '/util/registerEvent';

type Props = ButtonProps & {
  inactiveLabel?: string,
  activeLabel?: string,
  committedLabel?: string,
  isCommitted: boolean,
  isActive: boolean,
  purchase: Purchase.VCustomer,
  router: {
    pathname: string,
  },
  source: string,
};

class QuoteButton extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleClick = isQuotePage => () => {
    const {
      sendQuoteForm = () => {},
      continueQuote = () => {},
      source = '',
      isCommitted,
      isActive,
    } = this.props;
    if (isQuotePage) {
      sendQuoteForm();
    } else {
      continueQuote(source);
    }

    if (isActive && !isCommitted) {
      sendFormContinuedEvent('ContinueArrangement');
    }
  };

  handleMouseOver() {
    const { router = {}, validateQuoteForm = () => {} } = this.props;
    const isQuotePage = router.pathname === '/quote';

    if (isQuotePage) {
      validateQuoteForm();
    }
  }

  render() {
    const {
      inactiveLabel = 'Arrange online',
      activeLabel = 'Continue arrangement',
      committedLabel = 'View arrangement',
      isCommitted = '',
      isActive,
      sending,
      router = {},
      ...rest
    } = this.props;
    const isQuotePage = router.pathname === '/quote';
    const isDisabled = isQuotePage && sending;

    let label = inactiveLabel;
    if (isCommitted) {
      label = committedLabel;
    } else if (isActive) {
      label = activeLabel;
    }

    return (
      <Button
        data-id="btn-quote"
        label={label}
        disabled={isDisabled}
        onMouseOver={this.handleMouseOver}
        onClick={this.handleClick(isQuotePage)}
        {...rest}
      />
    );
  }
}

const quoteFlowConnector = withContext(
  QuoteFlowContext,
  ({ state, actions }) => ({
    isCommitted: Boolean(
      state.activeCase &&
        state.activeCase.purchase &&
        Purchase.isCommitted(state.activeCase.purchase)
    ),
    isActive: Boolean(state.activeCase && state.activeCase.purchase),
    continueQuote: actions.continueQuote,
    sending: state.sending,
  })
);

const quoteFormConnector = withContext(
  QuoteFormContext,
  ({ state, actions }) => ({
    deliveryOption: state.values.deliveryOption,
    validateQuoteForm: actions.validateQuoteForm,
    sendQuoteForm: actions.sendQuoteForm,
  })
);

export default compose(
  quoteFlowConnector,
  quoteFormConnector,
  withRouter
)(QuoteButton);
