import React, { Fragment } from 'react';
import DesktopOnly from '../DesktopOnly';
import MobileOnly from '../MobileOnly';
import { mediaQueries } from '../../variables';

var MediaQuerySwitch = function MediaQuerySwitch(_ref) {
  var _ref$breakpoint = _ref.breakpoint,
      breakpoint = _ref$breakpoint === void 0 ? 'medium' : _ref$breakpoint,
      desktopChildren = _ref.desktopChildren,
      mobileChildren = _ref.mobileChildren;
  return React.createElement(Fragment, null, React.createElement(DesktopOnly, {
    breakpoint: breakpoint
  }, desktopChildren), React.createElement(MobileOnly, {
    breakpoint: breakpoint
  }, mobileChildren));
};

export default MediaQuerySwitch;