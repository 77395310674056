import React from 'react';
import Link from 'components/Link';
import styled from 'styled-components';
import { mediaQueries, BREAKPOINTS, DesktopOnly, MobileOnly } from 'tulip-ui';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
//import { IPRegionContext } from 'context';
import _ from 'lodash/fp';

const Container = styled.footer`
  background-color: ${({ theme }) => theme.bgBlue};
  color: #fff;
`;

const Wrapper = styled('div')`
  padding: 80px 30px 42px;
  max-width: 1600px;
  margin: 0 auto;

  ${mediaQueries.medium`
    padding: 80px 95px 42px 90px;
    `};

  ${mediaQueries.large`
    padding: 96px 122px 32px 92px;
    `};
`;

const Cols = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${mediaQueries.large`
    flex-direction: row;
    justify-content: space-between;
    `};
`;

const LinkCols = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  ${mediaQueries.large`
    margin-top: 0;
  `};
`;

const Col = styled.div`
  &:not(:last-child) {
    margin-right: 30px;
  }
  ${mediaQueries.small`
    &:not(:last-child) {
      margin-right: 44px;
    }
    `};

  ${mediaQueries.medium`
    &:not(:last-child) {
      margin-right: 68px;
    }
    `};
`;

const Logo = styled('img')`
  display: block;
  width: 140px;

  ${mediaQueries.medium`
    width: 156px;
  `};
`;

const Head = styled('span')`
  display: block;
  color: ${({ theme }) => theme.colors.plainColor};
  font-size: 16px;
  margin: 16px 0 24px;
`;

const Item = styled('span')`
  &,
  & > a {
    ${({ theme }) => theme.fonts.text.fontWeight.light};

    display: block;
    color: ${({ theme }) => theme.colors.plainColor};
    font-size: 16px;
    margin: 12px 0;
  }
`;

const Info = styled('div')`
  margin-top: 62px;
  text-align: center;

  ${mediaQueries.medium`
    margin-top: 80px;
  `};
`;

const Copyright = styled('div')`
  color: #b3bcc4;
  font-size: 16px;
`;

const Address = styled('div')`
  ${({ theme }) => theme.fonts.text.fontWeight.light};
  color: #b3bcc4;
  font-size: 16px;
  line-height: 1.2;
  margin: 32px 0;
  &:first-of-type {
    margin-top: 36px;
  }
`;
const AddressWrapper = styled('div')`
  ${Address} {
    margin-right: 50px;
    margin-top: 32px;
  }
`;

const LogoWrapper = styled('div')``;
const Stamp = styled('img')`
  margin-top: 24px;
  width: 132px;

  ${mediaQueries.medium`
    width: 136px;
  `};
`;

const query = gql`
  query Regions {
    geography {
      regions(includeUnavailable: false) {
        edges {
          node {
            name
            gplFileLink
            state {
              shortName
            }
          }
        }
      }
    }
  }
`;

const queryStates = gql`
  query AddressInformation {
    geography {
      states(includeUnavailable: false) {
        edges {
          node {
            id
            shortName
            tulipAddress {
              line1
              line2
              city
              zip
              phoneNumber
              faxNumber
            }
          }
        }
      }
    }
  }
`;

const renderAdresses = () => {
  return (
    <AddressWrapper>
      <Query query={queryStates}>
        {({ loading, data = {} }) => {
          if (loading) return null;

          let states = _.getOr([], ['geography', 'states', 'edges'])(data);

          return (
            <React.Fragment>
              {states.map(state => {
                if (state.node && state.node.tulipAddress) {
                  return (
                    <React.Fragment key={state.node.id}>
                      <Address key={state.node.id}>
                        <p>{state.node.tulipAddress.line1}</p>
                        <p>{state.node.tulipAddress.line2}</p>
                        <p>
                          {state.node.tulipAddress.city +
                            ', ' +
                            state.node.shortName +
                            ' ' +
                            state.node.tulipAddress.zip}
                        </p>
                      </Address>
                    </React.Fragment>
                  );
                }
              })}
            </React.Fragment>
          );
        }}
      </Query>
    </AddressWrapper>
  );
};

export default class Footer extends React.Component {
  //static contextType = IPRegionContext;
  render() {
    return (
      <Container>
        <Wrapper>
          <Cols breakpoint={BREAKPOINTS.MEDIUM}>
            <Col>
              <LogoWrapper>
                <Logo src="/img/tulip-logo-white.png" />
                <a
                  href="https://www.bbb.org/us/ca/san-francisco/profile/cremation/tulip-cremation-inc-1116-880068/#sealclick"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Stamp
                    src="/img/bbb-logo-white.png"
                    alt="Tulip Cremation, Inc. BBB Business Review"
                  />
                </a>
              </LogoWrapper>
              <DesktopOnly>{renderAdresses()}</DesktopOnly>
            </Col>
            <Col>
              <LinkCols breakpoint={BREAKPOINTS.MEDIUM}>
                <Col>
                  <Head>Cremation Services</Head>
                  <Item>
                    <Link to="/our-service">Our Service</Link>
                  </Item>
                  <Item>
                    <Link to="/pricing">Pricing</Link>
                  </Item>
                  <Item>
                    <Link to="/plan-ahead">Plan Ahead</Link>
                  </Item>
                </Col>
                <Col>
                  <Head>Tulip Cremation</Head>
                  <Item>
                    <Link to="/locations">Service Area</Link>
                  </Item>
                  <Item>
                    <Link to="/reviews">Reviews</Link>
                  </Item>
                  <Item>
                    <Link to="/faq">FAQs</Link>
                  </Item>
                  <Item>
                    <a
                      href="https://www.tulipcremation.com/resources"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resources
                    </a>
                  </Item>
                  <Item>
                    <Link to="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=6e340480-0081-4736-aca8-64b3f1148951&ccId=19000101_000001&type=MP&lang=en_US">
                      Careers
                    </Link>
                  </Item>
                </Col>
                <Col>
                  <Head>Legal</Head>
                  <Item>
                    <Link to="/privacy">Privacy Policy</Link>
                  </Item>
                  <Item>
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </Item>
                  <Query query={query}>
                    {({ loading, data }) => {
                      if (loading) return null;
                      let regions = _.getOr(
                        [],
                        ['geography', 'regions', 'edges']
                      )(data);

                      let seen_regions = [];
                      let deduped_regions = regions.reduce(
                        (regions, region) => {
                          if (
                            region.node.state.shortName == 'CA' ||
                            region.node.state.shortName == 'FL'
                          ) {
                            if (
                              !seen_regions.includes(
                                region.node.state.shortName
                              )
                            ) {
                              seen_regions.push(region.node.state.shortName);
                              if (region.node.state.shortName == 'CA') {
                                region.node.name = 'California';
                              } else if (region.node.state.shortName == 'FL') {
                                region.node.name = 'Florida';
                              }
                              regions.push(region);
                            }
                          } else {
                            regions.push(region);
                          }

                          return regions;
                        },
                        []
                      );
                      regions = deduped_regions;
                      return (
                        <React.Fragment>
                          {regions.map((region, i) => {
                            if (region.node) {
                              return (
                                <Item key={i}>
                                  <Link
                                    to={{
                                      pathname: region.node.gplFileLink,
                                      query: { name: region.node.name },
                                    }}
                                  >
                                    {' '}
                                    {region.node.name} General Price List
                                  </Link>
                                </Item>
                              );
                            }
                          })}
                        </React.Fragment>
                      );
                    }}
                  </Query>
                </Col>
              </LinkCols>
            </Col>
          </Cols>
          <MobileOnly>{renderAdresses()}</MobileOnly>
          <Info>
            <Copyright>© 2021 Tulip Cremation, Inc.</Copyright>
          </Info>
        </Wrapper>
      </Container>
    );
  }
}
