// @flow
import { injectGlobal } from 'styled-components';

import { css } from './util';

function isTouchDevice() {
  return (
    typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    // works on most browsers
    ('ontouchstart' in window ||
      // works on IE10/11 and Surface
      navigator.maxTouchPoints)
  );
}

export const BREAKPOINTS = {
  XXLARGE: 1600,
  XLARGE: 1200,
  LARGE: 992,
  MEDIUM: 768,
  SMALL: 575,
};

const baseTheme = {
  name: 'Main',
  breakpoints: [
    // `${BREAKPOINTS.SMALL}px`,
    `${BREAKPOINTS.MEDIUM}px`,
    `${BREAKPOINTS.LARGE}px`,
    `${BREAKPOINTS.XLARGE}px`,
  ],
  colors: {
    plainColor: '#FFF',
    transparent: 'transparent',

    primaryButtonBackground: '#FFD81A',
    primaryButtonText: '#093969',

    secondaryButtonText: '#126FCB',
    secondaryButtonHoverBackground: '#126FCB',

    tertiaryButtonBackground: '#093969',
    tertiaryButtonText: '#FFF',

    link: '#126FCB',
    linkHover: '#305881',
    linkBase: '#4F6378',
    linkDark: '#1B254E',
    steel: '#727a9a',

    headerBase: '#093969',
    headerDarken: '#073058',
    headerNeutral: '#FFF',
    headerShadow: 'rgba(0, 0, 0, 0.5)',

    subheaderBaseDark: '#4F6378',
    subheaderNeutral: '#FFF',
    subheaderBase: '#126FCB',

    bodyTextBaseDark: '#4F6378',
    bodyTextNeutral: '#FFF',
    bodyTextBase: '#126FCB',
    bodyTextWarning: '#BB1122',
    bodyTextSuccess: '#0D8927',
    bodyTextHighlighted: '#188884',

    heroBackground: '#F6FCFC',

    boxBorderColor: '#C2EFEE',
    darkBorderColor: '#093969',
    primaryFill: '#64C7C4',

    reviewQuoteBackground: '#E4F8F7',
    reviewQuoteFill: '#60C6C3',

    // TODO: Temporary Styling changes. Need to be implemented
    // in main css color themes
    lightBlue: '#5ec6c4',
    lightBlue12: 'rgba(96,198,195, 0.12)',
    lightBlue30: 'rgba(96,198,195, 0.30)',
    darkBlue: '#243965',
    grey: '#D5D8DC',
    lightGrey: '#b4bccf',
    lightGreyTranslucent: 'rgba(190, 199, 214, 0.2)',
    linkBlue: '#15a3eb',
    baseBlue: '#126fcb',
    // /TODO

    progressBarFill: '#24C9C4',
    boxShadow: 'rgba(79, 99, 129, 0.24)',

    greenTextColor: '#1EA9A5',

    customerFormBackground: '#E2EDF8',
    blueSectionBackground: '#F5F9FC',

    priceBoxBorder: '#7BDEDB',
    lightPriceBorder: '#C2EFEE',
    priceText: '#60C6C3',
    arrowColor: '#B3BCC4',
    neutralBorder: '#979797',

    faqTopicLine: '#7BDEDB',
    lightGreyBorder: '#D5D8DC',
  },
  fonts: {
    headers: {
      fontFamily: 'font-family: "Tiempos Headline"',
      fontWeight: {
        regular: 'font-weight: 500',
        bold: 'font-weight: 600',
      },
    },
    text: {
      fontFamily: 'font-family: Brown',
      fontWeight: {
        light: 'font-weight: 300',
        regular: 'font-weight: 500',
      },
    },
  },
};

export const theme = {
  ...baseTheme,
  touch: isTouchDevice(),
  white: '#fff',
  bgYellow: '#ffd72f',
  bgBlue: '#243966',
  lightYellow: '#ffd700',
  lightBlue: '#5ec6c4',
  lightBlue12: 'rgba(96,198,195, 0.12)',
  lightBlue30: 'rgba(96,198,195, 0.30)',
  darkBlue: '#243965',
  strongBlue: 'rgba(9, 57, 105, 0.8)',
  grayBlue: '#7C88A3',
  grey: '#D5D8DC',
  lightGrey: '#EEEFF1',
  darkGrey: '#505f6d',
  subHeading: '#4a647b',
  lightGreyTranslucent: 'rgba(190, 199, 214, 0.2)',
  lightBlackTranslucent: 'rgba(107, 113, 125, 0.8)',
  lightGrey30: 'rgba(190, 199, 214, 0.3)',
  fadeGrey: '#e1e1e1',
  greyShadow: '#f0f6ff',
  affirmTextColor: '#4b637a',
  pricingHeader: '#394957',
  greyLine: '#979797',
  blueGrey: '#49647c',
  textGrey: '#aaadb7',
  mediumGrey: '#8A8E92',
  mutedGrey: '#7F8285',
  faintGrey: '#E3E3E3',
  lightMediumGrey: '#d6dade',
  lighterMediumGrey: '#BABDC5',
  benefitsLightBlue: '#e2edf8',
  benefitsLightYellow: '#fff7d1',
  faintBlue: '#f5f9fc',
  linkBlue: '#15a3eb',
  navy: '#093969',
  baseBlue: '#D0E6FB',
  paleBlue: '#f1f7f7',
  mediumBlue: '#A2C6CD',
  boldBlue: '#4f6378',
  textHard: '#1B2952',
  textSoft: '#687491',
  textSofter: '#8c96af',
  textSpecial: '#25BFB7',
  sharpBlue: '#458ed6',
  brightBlue: '#126FCB',
  teal: '#24A4AE',
  darkTeal: '#008489',
  babyBlue: '#ecf3fa',
  red: '#BB2233',
  darkRed: '#BB1122',
  darkerGrey: '#363F48',
  lighterBlue: '#eff6fc',
  brightLightBlue: '#619fdc',
  turquoise: '#22C9C3',
  // Login Color Schemes
  login: {
    title: '#093969',
    text: '#093969',
    label: '#505F6D',
    borderColor: '#505F6D',
  },
  caution: '#fbedbc',

  paperwork: {
    topHeading: '#093969',
    sectionHeader: '#093969',
    progressBar: '#093969',
    progressBarBacking: '#E3E3E3',
    border: '#D8D8D8',
    purchasesBorder: '#EBEBEB',
    buttonText: '#093969',
    link: '#126FCB',
    reviewQuestionBacking: '#EBF6FA',
  },
};

injectGlobal`
  @font-face {
    font-family: Brown;
    src: url("/fonts/Brown-Regular.otf");
    font-weight: normal;
  }

  @font-face {
    font-family: Brown;
    src: url("/fonts/Brown-Bold.otf");
    font-weight: bold;
  }

  @font-face {
    font-family: Brown;
    src: url("/fonts/Brown-Light.otf");
    font-weight: 300;
  }

  @font-face {
    font-family: "Tiempos Headline";
    src: url("/fonts/Tiempos-Headline-Medium.otf");
    font-weight: 500;
  }

  @font-face {
    font-family: "Tiempos Headline";
    src: url("/fonts/TiemposHeadline-Bold.otf");
    font-weight: 600;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
    font-size: 62.5%;
    @media (max-width: 1280px) { font-size: 59%; }
    @media (max-width: 640px) { font-size: 57%; }
    @media (max-width: 480px) { font-size: 55%; }
    @media (max-width: 360px) { font-size: 52%; }
  }

  body, input, button, select {
    font-family: Brown, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: ${theme.textSoft};
    line-height: 1.5;
    ${css.fontSize(16)};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  #modal {
    z-index: 1000;
  }
`;
