// @flow
import React from 'react';
export default function Check(props: any) {
  return (
    <svg width="14px" height="10px" viewBox="0 0 14 10" {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="V.2"
          transform="translate(-738.000000, -440.000000)"
          stroke="#60C6C3"
        >
          <g id="Group-12" transform="translate(738.000000, 392.000000)">
            <polyline id="Path-2" points="0 52.1061929 5.27580621 57 13 48" />
          </g>
        </g>
      </g>
    </svg>
  );
}
