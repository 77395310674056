// @flow
import * as React from 'react';
import styled from 'styled-components';

import { css } from '../util';

const sizes = {
  xlarge: 56,
  large: 50,
  normal: 40,
  small: 32,
  tiny: 24,
};

export type Size = $Keys<typeof sizes>;

function cssSize(s: Size, mobile?: boolean): string {
  return css.fontSize(Math.floor(sizes[s] * (mobile ? 0.7 : 1)));
}

type Props = {
  tag?: string,
  size?: Size,
  center?: boolean,
  className?: string,
  style?: Object,
  mobileSize?: Size,
};

const e = React.createElement;

/**
 * Use for any formal headings throughout the site.
 *
 * Main thing determining the font sizing breakpoint is when the HomeHero
 * header would need to break
 */
const Heading: React.ComponentType<Props> = styled(
  ({ tag = 'h2', center, ...props }) => e(tag, props)
)`
  font-family: 'Tiempos Headline', Georgia, Times, 'Times New Roman', serif;
  color: ${({ theme }) => theme.textHard};
  ${(p: Props) => cssSize(p.size || 'normal')};
  text-align: ${(p: Props) => (p.center ? 'center' : 'inherit')};
  font-weight: 500;
  line-height: 1.2;
  ${css.rem('margin-top', 30)};
  ${css.rem('margin-bottom', 30)};
  @media (max-width: 1000px) {
    ${(p: Props) => cssSize(p.mobileSize || p.size || 'normal', true)};
  }
`;

export default Heading;
