// @flow
import styled from 'styled-components';

/**
 * A row of buttons which stacks vertically when it's short on space
 */
export default styled('div')`
  margin: 30px 0;

  > a,
  > button {
    display: inline-block;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${p => p.breakpoint || 350}px) {
    > a,
    > button {
      &:last-child {
        display: block;
        margin: 20px auto;
      }
    }
  }
`;
