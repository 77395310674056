// @flow
import styled from 'styled-components';

const BREAKPOINT = 800;

export default styled.div`
  font-size: 16px;
  margin: 0 auto;
  padding: 0 74px 14px 0;

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 14px;
    padding: 0 0 8px;
  }
`;
