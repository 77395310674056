// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InlineList, ContactCard } from '../components';

type Props = {
  title?: string,
  subtitle?: string,
  contentStyle?: string,
  content?: Array,
  gtmTag?: string,
  open?: Boolean,
};

export default function AccordionItem({
  title,
  subtitle,
  contentStyle,
  content,
  gtmTag,
  open,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const ItemWrapper = styled.div`
    padding: 32px 0;
    border-top: solid 1px ${({ theme }) => theme.faintBlue};

    &:last-child {
      border-bottom: solid 1px ${({ theme }) => theme.faintBlue};
    }
  `;

  const ExpandButton = styled('a')`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  `;

  const ExpandHeading = styled.span`
    color: ${({ theme }) => theme.textHard};
    font-weight: bold;
    font-size: 20px;
  `;

  const ExpandSubheading = styled.span`
    color: ${({ theme }) => theme.grayBlue};
    font-size: 20px;
    margin-top: 16px;
  `;

  const ExpandContent = styled('div')`
    max-height: ${isOpen ? '' : '0'};
    overflow: hidden;
    opacity: ${isOpen ? '100%' : '0'};
    transition: all 0.2s ease-out;
  `;

  const ToggleIcon = styled('img')`
    width: 13px;
  `;

  useEffect(() => {
    if (open !== undefined) setIsOpen(open);
  }, open);

  let innerContent = null;

  if (contentStyle === 'inlineList') {
    innerContent = <InlineList items={content} />;
  } else if (contentStyle === 'contactGroup') {
    innerContent = <ContactCard content={content} />;
  }

  return content.length ? (
    <ItemWrapper>
      <ExpandButton
        role="button"
        onClick={() => {
          window.dataLayer.push({ event: gtmTag });
          setIsOpen(!isOpen);
        }}
      >
        <ExpandHeading>{title}</ExpandHeading>
        <ToggleIcon src={`/img/${isOpen ? 'close' : 'open'}-accordion.svg`} />
      </ExpandButton>
      {subtitle ? <ExpandSubheading>{subtitle}</ExpandSubheading> : null}

      <ExpandContent>{innerContent}</ExpandContent>
    </ItemWrapper>
  ) : null;
}
