import React, { useEffect } from 'react';
import { IPRegionContext } from '/context';
import QueryWithSpinner from '/hoc/queryWithSpinner';
import gql from 'graphql-tag';
import { PURCHASE_TYPE } from 'tulip-core';

import MultipleChoice, { OptionType } from '../MultipleChoice';
import Buttons from '../Buttons';
import { StepProps } from '../StepWrapper';

import * as utils from '/util/registerEvent';

import { SimpleKV, QuoteData } from '../types';
import {
  StepSpinner,
  QuestionText,
  QuestionWrapper,
  DisclaimerText,
} from '../styled';

interface PickerProps {
  quoteData: QuoteData;
  onSelect: (value: string) => void;
  baseOptions: OptionType[];
}

function filterOptionsByCounty(
  options: OptionType[],
  countyObject: SimpleKV
): OptionType[] {
  return options.filter(option => {
    const purchaseOption = countyObject[option.value];
    return purchaseOption && purchaseOption.isAvailable;
  });
}

const PickerWithCounty = ({
  baseOptions,
  onSelect,
  quoteData,
}: PickerProps) => {
  const { countyRef, purchaseType } = quoteData;

  const query = gql`query {
    geography {
      county(did: "${countyRef}") {
        immediately: purchaseOption(option: PACKAGE) {
          isAvailable
        }
        soon: purchaseOption(option: PACKAGE) {
          isAvailable
        }
        preneed_insurance: purchaseOption(option: PRE_NEED_INSURANCE) {
          isAvailable
        }
      }
    }
  }`;

  return (
    <QueryWithSpinner query={query}>
      {({ geography }) => (
        <MultipleChoice
          activeOptionValue={purchaseType}
          options={filterOptionsByCounty(baseOptions, geography.county)}
          onSelect={onSelect}
        />
      )}
    </QueryWithSpinner>
  );
};

const PickerWithoutCounty = ({
  baseOptions,
  onSelect,
  quoteData,
}: PickerProps) => {
  const { purchaseType } = quoteData;
  return (
    <MultipleChoice
      activeOptionValue={purchaseType}
      options={baseOptions}
      onSelect={onSelect}
    />
  );
};

// Purchase Type renders PurchaseType options in a MultipleChoice picker.
// If a county is set in quoteData, then the PurchaseType system will only
// display available options for that county.
// If one isn't known, the it will display all available options and just provide
// the standard "Service Unavailable" dialog for users looking for
// purchaseOptions not provided to them..

const STEP_ID = 'PurchaseType';
const PurchaseType = (props: StepProps) => {
  const {
    presets,
    bulkUpdateQuoteData,
    stepForward,
    skip,
    recordStepStarted,
    recordStepPresetSkipped,
  } = props;
  const { countyRef, isPurchaserBeneficiary } = props.quoteData;
  const region = React.useContext(IPRegionContext);
  const isFlorida = region === 'FL';

  useEffect(() => {
    if (!presets.purchaseType) {
      recordStepStarted();
    } else {
      // Set pre-recorded step
      recordStepPresetSkipped({
        id: 'purchaseType',
        value: presets.purchaseType,
      });
    }
  }, []);

  if (presets.purchaseType) {
    skip(true);
    return <StepSpinner />;
  }

  let baseOptions = [
    {
      value: PURCHASE_TYPE.SOON,
      name: 'Within the next six months',
    },
    {
      value: PURCHASE_TYPE.PRENEED_INSURANCE,
      name: 'Beyond the next six months',
    },
  ];

  if (!isPurchaserBeneficiary) {
    baseOptions.unshift({
      value: PURCHASE_TYPE.IMMEDIATELY,
      name: 'Now',
    });
  }

  const onSelect = (newValue: string) => {
    // Users trying to make arrangements for themselves should always be
    // sent to the PreNeedInsurance plan as we're not allowed to sell them a
    // soon plan.
    // We also need to record that they require the service imminently for
    // Efuneral to adjust
    if (newValue === PURCHASE_TYPE.SOON && isPurchaserBeneficiary) {
      bulkUpdateQuoteData(
        {
          purchaseType: PURCHASE_TYPE.PRENEED_INSURANCE,
          serviceTimelineImminent: true,
        },
        STEP_ID
      );
    } else if (newValue === PURCHASE_TYPE.SOON) {
      bulkUpdateQuoteData(
        {
          purchaseType: newValue,
          serviceTimelineImminent: true,
        },
        STEP_ID
      );
    } else {
      bulkUpdateQuoteData(
        {
          purchaseType: newValue,
          serviceTimelineImminent: false,
        },
        STEP_ID
      );
    }

    utils.sendStepCompletedEvent(STEP_ID, newValue);
    stepForward();
  };

  // Choose a picker which can work with or without the county being known
  const Picker = countyRef ? PickerWithCounty : PickerWithoutCounty;

  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>When do you require our services?</QuestionText>
        <Picker {...props} baseOptions={baseOptions} onSelect={onSelect} />
      </QuestionWrapper>
      <Buttons hideContinue />
      {isFlorida && <FloridaDisclaimer />}
    </React.Fragment>
  );
};

PurchaseType.displayName = STEP_ID;

const FloridaDisclaimer = () => {
  return (
    <DisclaimerText>
      In the State of Florida, we only offer direct cremation services.
    </DisclaimerText>
  );
};

export default PurchaseType;
