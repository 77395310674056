// @flow
/* eslint-env node */
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import _ from 'lodash/fp';

import * as css from './css';
import * as validation from './validation';
export { default as SentryManager } from './SentryManager';

export { css, validation };

export const isSpanish = process.browser && navigator.language === 'es';

export const isBrowser = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return true;
};

// sometimes React's Ref api doesn't give helpful offsets which translate nicely
// so we just use the DOM
export const scrollElementIntoView = (id, offset) => {
  const el = document.getElementById(id);
  window.scrollTo({
    top: el.offsetTop - offset,
    behavior: 'smooth',
  });
};

export const formatDate = (date: Date) => format(date, 'D MMM YYYY [at] HH:mm');

export const formatPrice = (p: number): string => `$${(p / 100).toFixed(2)}`;

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatPriceWithCommas = (p: number): string =>
  currencyFormatter.format(Math.ceil(p / 100)).split('.')[0];

export const track = (event: string, properties?: Object = {}): void => {
  if (window.dataLayer) {
    if (Cookies.get('jwt')) {
      properties['isStaff'] = true;
    }
    window.dataLayer.push({
      event,
      category: 'Conversion Funnel',
      properties,
    });
  }
};

export const trackRaw = (event: string, properties?: Object = {}): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      ...properties,
    });
  }
};

function hexString(buffer) {
  const byteArray = new Uint8Array(buffer);

  const hexCodes = [...byteArray].map(value => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, '0');
    return paddedHexCode;
  });

  return hexCodes.join('');
}

export function hash(message, algorithm = 'SHA-256') {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  return crypto.subtle.digest(algorithm, data).then(hexString);
}

export const getFaqAndServiceAreaText = (data, language, counties) => {
  let states = _.getOr([], ['geography', 'states', 'edges'])(data);
  let countyCount = _.getOr(0, ['geography', 'counties', 'totalCount'])(data);
  let engText = '';
  let spanishText = '';
  for (let i = 0; i < states.length; i++) {
    if (i > 0) {
      spanishText += ', ';
      engText += ', ';
      if (i === states.length - 1) {
        spanishText += 'y ';
        engText += 'and ';
      }
    }
    spanishText += states[i].node.name;
    engText += states[i].node.name;
  }
  if (language === 'English') {
    if (counties) {
      return [engText, countyCount];
    } else {
      return engText;
    }
  } else {
    if (counties) {
      return [spanishText, countyCount];
    } else {
      return spanishText;
    }
  }
};

export const getEnglishServiceAreaStateText = states => {
  let engText = '';
  for (let i = 0; i < states.length; i++) {
    if (i === states.length - 1 && i != 1) {
      engText += 'and ' + states[i].name;
      break;
    }
    engText += states[i].name + ', ';
  }
  return engText;
};

export const getQueryParams = params => {
  return Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&');
};

// extract only the numbers from a formatted phone number
export const getNumbers = (phoneNumber: string) => {
  return phoneNumber.replace(/ |-|[()]/gi, '');
};
