function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      display: block;\n      ", ";\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled, { css } from 'styled-components';
import { display } from 'styled-system';
import { mediaQueries } from '../../variables';
var DesktopOnly = styled.div.withConfig({
  displayName: "DesktopOnly",
  componentId: "pkwpac-0"
})(["", ";"], function (_ref) {
  var breakpoint = _ref.breakpoint;
  return css(["display:none;", ";"], mediaQueries[breakpoint || 'medium'](_templateObject(), display));
});
export default DesktopOnly;