import SentryManager from '/util/SentryManager';

import React, { useEffect, useState } from 'react';
import { subscribeNewLocation } from '/api';

import { StepProps } from '../StepWrapper';

import * as utils from '/util/registerEvent';

import * as validation from '/util/validation';

import Input from '../Input';
import { SimpleKV } from '../types';
import Buttons from '../Buttons';
import PreneedHeader from '../PreneedHeader';
import {
  MessageWrapper,
  QuestionWrapper,
  StepSpinner,
  SignUpBtnSpacer,
} from '../styled';

const STEP_ID = 'ServiceUnavailable';

async function signUpToMailingList(fields: SimpleKV) {
  await subscribeNewLocation({
    firstName: fields.subscriberFirstName,
    lastName: fields.subscriberLastName,
    // TODO: find out why zip is still referenced in this subscription call
    cityState: fields.subscriberCity,
    email: fields.subscriberEmail,
    phone: fields.subscriberPhone,
  });
}

interface InputType {
  id: string;
  label: string;
  isValidCheck: (value: any) => boolean;
}

const ErrorMsg = () => {
  const errMsg = `There was an unexpected error.\nPlease try again later`;

  return (
    <React.Fragment>
      <QuestionWrapper>
        <MessageWrapper>{errMsg}</MessageWrapper>
      </QuestionWrapper>
    </React.Fragment>
  );
};

const ServiceUnavailable = (props: StepProps) => {
  const { quoteData, skip, stepForward, recordStepStarted } = props;
  const { serviceAvailable, countyRef } = quoteData;
  const placeId = String(countyRef);
  const [submitError, setSubmitError] = useState('');

  if (serviceAvailable) {
    skip();
    return <StepSpinner />;
  }

  useEffect(() => {
    if (!serviceAvailable) {
      recordStepStarted();
    }
  }, []);

  const inputs: InputType[] = [
    {
      id: 'subscriberFirstName',
      label: 'First name',
      isValidCheck: validation.isName,
    },
    {
      id: 'subscriberLastName',
      label: 'Last name',
      isValidCheck: validation.isName,
    },
    {
      id: 'subscriberCity',
      label: 'City and State',
      isValidCheck: validation.isRequired,
    },
    {
      id: 'subscriberEmail',
      label: 'Email address',
      isValidCheck: validation.isEmail,
    },
    {
      id: 'subscriberPhone',
      label: 'Phone number',
      isValidCheck: validation.isPhoneNumber,
    },
  ];

  const [fields, setFields] = useState({});

  const continueDisabled = !inputs.every(({ id, isValidCheck }) => {
    const value = fields[id];
    return value && isValidCheck(value);
  });

  const updateFieldValue = (id: string, value: string) => {
    setFields({ ...fields, [id]: value });
  };

  // trigger error message handling
  if (submitError) {
    return <ErrorMsg />;
  }

  return (
    <React.Fragment>
      <QuestionWrapper>
        <PreneedHeader placeId={placeId} />
        {inputs.map(({ id, label, isValidCheck }) => (
          <Input
            key={id}
            id={id}
            value={fields[id] || ''}
            onBlur={() => {
              utils.sendDataEnteredEvent(STEP_ID, {
                id,
                value: fields[id],
              });
            }}
            label={label}
            isValidCheck={isValidCheck}
            onChange={updateFieldValue}
          />
        ))}
      </QuestionWrapper>
      <SignUpBtnSpacer></SignUpBtnSpacer>
      <Buttons
        disabled={continueDisabled}
        continueText={'Sign up'}
        continueAction={async () => {
          try {
            await signUpToMailingList(fields);
            utils.sendStepCompletedEvent(STEP_ID, fields);
            stepForward();
          } catch (e) {
            SentryManager.captureException(e);

            // Don't show error message if they are alreay on mailing list
            if (
              e.response.data.message &&
              e.response.data.message.includes('is already a list member')
            ) {
              stepForward();
            } else {
              setSubmitError(e);
            }
          }
        }}
        hideBack={true}
      />
    </React.Fragment>
  );
};

ServiceUnavailable.displayName = STEP_ID;

export default ServiceUnavailable;
