// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import CollapsibleText from './CollapsibleText';
import Star from './icons/Star';

const Wrapper = styled.div`
  padding: 60px 0 50px;
  width: 100%;
  border-top: 1px solid #ecf3f4;

  display: flex;

  &:last-child {
    border-bottom: 1px solid #ecf3f4;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const PersonalInfo = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  display: block;
  font-size: 18px;
  color: ${({ theme }) => theme.textHard};
`;

const Location = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.textSpecial};
  font-size: 16px;
`;

const DateWrap = styled.div`
  color: #7c88a3;
  font-size: 14px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RatingLinkInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 15px;
`;

const Rate = styled.div`
  color: black;

  & > svg {
    margin-right: 3px;
  }
`;

const Logo = styled.a`
  margin-left: auto;

  & > img {
    height: 35px;
  }
`;

type Props = {
  name: string,
  rating: number,
  text: string,
  source: string,
  location: string,
  date: string,
};

const yelpUrl = 'https://www.yelp.com/biz/tulip-cremation-san-francisco';

const googleUrl =
  'https://www.google.com/maps/place/Tulip+Cremation/@37.317805,-122.9952567,8z/data=!4m7!3m6!1s0x808580621cba18f9:0x3fe8d5152eaffec4!8m2!3d37.791917!4d-122.400662!9m1!1b1';

const Testimonial = ({
  name = '',
  rating,
  text,
  source,
  location,
  date,
}: Props) => {
  const formattedDate = moment(date).format('MMM DD, YYYY');

  return (
    <Wrapper>
      <PersonalInfo>
        <Name>{name}</Name>
        <Location>{location}</Location>
        <DateWrap>
          {formattedDate === 'Invalid date' ? '' : formattedDate}
        </DateWrap>
      </PersonalInfo>
      <Content>
        <RatingLinkInfo>
          <Rate>
            {[1, 2, 3, 4, 5].map(index => (
              <Star
                key={index}
                color={index <= rating ? '#FFD72E' : '#E7F1F2'}
              />
            ))}
          </Rate>
          <Logo
            href={
              source === 'YELP' ? yelpUrl : source === 'GOOGLE' ? googleUrl : ''
            }
            target="_blank"
            link={source}
          >
            <img
              src={
                source === 'YELP'
                  ? '/img/yelp-logo.png'
                  : source === 'GOOGLE'
                  ? '/img/google-logo.png'
                  : '/img/logo-square.png'
              }
            />
          </Logo>
        </RatingLinkInfo>
        <CollapsibleText text={text} length={430} />
      </Content>
    </Wrapper>
  );
};

export default Testimonial;
