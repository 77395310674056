// @flow
// Allows for function composition. eg: instead of
// a(b(c(X))); you can call compose(a, b, c) to create
// a function that calls each function with the result of
// the last and then call that function with your initial argument
const compose = (...functionsToChain) => {
  // build the new compose function by reducing the array
  // of funcs passed in building up one large accumulated
  // function to return.

  // we use an echo Function as the start of the reduce loop
  // so that the inner-most function (the end of the chain)
  // returns the final value.
  const echoFunction = arg => arg;

  // Each loop of the reduce will return a function
  // that calls the accumulated function with the result of
  // the next function's execution.
  // Thus adding another nested function to the chain.
  return functionsToChain.reduce((accumulatedFunc, currentFunc) => {
    return (...args) => {
      return accumulatedFunc(currentFunc(...args));
    };
  }, echoFunction);
};

export default compose;
