// @flow
import * as React from 'react';
import styled from 'styled-components';

import Hcont from './Hcont';
import Heading from './Heading';

type Props = {
  text: string,
};

export default function PlainBannerHeading({ text }: Props) {
  return (
    <Wrapper>
      <Hcont>
        <CustomHeading tag="h1">{text}</CustomHeading>
      </Hcont>
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  padding: 75px 0;
  background-color: #e9f1f3;
`;

const CustomHeading = styled(Heading)`
  max-width: 820px;
  margin: 0 auto;
  text-align: center;
  color: #243966;
`;
