// @flow
import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { BREAKPOINTS } from '../globalStyles';

const BREAK_POINT = {
  large: 1310,
  medium: 1152,
  small: 670,
};

type Props = {
  carouselItems: Object,
  carouselType: String,
  anchor?: String,
};

class Carousel extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.state = {
      slideHeight: 'auto',
    };
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  setSlideHeight() {
    // Get slide track height minus slide padding.
    const sliderContainer = document.getElementsByClassName('slick-slider')[0];
    const slideHeight = sliderContainer ? sliderContainer.clientHeight - 64 : 0;
    // Set slide height to height of slide track.
    this.setState({ slideHeight });
  }

  componentDidMount() {
    if (this.props.carouselType !== 'simple') return;
    setTimeout(() => {
      this.setSlideHeight();
    }, 300);

    const resizeWindow = () => {
      this.setState({ slideHeight: 'auto' });
      setTimeout(() => {
        this.setSlideHeight();
      }, 120);
    };

    window.addEventListener('resize', resizeWindow);
  }

  render() {
    const { slideHeight } = this.state;

    const { carouselItems, carouselType } = this.props;
    const simpleCarousel = carouselType === 'simple';

    const ArrowContainer = styled('div')`
      display: flex;
      justify-content: space-between;
      padding: 0 70px;
      @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
        padding: 0 16px 16px 16px;
      }
    `;

    const PrevArrow = styled('div')`
      content: url('img/brochure/icon-arrow-left.svg');
      font-size: 30px;
      color: black;
      cursor: pointer;
    `;

    const NextArrow = styled('div')`
      content: url('img/brochure/icon-arrow-right.svg');
      font-size: 30px;
      color: black;
      cursor: pointer;
    `;

    const SlickSlider = styled(Slider)`
      width: 100%;
      .slick-prev:before {
        content: url('img/brochure/icon-arrow-left.svg');
        font-size: 30px;
        color: black;
      }
      @media (max-width: ${BREAK_POINT.large}px) {
        width: ${simpleCarousel ? '100%' : '70%'};
        margin: 0 auto;
      }
      @media (max-width: ${BREAK_POINT.small}px) {
        width: 100%;
      }
      .slick-dots {
        position: ${simpleCarousel ? 'static' : ''};
        margin: ${simpleCarousel ? '16px 0 32px 0' : '0'};
      }
      .slick-next:before {
        content: url('img/brochure/icon-arrow-right.svg');
        font-size: 30px;
        color: black;
      }
      .slick-track {
        @media (max-width: ${BREAK_POINT.small}px) {
          padding: ${simpleCarousel ? '8px 0' : '0'};
        }
        padding: ${simpleCarousel ? '32px 0' : '0'};
      }
      .slick-slide {
        height: ${slideHeight === 'auto' ? 'auto' : `${slideHeight}px`};
      }
      .slick-slide > div {
        display: ${simpleCarousel ? 'flex' : 'block'};
        align-items: stretch;
        height: ${simpleCarousel ? '100%' : 'auto'};
      }
    `;

    let settings = {
      slidesToShow: 3, // number of items shown each time
      slidesToScroll: 1, // number of items to scroll through
      arrows: !simpleCarousel,
      dots: simpleCarousel,
      centerMode: simpleCarousel,
      centerPadding: '10%',
      infinity: true, // infinitely scroll
      mobileFirst: true,
      responsive: [
        {
          breakpoint: BREAK_POINT.large,
          settings: {
            slidesToShow: 2,
            centerMode: simpleCarousel,
            centerPadding: '18%',
          },
        },
        {
          breakpoint: BREAK_POINT.medium,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: simpleCarousel,
            centerPadding: '25%',
          },
        },
        {
          breakpoint: BREAK_POINT.small,
          settings: {
            arrows: false,
            slidesToShow: 1,
            dots: true,
            centerMode: false,
          },
        },
      ],
    };

    return (
      <>
        {simpleCarousel && (
          <ArrowContainer id={this.props.anchor}>
            <PrevArrow onClick={this.previous} />
            <NextArrow onClick={this.next} />
          </ArrowContainer>
        )}
        <SlickSlider innerRef={slider => (this.slider = slider)} {...settings}>
          {carouselItems}
        </SlickSlider>
      </>
    );
  }
}

export default Carousel;
