'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listFullUrl = exports.getUrl = undefined;

var _models = require('../models');

var getUrl = exports.getUrl = function getUrl() {
  return '/purchases/:id';
};
var listFullUrl = exports.listFullUrl = function listFullUrl() {
  return '/purchases/full';
};