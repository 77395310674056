import { css } from 'styled-components';

var createQuery = function createQuery(size) {
  var minMax = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'min';
  return function () {
    for (var _len = arguments.length, rules = new Array(_len), _key = 0; _key < _len; _key++) {
      rules[_key] = arguments[_key];
    }

    return css(["@media screen and (", "-width:", "px){", "}"], minMax, size, rules);
  };
};

export var BREAKPOINTS = {
  XLARGE: 1200,
  LARGE: 992,
  MEDIUM: 768,
  SMALL: 575
};
export var mediaQueries = {
  // Extra large devices (large desktops, 1200px and up)
  xlarge: createQuery(BREAKPOINTS.XLARGE),
  // Large devices (desktops, 992px and up)
  large: createQuery(BREAKPOINTS.LARGE),
  // Medium devices (tablets, 768px and up)
  medium: createQuery(BREAKPOINTS.MEDIUM),
  // Small devices (landscape phones, 576px and up)
  small: createQuery(BREAKPOINTS.SMALL),
  // Extra small devices
  xsmall: createQuery(BREAKPOINTS.SMALL, 'max')
};