// @flow
import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../components';
import { BREAKPOINTS } from '../../globalStyles';

type Props = {
  cards?: Array,
  title?: String,
  anchor?: String,
  italicText?: String,
  region?: String,
};

export default function CardDeck({
  title,
  cards,
  anchor,
  italicText,
  region,
}: Props) {
  const Wrapper = styled.div`
    background: rgb(27, 41, 82);
    background: linear-gradient(
      90deg,
      rgba(27, 41, 82, 1) 49%,
      rgba(255, 255, 255, 1) 51%,
      rgba(245, 249, 252, 1) 53%
    );
  `;

  const Deck = styled.div`
    display: flex;
    background-color: #f5f9fc;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      flex-direction: column;
    }
  `;

  //this is just a comment
  const Card = styled.div`
    flex: 1;
    padding: 85px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid rgba(162, 198, 205, 0.3);

    &:last-child {
      border-right: none;
    }

    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      border-right: none;
      border-bottom: 1px solid rgba(162, 198, 205, 0.3);
      padding: 32px;

      ${region === 'Nevada' ? '&:nth-last-child(2) {border-bottom: none;}' : ''}

      &:last-child {
        border-bottom: none;
      }
    }
  `;

  const TitleCard = styled(Card)`
    background-color: #1b2952;
    align-items: center;
    padding: 64px 70px;

    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      padding: 64px 32px;
      align-items: flex-start;
    }
  `;

  const StyledHeading = styled(Heading)`
    color: #fff;
    margin: 0;
  `;

  const H4 = styled.h4`
    color: ${({ theme }) => theme.textHard};
    text-align: left;
    margin: 0;
    font-size: 16px;
  `;

  const P = styled.p`
    font-size: 16px;
  `;

  const PItalic = styled.p`
    font-size: 16px;
    font-style: italic;
    margin: 0 0;
  `;

  return cards && cards.length ? (
    <Wrapper>
      <Deck id={anchor}>
        <TitleCard>
          <StyledHeading>
            {region === 'Minnesota'
              ? 'Cremation and Funeral Services in MN'
              : region === 'Northern California'
              ? 'Cremation and Funeral Services'
              : region === 'Wisconsin'
              ? 'Cremation and Funeral Services in WI'
              : region === 'Idaho'
              ? 'Cremation and Funeral Services in ID'
              : region === 'Colorado' || region === 'Oregon - Portland'
              ? 'Cremation and Funeral Services'
              : title}
          </StyledHeading>
        </TitleCard>
        {cards.map((card, i) => (
          <Card key={i}>
            {(region === 'Gulf Coast' && card.title === 'Immediate need') ||
            (region === 'Southern California' &&
              card.title === 'Immediate need') ? (
              <H4>{`${card.title} services for ${region} cremations`}</H4>
            ) : (
              <H4>{card.title}</H4>
            )}
            {card.title === 'Soon' && italicText ? (
              <>
                <P>
                  {card.body}
                  <PItalic>{'We have a service for you.'}</PItalic>
                </P>
              </>
            ) : (<P>{card.body}</P>)}
          </Card>
        ))}
      </Deck>
    </Wrapper>
  ) : null;
}
