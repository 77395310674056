'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBSCRIBE_SUCCESS_STEP = exports.NEW_LOCATION_STEP = exports.MAIN_QUOTE_FLOW = exports.QUOTE_FLOW_STEP_TYPES = undefined;

var _constants = require('./constants');

require('./types');

// THIS FILE IS ONLY USED BY tulip-admin/app/containers/CaseOverview/ACtivatePreNeedForm and should be removed when that changes
// there's a ticket CUST-301.

var QUOTE_FLOW_STEP_TYPES = exports.QUOTE_FLOW_STEP_TYPES = Object.freeze({
  LOCATION_AUTOCOMPLETE: 'locationAutocomplete',
  MULTIPLE_CHOICE: 'multipleChoice',
  INPUTS: 'inputs',
  MESSAGE: 'message',
  WELCOME: 'welcome'
});

var MAIN_QUOTE_FLOW = exports.MAIN_QUOTE_FLOW = [{
  type: QUOTE_FLOW_STEP_TYPES.MULTIPLE_CHOICE,
  id: 'purchaseType',
  heading: 'When do you require our services?',
  isSingleColumn: true,
  options: [{
    value: _constants.PURCHASE_TYPE.IMMEDIATELY,
    name: 'A loved one has passed',
    largeName: 'A loved one has passed'
  }, {
    value: _constants.PURCHASE_TYPE.SOON,
    name: 'A loved one is about to pass',
    largeName: 'A loved one is about to pass'
  }, {
    value: _constants.PURCHASE_TYPE.PRENEED,
    name: "I'm planning for the future",
    largeName: "I'm planning for the future"
  }]
}, {
  type: QUOTE_FLOW_STEP_TYPES.LOCATION_AUTOCOMPLETE,
  id: 'county',
  isSingleColumn: true,
  heading: function heading(_ref) {
    var purchaseType = _ref.purchaseType;

    switch (purchaseType) {
      case _constants.PURCHASE_TYPE.PRENEED:
        return 'What city do you live in?';
      case _constants.PURCHASE_TYPE.IMMEDIATELY:
        return 'In what city did your loved one pass?';
      case _constants.PURCHASE_TYPE.SOON:
        return 'In what city does your loved one live?';
      default:
        "Shouldn't see this";
    }
  }
}, {
  type: QUOTE_FLOW_STEP_TYPES.MULTIPLE_CHOICE,
  id: 'decedentLocationType',
  skipIf: [_constants.PURCHASE_TYPE.PRENEED],
  heading: 'Where is your loved one at present?',
  options: [{ value: _constants.DECEDENT_LOCATION_TYPE.HOSPITAL, name: 'Hospital' }, { value: _constants.DECEDENT_LOCATION_TYPE.RESIDENCE, name: 'Private residence' }, { value: _constants.DECEDENT_LOCATION_TYPE.NURSING_HOME, name: 'Nursing home' }, { value: _constants.DECEDENT_LOCATION_TYPE.CORONER, name: "Coroner's office" }, {
    value: _constants.DECEDENT_LOCATION_TYPE.FUNERAL_HOME,
    name: 'Funeral Home',
    hideInSmall: true
  }]
}, {
  type: QUOTE_FLOW_STEP_TYPES.MULTIPLE_CHOICE,
  id: 'decedentOverweight',
  skipIf: [_constants.PURCHASE_TYPE.PRENEED],
  heading: 'What is the approximate weight of your loved one?',
  options: [{ value: false, name: 'Under 300lbs' }, { value: true, name: 'Between 300 - 500lbs' }],
  footer: 'We are unable to provide services for loved ones that are over 500lbs'
}, {
  type: QUOTE_FLOW_STEP_TYPES.MULTIPLE_CHOICE,
  id: 'decedentImplant',
  skipIf: [_constants.PURCHASE_TYPE.PRENEED],
  heading: 'Does your loved one have a pacemaker or any other battery-operated implant?',
  options: [{ value: true, name: 'Yes' }, { value: false, name: 'No' }]
}, {
  type: QUOTE_FLOW_STEP_TYPES.INPUTS,
  id: 'createQuoteForm',
  heading: "We'll also send you a quote:",
  inputs: [{
    id: 'purchaserFirstName',
    label: 'First name',
    type: 'text',
    placeholder: 'Your first name'
  }, {
    id: 'purchaserLastName',
    label: 'Last name',
    type: 'text',
    placeholder: 'Your last name'
  }, {
    id: 'purchaserEmail',
    label: 'Email address',
    type: 'email',
    placeholder: 'Your email address'
  }, {
    id: 'purchaserPhone',
    label: 'Phone number',
    type: 'tel',
    placeholder: 'Your phone number'
  }]
}];

var NEW_LOCATION_STEP = exports.NEW_LOCATION_STEP = {
  type: QUOTE_FLOW_STEP_TYPES.INPUTS,
  id: 'newLocationForm',
  heading: function heading(_ref2) {
    var purchaseType = _ref2.purchaseType;

    return purchaseType === _constants.PURCHASE_TYPE.PRENEED ? "We'll let you know as soon as Tulip pre-need plans are available in your area:" : 'We are not yet in your area. Sign up for our mailing list and we will notify you when we expand to your city.';
  },
  inputs: [{
    id: 'subscriberFirstName',
    label: 'First name',
    type: 'text',
    placeholder: 'Your first name'
  }, {
    id: 'subscriberCity',
    label: 'City and State',
    type: 'text',
    placeholder: 'Your city and state'
  }, {
    id: 'subscriberEmail',
    label: 'Email address',
    type: 'email',
    placeholder: 'Your email address'
  }]
};

var SUBSCRIBE_SUCCESS_STEP = exports.SUBSCRIBE_SUCCESS_STEP = {
  id: 'subscribeSuccess',
  type: QUOTE_FLOW_STEP_TYPES.MESSAGE,
  message: "Thank you! We'll be in touch."
};