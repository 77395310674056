function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    font-size: 24px;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    height: 90px;\n    width: 90px;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    height: 90px;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    width: 600px;\n    max-width: 600px;\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled, { css } from 'styled-components';
import { mediaQueries } from '../../variables';
export var Wrapper = styled.div.withConfig({
  displayName: "styled__Wrapper",
  componentId: "sc-15vfcll-0"
})(["max-width:320px;position:relative;", ";"], mediaQueries.medium(_templateObject()));
var BaseContent = css(["background-color:white;height:72px;display:flex;justify-content:space-between;align-items:center;padding-left:16px;box-sizing:border-box;", ";"], mediaQueries.medium(_templateObject2()));
export var Content = styled.form.withConfig({
  displayName: "styled__Content",
  componentId: "sc-15vfcll-1"
})(["", ";border:2px solid #b3bcc4;border-radius:3px;& svg{width:35px;height:35px;}"], BaseContent);
export var ResultContent = styled.div.withConfig({
  displayName: "styled__ResultContent",
  componentId: "sc-15vfcll-2"
})(["", ";justify-content:flex-start;&:hover{background-color:#c2efee;}"], BaseContent);
export var Button = styled.button.withConfig({
  displayName: "styled__Button",
  componentId: "sc-15vfcll-3"
})(["background-color:#093969;height:72px;width:72px;border-radius:3px;transform:translateX(2px);border:0;margin:0;cursor:pointer;&:focus{outline:0;}&:hover{background-color:#126fcb;}& svg{width:27px;height:27px;}", ";"], mediaQueries.medium(_templateObject3()));
export var Center = styled.div.withConfig({
  displayName: "styled__Center",
  componentId: "sc-15vfcll-4"
})(["width:100%;height:100%;display:flex;justify-content:center;align-items:center;"]);
export var Input = styled.input.withConfig({
  displayName: "styled__Input",
  componentId: "sc-15vfcll-5"
})(["font-size:16px;font-weight:300;", ";", ";border:0;margin:0 auto 0 16px;", ";&:focus{outline:0;}"], mediaQueries.medium(_templateObject4()), function (_ref) {
  var theme = _ref.theme;
  return css(["color:", ";&::placeholder{color:", ";}"], theme.colors.bodyTextBaseDark, theme.colors.bodyTextBaseDark);
}, ''
/* flex: 1; */
);
export var Results = styled.div.withConfig({
  displayName: "styled__Results",
  componentId: "sc-15vfcll-6"
})(["position:absolute;z-index:10;width:100%;max-height:200px;overflow:auto;border:1px solid #b3bcc4;border-top:0;border-radius:0 0 3px 3px;"]);