function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    top: 20px;\n\n    & svg {\n      width: 25px;\n      height: 25px;\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    height: auto;\n\n    padding: 100px 40px 100px;\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import * as React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../variables';
import Close from '../Icons/Close';
import { H2Special as H2 } from '../Typography';
import WithPortal from '../../hocs/WithPortal';
var Background = styled.div.withConfig({
  displayName: "Modal__Background",
  componentId: "sc-13qeu5p-0"
})(["position:fixed;top:0;right:0;bottom:0;left:0;background-color:rgba(0,0,0,0.5);display:flex;justify-content:center;align-items:center;z-index:1000;& *{box-sizing:border-box;}"]);
var Wrapper = styled.div.withConfig({
  displayName: "Modal__Wrapper",
  componentId: "sc-13qeu5p-1"
})(["width:100%;height:100vh;max-width:920px;background-color:white;position:relative;padding:24px 40px 20px;display:flex;justify-content:flex-start;align-items:center;flex-direction:column;", ";"], mediaQueries.medium(_templateObject()));
var CloseWrapper = styled.div.withConfig({
  displayName: "Modal__CloseWrapper",
  componentId: "sc-13qeu5p-2"
})(["margin-left:auto;position:absolute;cursor:pointer;top:30px;right:20px;& svg{width:18px;height:18px;}", ";"], mediaQueries.medium(_templateObject2()));

var Modal = function Modal(_ref) {
  var title = _ref.title,
      onClose = _ref.onClose,
      children = _ref.children,
      renderHeader = _ref.renderHeader;
  return React.createElement(WithPortal, {
    elementId: "modal"
  }, React.createElement(Background, {
    onClick: onClose
  }, React.createElement(Wrapper, {
    onClick: function onClick(e) {
      e.stopPropagation();
    }
  }, React.createElement(CloseWrapper, {
    onClick: onClose
  }, React.createElement(Close, null)), renderHeader && renderHeader(), React.createElement(H2, {
    mb: 20
  }, title), children)));
};

export default Modal;