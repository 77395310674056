// @flow
import styled, { css } from 'styled-components';
import { Query } from 'react-apollo';
import * as React from 'react';

import { SimpleSpinner } from '../components';

type Props = {
  children: array,
  variables: object,
  query: string,
};

// QueryWithSpinner provides a GraphQL Query Wrapper with a built in spinner it
// also supports usage as both a lambda component so you can work with the
// queryData to change its shape for your props:
//
// <QueryWithSpinner>
//   { (queryData) => {
//      const { bar } = queryData.county.purchaseOptions;
//      <WrappedComponent foo={bar}/>
// </QueryWithSpinner>
//
// and a regular wrapper component:
//
// <QueryWithSpinner>
//   <WrappedComponent /> // queryData passed in as queryData prop
// </QueryWithSpinner>
//
const QueryWithSpinner = (props: Props) => {
  const { query, children, variables } = props;
  return (
    <Query query={query} variables={variables}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <SpinnerContainer visible>
              <SimpleSpinner />
            </SpinnerContainer>
          );
        }

        if (error) {
          if (typeof window !== undefined && window.Sentry) {
            window.Sentry.captureMessage(error);
          }
        }

        // Handle usage as a regular wrapping component
        if (React.isValidElement(children)) {
          return React.Children.map(children, child =>
            React.cloneElement(child, { queryData: data, queryError: error })
          );
          // Handle usage as a lambda component
        } else {
          return children(data);
        }
      }}
    </Query>
  );
};

export default QueryWithSpinner;

const SpinnerContainer = styled('div')`
  font-size: 0.7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  ${({ visible }) => {
    if (visible) {
      return css`
        opacity: 1;
      `;
    } else {
      return css`
        opacity: 0;
      `;
    }
  }}
`;
