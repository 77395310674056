'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaults = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.getTotal = getTotal;
exports.getDescription = getDescription;
exports.isForPackage = isForPackage;
exports.getPackageItem = getPackageItem;
exports.generateBasicLineItem = generateBasicLineItem;
exports.generateFromPurchase = generateFromPurchase;

var _util = require('../util');

var _constants = require('../constants');

var _lineItem = require('./line-item');

var LineItem = _interopRequireWildcard(_lineItem);

var _purchase = require('./purchase');

var Purchase = _interopRequireWildcard(_purchase);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// todo: remove
var defaults = exports.defaults = {
  description: null,
  primary: false,
  recipientEmail: null,
  recipientFirstName: null,
  recipientLastName: null,
  createdById: null,
  paidAt: null,
  refundedAt: null,
  lineItems: [],
  stripeChargeId: null,
  affirmChargeId: null
};

function getTotal(invoice) {
  return invoice.lineItems.reduce(function (carry, li) {
    return carry + LineItem.getItemTotal(li);
  }, 0);
}

function getDescription(invoice) {
  if (invoice.description) return invoice.description;
  return invoice.lineItems.map(function (li) {
    return LineItem.getDescription(li);
  }).join(', ');
}

function isForPackage(invoice) {
  if (!invoice || !invoice.lineItems || invoice.lineItems.length === 0) {
    return false;
  }
  return invoice.lineItems.some(function (item) {
    return item.type === 'PACKAGE' || item.type === 'package_mandatory_cert';
  });
}

function getPackageItem(invoice) {
  if (!invoice || !invoice.lineItems || invoice.lineItems.length === 0) {
    throw new Error('cannot find lineItem in invoice');
  }

  var texasLineItem = invoice.lineItems.find(function (item) {
    return item.type === 'package_mandatory_cert';
  });
  var defaultLineItem = invoice.lineItems.find(function (item) {
    return item.type === 'PACKAGE';
  });
  if (texasLineItem) {
    return texasLineItem;
  } else if (defaultLineItem) {
    return defaultLineItem;
  } else {
    throw new Error('No package line item in the invoice');
  }
}

function generateBasicLineItem(_ref) {
  var type = _ref.type,
      unitPrice = _ref.unitPrice,
      _ref$quantity = _ref.quantity,
      quantity = _ref$quantity === undefined ? 1 : _ref$quantity,
      customDescription = _ref.customDescription;

  return (0, _extends3.default)({}, LineItem.defaults, {
    id: (0, _util.makeGuid)(),
    type: type,
    unitPrice: unitPrice,
    quantity: quantity,
    customDescription: customDescription
  });
}

/**
 * Used to generate primary invoice from purchase data.
 * @param {Object} purchase
 */
function generateFromPurchase(purchase) {
  var lineItems = [];

  lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
    id: (0, _util.makeGuid)(),
    type: 'PACKAGE',
    unitPrice: purchase.quotedPackagePrice
  }));

  if (purchase.numDeathCertificates && purchase.numDeathCertificates > 0) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'DEATH_CERT',
      unitPrice: purchase.quotedDeathCertificatePrice,
      quantity: typeof purchase.quotedNoninitialDeathCertificatePrice === 'number' ? 1 : purchase.numDeathCertificates
    }));
    if (typeof purchase.quotedNoninitialDeathCertificatePrice === 'number' && purchase.numDeathCertificates > 1) {
      lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
        id: (0, _util.makeGuid)(),
        type: 'DEATH_CERT',
        unitPrice: purchase.quotedNoninitialDeathCertificatePrice,
        quantity: purchase.numDeathCertificates - 1
      }));
    }
  }
  if (purchase.decedentLocationType === 'residence') {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'TRANSPORT',
      unitPrice: purchase.quotedTransportPrice
    }));
  }
  if (purchase.decedentOverweight) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'OVERWEIGHT',
      unitPrice: purchase.quotedOverweightPrice
    }));
  }
  if (purchase.decedentImplant) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'IMPLANT',
      unitPrice: purchase.quotedImplantPrice
    }));
  }
  if (purchase.quotedExpeditedShippingDeathCertPrice) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'EXPEDITED_SHIPPING_DEATH_CERT',
      unitPrice: purchase.quotedExpeditedShippingDeathCertPrice
    }));
  }
  if (purchase.deliveryOption === _constants.DELIVERY_OPTION.HAND_DELIVERY) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'HAND_DELIVERY',
      unitPrice: purchase.quotedHandDeliveryPrice
    }));
  }
  if (purchase.deliveryOption === _constants.DELIVERY_OPTION.MAIL) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'USPS_DELIVERY',
      unitPrice: purchase.quotedUspsDeliveryPrice
    }));
  }
  if (purchase.deliveryOption === _constants.DELIVERY_OPTION.SCATTER_AT_SEA) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'SCATTER_AT_SEA',
      unitPrice: purchase.quotedScatterAtSeaPrice
    }));
  }
  if (purchase.deliveryOption === _constants.DELIVERY_OPTION.MOUNTAIN_SCATTER) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'MOUNTAIN_SCATTER',
      unitPrice: purchase.quotedMountainScatterPrice
    }));
  }
  if (purchase.discount) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'discount',
      unitPrice: -purchase.discount.value
    }));
  }
  if (purchase.isGlobalCoverage === true) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'GLOBAL_COVERAGE',
      unitPrice: purchase.quotedGlobalCoveragePrice
    }));
  }
  if (typeof purchase.quotedFee === 'number' && purchase.quotedFee > 0) {
    lineItems.push((0, _extends3.default)({}, LineItem.defaults, {
      id: (0, _util.makeGuid)(),
      type: 'QUOTED_FEE',
      unitPrice: purchase.quotedFee
    }));
  }

  var contact = Purchase.extractContactInfo(purchase, 'purchaser');
  return (0, _extends3.default)({}, defaults, {
    id: (0, _util.makeGuid)(),
    purchaseId: purchase.id,
    status: _constants.INVOICE_STATUS.PENDING,
    description: 'Web Checkout',
    primary: true,
    recipientEmail: contact.email,
    recipientFirstName: contact.firstName,
    recipientLastName: contact.lastName,
    createdAt: new Date(),
    lineItems: lineItems
  });
}