'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requiredFields = requiredFields;


/**
 * Input should be flat
 */
function requiredFields(flatInput, fields) {
  var errors = {};
  fields.forEach(function (field) {
    var value = flatInput[field] != null ? flatInput[field] : null;
    value = value && value.trim ? value.trim() : value;
    if (value === null || value === '') {
      errors[field] = true;
    }
  });
  return errors;
} /**
   * Super simple validation logic for now, which only supports fields being
   * required (partly due to limits in design).
   *
   * At some point will want to come up with API for more types of validation,
   * and integrate it with the Form component.
   *
   * 
   */