// @flow
import { CasesApi } from 'tulip-core/api-specs';

import { tulipApi, reviewsApi } from './client';

export async function assumeCase(caseId: string) {
  return tulipApi.get(CasesApi.cookieAssumeUrl(caseId));
}

export async function fromQuote(values) {
  return tulipApi.post(CasesApi.fromQuoteUrl(), values);
}

export async function updatePurchase(id: string, values: Object) {
  return tulipApi.patch(CasesApi.patchUrl(id), values);
}

export async function callMeBack(
  userInfo: {
    email: string,
    firstName: string,
    phone: string,
    time: string,
  },
  isPreneed: ?boolean
) {
  const url = isPreneed
    ? '/subscribe/call-me-back-preneed'
    : '/subscribe/call-me-back';

  return tulipApi.post(url, userInfo);
}

export async function subscribeNewLocation(userInfo: {
  firstName: string,
  lastName: string,
  cityState: string,
  email: string,
  phone: string,
}) {
  const {
    firstName = '',
    lastName = '',
    cityState = '',
    email = '',
    phone = '',
  } = userInfo;

  const today = new Date();

  const updatedUserInfo = {
    firstName,
    lastName,
    // TODO: find out why zip is still referenced in this subscription call
    zip: cityState,
    email,
    phone,
    quoteDate: today,
  };
  return tulipApi.post('/subscribe/new-location', updatedUserInfo);
}

export async function checkoutPayment({
  purchaseId,
  token,
  paymentMethod,
}: {
  purchaseId: string,
  paymentMethod: string,
  token: string,
}) {
  return tulipApi.post(`/payment/${purchaseId}/checkout/${token}`, {
    paymentMethod,
  });
}

export async function getInvoice(invoiceId: string) {
  return tulipApi.get(`/invoices/${invoiceId}`);
}

export async function clearCase() {
  return tulipApi.get('/cases/clear');
}

export async function preNeedInterest(values) {
  return tulipApi.post('/pre-need', values);
}

export async function invoicePayment({
  invoiceId,
  token,
  noCollect,
}: {
  invoiceId: string,
  token: string,
  noCollect: ?boolean,
}) {
  let url = `/payment/invoice/${invoiceId}/${token}`;
  return tulipApi.post(url, null, { params: { 'no-collect': noCollect } });
}

export async function cardPayment({
  purchaseId,
  token,
}: {
  purchaseId: string,
  token: string,
}) {
  return tulipApi.put(`/payment/${purchaseId}/card`, { token: token.id });
}

export async function fetchReviews() {
  return reviewsApi.get(
    'https://script.google.com/macros/s/AKfycbz4TXRgx0zteLIOGmGg1ecWDLqxLLd4lpW9Ly2zB1EnfFdWym8/exec'
  );
}
