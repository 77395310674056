// @flow
export function centerConstrain(
  width: number,
  { margin }: { margin?: number } = {}
): string {
  return `
    margin: ${margin || 0}px auto;
    max-width: ${width}px;
    text-align: center;
  `;
}

export function ellipsize(): string {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
}

export const fontSize = (size: number): string => rem('font-size', size);

export function rem(property: string, sizeInPx: number): string {
  const size = Math.round(sizeInPx);
  return `
    ${property}: ${size}px;
    ${property}: ${size / 10}rem;
  `;
}
