import React, { useContext } from 'react';
import styled from 'styled-components';
import { QuoteFlowContext } from '/context';

import MultipleChoice from '../MultipleChoice';
import { StepProps } from '../StepWrapper';
import {
  StepSpinner,
  QuestionText,
  QuestionWrapper,
  QuoteFormTitle,
  CancelArrangement,
} from '../styled';

import { sendFormContinuedEvent } from '/util/registerEvent';

// If a user has an existing arrangement, the option should be provided
// to let them continue it
const ContinueArrangement = (props: StepProps) => {
  const { skip, stepIds = [], currentStep } = props;
  const { state, actions } = useContext(QuoteFlowContext);

  if (!state.activeCase) {
    skip(true);
    return <StepSpinner />;
  }

  const options = [
    {
      value: 'CONTINUE',
      name: 'Continue this arrangement',
      largeName: 'Continue this arrangement',
    },
  ];

  const onSelect = () => {
    sendFormContinuedEvent(stepIds[currentStep]);
    actions.continueQuote();
  };

  return (
    <PaddedQuestionWrapper>
      <QuoteFormTitle>Continue an Arrangement</QuoteFormTitle>
      <QuestionText>
        {"It appears that you've already asked for a quote from Tulip."}
      </QuestionText>
      <MultipleChoice options={options} onSelect={onSelect} />
      <CancelArrangement onClick={actions.clearActiveCase}>
        Cancel this arrangement
      </CancelArrangement>
    </PaddedQuestionWrapper>
  );
};

const PaddedQuestionWrapper = styled(QuestionWrapper)`
  padding-top: 64px;
`;

ContinueArrangement.hideProgressBar = true;
ContinueArrangement.displayName = 'ContinueArrangement';
ContinueArrangement.titleText = () => '';

export default ContinueArrangement;
