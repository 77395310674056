import React, { useEffect } from 'react';
import { PURCHASE_TYPE } from 'tulip-core';

import MultipleChoice from '../MultipleChoice';
import Buttons from '../Buttons';
import { StepProps } from '../StepWrapper';

import * as utils from '/util/registerEvent';

import {
  QuestionWrapperVisibilityHidden,
  QuestionText,
  FooterText,
  StepSpinner,
} from '../styled';

const STEP_ID = 'DecedentWeight';

const DecedentWeight = (props: StepProps) => {
  const {
    updateQuoteData,
    stepForward,
    skip,
    presets,
    recordStepStarted,
    recordStepPresetSkipped,
  } = props;
  const {
    decedentOverweight,
    purchaseType,
    isPurchaserBeneficiary,
  } = props.quoteData;

  const presetExists = presets.decedentOverweight !== undefined;

  // AUTOMATIC CLICK TO GET A VALUE FOR THIS SCREEN, DEFAULT VALUE: HOSPITAL ******************
  const liArray = document.getElementsByTagName('li');
  let locatedElement: HTMLLIElement;

  function loopElements() {
    for (let i = 0; i < liArray.length; i++) {
      if (liArray[i].innerText === 'Under 300lbs') {
        locatedElement = liArray[i];
        locatedElement.click();
      }
    }
  }
  // ******************************************************************************************
  useEffect(() => {
    if (
      !presets.decedentOverweight &&
      purchaseType !== PURCHASE_TYPE.PRENEED &&
      purchaseType !== PURCHASE_TYPE.PRENEED_INSURANCE
    ) {
      recordStepStarted();
      loopElements();
      // TO NOT SHOT THIS SCREEN:
      skip();
    } else if (presetExists) {
      // Set pre-recorded step
      recordStepPresetSkipped({
        id: 'decedentOverweight',
        value: presets.decedentOverweight,
      });
    }
  }, []);

  if (
    purchaseType === PURCHASE_TYPE.PRENEED ||
    purchaseType === PURCHASE_TYPE.PRENEED_INSURANCE ||
    presetExists
  ) {
    skip(presetExists);
    return <StepSpinner />;
  }

  const options = [
    {
      value: false,
      name: 'Under 300lbs',
    },
    {
      value: true,
      name: 'Between 300 - 500lbs',
    },
  ];

  const questionText = isPurchaserBeneficiary
    ? 'What is your approximate weight?'
    : 'What is the approximate weight of your loved one?';

  return (
    <React.Fragment>
      <QuestionWrapperVisibilityHidden>
        <QuestionText>{questionText}</QuestionText>
        <MultipleChoice
          activeOptionValue={decedentOverweight}
          options={options}
          onSelect={(newValue: string) => {
            updateQuoteData('decedentOverweight', newValue, STEP_ID);
            utils.sendStepCompletedEvent(STEP_ID, newValue);
            stepForward();
          }}
        />
        <FooterText>
          We are unable to provide services for loved ones that are over 500lbs
        </FooterText>
      </QuestionWrapperVisibilityHidden>
      <Buttons hideContinue />
    </React.Fragment>
  );
};

DecedentWeight.displayName = STEP_ID;

export default DecedentWeight;
