// @flow
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../globalStyles';
import Heading from './Heading';

type Props = {
  url: string,
  mobileUrl: string,
  title?: string,
  body?: string,
  heroStyle?: string,
  children?: Node,
};

export default function Hero({ url, mobileUrl, title, body, children }: Props) {
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const [mobileOffset, setMobileOffset] = useState(0);
  const lastWidth = useRef();

  const updatePlaceholderStyles = () => {
    const header = document.getElementsByTagName('header')[0];
    const headerHeight = header ? header.offsetHeight : 0;
    const heroTotal = 520 - headerHeight;
    const heroChild = document.getElementsByClassName('hero-child-wrapper')
      .length
      ? document
          .getElementsByClassName('hero-child-wrapper')[0]
          .getElementsByTagName('form')[0]
      : null;
    setMobileOffset(headerHeight + 64);

    if (heroChild) {
      const heroChildHeight = heroChild.offsetHeight;
      const heroChildToTop =
        heroChild.getBoundingClientRect().top + window.scrollY;
      const heroWithChild = heroChildToTop + heroChildHeight - headerHeight;
      setPlaceholderHeight(heroWithChild);
    } else {
      setPlaceholderHeight(heroTotal);
    }
  };

  useEffect(() => {
    updatePlaceholderStyles();

    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeWindow = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        if (lastWidth.current !== window.innerWidth) {
          lastWidth.current = window.innerWidth;
          updatePlaceholderStyles();
        }
      }, 150);
    };

    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  return (
    <>
      <Wrapper image={url} mobileImage={mobileUrl}>
        <Container>
          <StyledHeading
            tag="h1"
            size="xlarge"
            mobileSize="large"
            offset={mobileOffset}
          >
            {title === 'Cremation Services Near Me'
              ? title
              : `${title} Cremation Services`}
          </StyledHeading>
          {body && <p>{body}</p>}
          <div className="hero-child-wrapper">{children}</div>
        </Container>
      </Wrapper>
      <Placeholder height={placeholderHeight} />
    </>
  );
}

const StyledHeading = styled(Heading)`
  @media (max-width: ${BREAKPOINTS.LARGE}px) {
    margin-top: url(${props => props.offset}px);
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: ${props => props.height}px;
`;

const Container = styled.div`
  white-space: pre-wrap;
  max-width: 1800px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 70px;

  @media (max-width: ${BREAKPOINTS.LARGE}px) {
    justify-content: flex-start;
    padding: 0 16px;
    white-space: normal;
  }
`;

const Wrapper = styled.div`
  height: 520px;
  background: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: ${BREAKPOINTS.SMALL}px) {
    background: url(${props => props.mobileImage});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
