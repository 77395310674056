import React from 'react';

import { StepProps } from '../StepWrapper';
import { QuestionWrapper, MessageWrapper, StepSpinner } from '../styled';

const ThankYou = (props: StepProps) => {
  const { quoteData, skip } = props;
  const { serviceAvailable } = quoteData;

  if (serviceAvailable) {
    skip();
    return <StepSpinner />;
  }

  const messageText = "Thank you! We'll be in touch";
  return (
    <React.Fragment>
      <QuestionWrapper>
        <MessageWrapper>{messageText}</MessageWrapper>
      </QuestionWrapper>
    </React.Fragment>
  );
};

ThankYou.displayName = 'ThankYou';

export default ThankYou;
