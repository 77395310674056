// @flow
import styled from 'styled-components';

export default styled.section`
  display: block;

  ${() => 'position: relative'};

  ${p => (p.coverUrl ? `background: url(${p.coverUrl}) center center` : '')};
  background-size: cover;
`;
