import { PURCHASE_TYPE, DECEDENT_LOCATION_TYPE } from 'tulip-core';

export interface QuoteData {
  purchaseType?: PURCHASE_TYPE;
  county?: string;
  countyRef?: number;
  serviceAvailable?: boolean;
  purchaserEmail?: string;
  purchaserFirstName?: string;
  purchaserLastName?: string;
  dateOfBirth?: string;
  purchaserPhone?: string;
  decedentLocationType?: DECEDENT_LOCATION_TYPE;
  decedentOverweight?: boolean;
  decedentImplant?: boolean;
  state?: string;
  isPurchaserBeneficiary?: boolean;
  serviceTimeline?: ServiceTimeline;
  purchaserRelationship?: string;
  serviceTimelineImminent?: boolean;
}

export interface SimpleKV {
  [key: string]: any;
}

export enum ServiceTimeline {
  WITHIN_SIX_MONTHS = 'Within the next 6 months',
  BEYOND_SIX_MONTHS = 'Beyond the next 6 months',
}
