import React from 'react';

import { QuoteInput } from './styled';

interface InputProps {
  id: string;
  name?: string;
  label: string;
  value: string;
  isValidCheck: (value: string) => boolean;
  onChange: (id: string, value: string) => void;
  onBlur: () => void;
}

const Input = (props: InputProps) => {
  const { id, name, label, value, isValidCheck, onChange, onBlur } = props;
  return (
    <QuoteInput
      type={'text'}
      id={id}
      onBlur={onBlur}
      name={name}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        onChange(id, newValue);
      }}
      label={label}
      value={value}
      error={value !== '' && isValidCheck && !isValidCheck(value)}
      touched={value !== undefined}
      placeholder={label}
      hideErrors={false}
    />
  );
};

export default Input;
