import React, { useEffect } from 'react';

import MultipleChoice, { OptionType } from '../MultipleChoice';
import Buttons from '../Buttons';
import { StepProps } from '../StepWrapper';

import * as utils from '/util/registerEvent';

import { QuoteData } from '../types';
import { StepSpinner, QuestionText, QuestionWrapper } from '../styled';

interface PickerProps {
  quoteData: QuoteData;
  onSelect: (value: string) => void;
  baseOptions: OptionType[];
}

const Picker = ({ baseOptions, onSelect, quoteData }: PickerProps) => {
  const { isPurchaserBeneficiary } = quoteData;
  return (
    <MultipleChoice
      activeOptionValue={isPurchaserBeneficiary}
      options={baseOptions}
      onSelect={onSelect}
    />
  );
};

const STEP_ID = 'PurchaseFor';
const PurchaseForStep = (props: StepProps) => {
  const {
    presets,
    updateQuoteData,
    stepForward,
    skip,
    recordStepStarted,
    recordStepPresetSkipped,
  } = props;

  useEffect(() => {
    if (!presets.isPurchaserBeneficiary) {
      recordStepStarted();
    } else {
      // Set pre-recorded step
      recordStepPresetSkipped({
        id: 'isPurchaserBeneficiary',
        value: presets.isPurchaserBeneficiary,
      });
    }
  }, []);

  if (presets.purchaseType) {
    skip(true);
    return <StepSpinner />;
  }

  const baseOptions = [
    {
      value: true,
      name: 'Myself',
      largeName: 'Myself',
    },
    {
      value: false,
      name: 'A loved one',
      largeName: 'A loved one',
    },
  ];

  const onSelect = (newValue: string) => {
    updateQuoteData('isPurchaserBeneficiary', newValue, STEP_ID);
    utils.sendStepCompletedEvent(STEP_ID, newValue);
    stepForward();
  };

  // Choose a picker which can work with or without the county being known
  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>Is this for yourself or someone else?</QuestionText>
        <Picker {...props} baseOptions={baseOptions} onSelect={onSelect} />
      </QuestionWrapper>
      <Buttons hideContinue />
    </React.Fragment>
  );
};

PurchaseForStep.displayName = STEP_ID;

export default PurchaseForStep;
