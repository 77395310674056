// @flow
import styled, { css } from 'styled-components';

type widthToDivision = {
  width: number,
  division: number,
};

const GUTTER = 16;

export const columns = {
  'x-large': 9999999,
  large: 1220,
  medium: 960,
  small: 672,
  'x-small': 307,
};

const MAX_WIDTH_TO_DIVISIONS: widthToDivision[] = [
  { width: columns['x-large'], divisions: 12 },
  { width: columns['large'], divisions: 12 },
  { width: columns['medium'], divisions: 6 },
  { width: columns['small'], divisions: 6 },
  { width: columns['x-small'], divisions: 2 },
];

export const Grid = styled('div')`
  display: flex;
  flex-wrap: wrap;
  max-width: 1220px;
  margin: 0 auto;
  @media (max-width: ${columns.medium}px) {
    max-width: 725px;
  }
  @media (max-width: ${columns.small}px) {
    max-width: ${columns['x-small']}px;
  }
`;

const withMarginsDiv = styled('div')`
  flex-grow: 0;
  margin-left: ${GUTTER * 0.5}px;
  margin-right: ${GUTTER * 0.5}px;
`;

function percentageForW(w: number, divisions: number) {
  // can't have a w value greater than the number of divisions in this view!
  // Larger w values therefore just become 100% fills
  const trimmedW = Math.min(w, divisions);
  return (trimmedW / divisions) * 100;
}

export const GridBox = styled(withMarginsDiv)`
  ${({ w }) => {
    return MAX_WIDTH_TO_DIVISIONS.map(({ width, divisions }) => {
      const percentage = percentageForW(w, divisions);
      return css`
        @media (max-width: ${width}px) {
          flex-basis: calc(${percentage}% - ${GUTTER}px);
        }
      `;
    });
  }};

  //test for mobile width, send this to it's own function later
  @media (max-width: ${columns.small}px) {
    ${({ mw }) => {
      return MAX_WIDTH_TO_DIVISIONS.map(({ width, divisions }) => {
        const percentage = percentageForW(mw, divisions);
        return css`
          @media (max-width: ${width}px) {
            flex-basis: calc(${percentage}% - ${GUTTER}px);
          }
        `;
      });
    }};
  }
`;
