import React from 'react';

import FormDataProvider from './providers/FormDataProvider';
import NavigationProvider from './providers/NavigationProvider';
import Form from './Form';

import { sendStepBackEvent } from '/util/registerEvent';

// Any props which QuoteForm is assigned will be passed to FormDataProvider
// to be used as Quote Presets.
// eg: <QuoteForm purchaseType={PURCHASE_TYPE.SOON}/> will render a QuoteForm
// with purchase type pre-selected as soon.
// This means that some steps can be automatically skipped
//
// These values should still be passed to the eventManager so that quote flow
// can be properly measured even though the user will never see the values. The
// correct place to do so is in each step.

interface InitialProps {
  partnerCode: string;
  referrer: string;
  className: string;
}

const QuoteForm = (props: InitialProps) => {
  const { partnerCode, referrer } = props;
  return (
    <FormDataProvider {...props}>
      <NavigationProvider sendStepBackEvent={sendStepBackEvent}>
        <Form
          className={props.className}
          partnerCode={partnerCode}
          referrer={referrer}
        />
      </NavigationProvider>
    </FormDataProvider>
  );
};

export default QuoteForm;
