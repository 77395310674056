import React from 'react';
import styled from 'styled-components';
import { Text } from 'tulip-ui';

const Wrapper = styled.ul`
  list-style-type: none;
  padding: 0;
`;

interface OptionProps {
  active: boolean;
}

const Option = styled.li<OptionProps>`
  ${({ theme }) => theme.fonts.text.fontWeight.light};

  align-items: center;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.priceBoxBorder : theme.colors.neutralBorder};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  line-height: 1;
  margin-bottom: 8px;
  text-align: center;
  transition: background-color 0.15s ease, border-color 0.15s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueSectionBackground};
  }
`;

export interface OptionType {
  value: any;
  name: string;
  largeName?: string;
}

interface Props {
  activeOptionValue?: any;
  onSelect: (optionValue: any) => any;
  options: OptionType[];
  className?: string;
}

const MultipleChoice = ({
  activeOptionValue,
  onSelect,
  options,
  className,
}: Props) => (
  <Wrapper className={className}>
    {options.map(option => (
      <Option
        key={option.name}
        active={activeOptionValue === option.value}
        onClick={() => onSelect(option.value)}
      >
        <Text>{option.name}</Text>
      </Option>
    ))}
  </Wrapper>
);

export default MultipleChoice;
