'use strict';

var _imageAsset = require('./image-asset');

var ImageAsset = _interopRequireWildcard(_imageAsset);

var _image = require('./image');

var Image = _interopRequireWildcard(_image);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }