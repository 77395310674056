// @flow
const getDisplayName = Component =>
  Component.displayName ||
  Component.name ||
  (typeof Component === 'string' && Component.length > 0
    ? Component
    : 'Unknown');

const wrapDisplayName = (BaseComponent, hocName) =>
  `${hocName}(${getDisplayName(BaseComponent)})`;

export default wrapDisplayName;
