import React from 'react';

import { theme } from '/globalStyles';

import { ModalContext } from '/providers/ModalProvider';

function Modal() {
  return (
    <ModalContext.Consumer>
      {value => {
        const {
          isDisplaying,
          setIsDisplaying,
          content,
          shouldPreventDismiss,
        } = value;
        return (
          <div
            style={{
              display: isDisplaying ? 'flex' : 'none',
              ...styles.wrapper,
            }}
            className="Modal_component"
          >
            <div
              style={styles.overlay}
              onClick={() => {
                !shouldPreventDismiss && setIsDisplaying(false);
              }}
            />
            <div style={styles.modal}>{content}</div>
          </div>
        );
      }}
    </ModalContext.Consumer>
  );
}

const styles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.lightBlackTranslucent,
    opacity: 0.9,
    zIndex: 50,
  },
  modal: {
    zIndex: 99,
  },
};

export default Modal;
