// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  symbol: 'attention' | 'info',
  backgroundColor: string,
};

const glyphs = {
  attention: '!',
  info: 'i',
};

export default function Icon({ symbol, backgroundColor, ...rest }: Props) {
  return (
    <Wrapper backgroundColor={backgroundColor} {...rest}>
      <Span>{glyphs[symbol]}</Span>
    </Wrapper>
  );
}

const Wrapper = styled('div')`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: ${p => p.backgroundColor};
  text-align: center;
  user-select: none;
`;

const Span = styled('span')`
  color: #fff;
  font-size: 18px;
`;
