// @flow
import * as React from 'react';
import styled from 'styled-components';

type VMode = 'standalone' | 'joined';

type Props = {|
  /**
   * Any horizontal constraining to apply to the content
   *
   * - standard: Normal fairly wide page, useful if you have a page with
   *             multiple columns.
   * - prose: If the page is just text, it would look too wide in a standard
   *          container, so use this more narrow one instead.
   */
  h?: 'standard' | 'prose' | 'textSection',
  /**
   * By default the container will have some horizontal padding, so that
   * contents don't touch the side of the screen on mobile.  You can set this
   * to 0 or some other value if you know what you're doing.
   */
  hPad?: number,
  hMaxWidth?: number,
  /**
   * Any padding to apply to both the top and bottom of the container.  For
   * more granular control you can use the "top" and "bottom" props instead.
   *
   * - standalone: This section is self-contained on a page, and therefore
   *               should have a large amount of vertical spacing.
   * - joined: This section is related to what's next to it (e.g. just below
   *           a title), so should be quite close.
   */
  v?: VMode,
  top?: VMode,
  bottom?: VMode,
  vBreakpoint?: number,
  style?: Object,
  children: React.Node,
|};

/**
 * A general purpose container, for constraining and spacing content, while
 * keeping sizes consistent between pages, responsive for mobile devices, etc.
 */
const Container: React.ComponentType<Props> = styled('div').attrs({
  top: props => props.top || props.v || null,
  bottom: props => props.bottom || props.v || null,
})`
  display: block;
  ${(p: Props) =>
    p.h || p.hMaxWidth
      ? `
    margin-left: auto;
    margin-right: auto;
    // Keep box-sizing as content-box, so certain elements can reach the edges,
    // while still lining up.
    padding-left: ${p.hPad != null ? p.hPad : 28}px;
    padding-right: ${p.hPad != null ? p.hPad : 28}px;
  `
      : ''};
  ${(p: Props) => (p.hMaxWidth ? `max-width: ${p.hMaxWidth}px;` : '')};
  ${(p: Props) => (p.h === 'standard' ? `max-width: 1260px;` : '')};
  ${(p: Props) => (p.h === 'textSection' ? `max-width: 1140px;` : '')};
  ${(p: Props) => (p.h === 'prose' ? `max-width: 620px;` : '')};
  ${(p: Props) =>
    p.top === 'standalone'
      ? `
    margin-top: 100px;
    @media (max-width: ${p.vBreakpoint || 1100}px) {
      margin-top: 40px;
    }
  `
      : ''};
  ${(p: Props) =>
    p.bottom === 'standalone'
      ? `
    margin-bottom: 100px;
    @media (max-width: ${p.vBreakpoint || 1100}px) {
      margin-bottom: 60px;
    }
  `
      : ''};
  ${(p: Props) => (p.top === 'joined' ? `padding-top: 30px` : '')};
  ${(p: Props) => (p.bottom === 'joined' ? `padding-bottom: 30px` : '')};
`;

export default Container;
