import React from 'react';

import {
  QuoteFormDataContext,
  FormDataProps,
} from './providers/FormDataProvider';
import {
  FormNavigationContext,
  NavigationProps,
} from './providers/NavigationProvider';

import {
  sendStepStartedEvent,
  sendStepPresetSkippedEvent,
} from '/util/registerEvent';

export interface StepProps extends FormDataProps, NavigationProps {
  recordStepStarted: () => void;
  recordStepPresetSkipped: (value: { id: string; value: any }) => void;
}

export interface Step {
  (props: StepProps): React.ReactElement;
  displayName: string;
  titleText?: () => string;
  hideProgressBar?: boolean;
}

// Wraps a step in FormDataProps and NavProps
const StepWrapper = (props: { children: any }) => {
  if (Array.isArray(props.children)) {
    // Should not have more than one child!
    return null;
  }

  const Step = props.children;

  return (
    <QuoteFormDataContext.Consumer>
      {(formDataProps: FormDataProps) => (
        <FormNavigationContext.Consumer>
          {(navProps: NavigationProps) => (
            <Step
              {...formDataProps}
              {...navProps}
              recordStepStarted={() => {
                sendStepStartedEvent(Step.displayName);
              }}
              recordStepPresetSkipped={value => {
                sendStepPresetSkippedEvent(Step.displayName, value);
              }}
            />
          )}
        </FormNavigationContext.Consumer>
      )}
    </QuoteFormDataContext.Consumer>
  );
};

export default StepWrapper;
