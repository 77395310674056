// @flow
import * as React from 'react';
import { withTheme } from 'styled-components';

type Props = {
  theme: Object,
};

const LongArrow = (props: Props) => (
  <svg width="134" height="15" viewBox="0 0 134 15" {...props}>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.542299107"
    >
      <path
        id="Line-9"
        d="M132.166667,7.20833333 L120.166667,1.45833333 L120.166667,12.9583333 L132.166667,7.20833333 Z M1.5,7.95833333 L120.916667,7.95833333 L121.666667,7.95833333 L121.666667,6.45833333 L120.916667,6.45833333 L1.5,6.45833333 L0.75,6.45833333 L0.75,7.95833333 L1.5,7.95833333 Z"
        fill={props.theme.colors.arrowColor}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default withTheme(LongArrow);
