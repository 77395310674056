'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cleanPhoneNumber = exports.camelCaseToHumanReadable = exports.camelCaseToSentence = undefined;
exports.capitalize = capitalize;
exports.getReadableString = getReadableString;
exports.getCountyByNameAndState = getCountyByNameAndState;
exports.makeGuid = makeGuid;
exports.flattenObject = flattenObject;
exports.getIsInPersonPickup = getIsInPersonPickup;
exports.getDeathCertificatePriceForCounty = getDeathCertificatePriceForCounty;
exports.getDeathCertificatePriceForCountyName = getDeathCertificatePriceForCountyName;
exports.getNoninitialDeathCertificatePriceForCounty = getNoninitialDeathCertificatePriceForCounty;
exports.getNoninitialDeathCertificatePriceForCountyName = getNoninitialDeathCertificatePriceForCountyName;

var _constants = require('./constants');

function capitalize(input) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}
function getReadableString(string) {
  return capitalize(string).replace(/_/g, ' ');
}

var camelCaseToSentence = exports.camelCaseToSentence = function camelCaseToSentence(string) {
  return string.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2');
};

var camelCaseToHumanReadable = exports.camelCaseToHumanReadable = function camelCaseToHumanReadable(name) {
  var readableName = camelCaseToSentence(name);
  return readableName.charAt(0).toUpperCase() + readableName.slice(1);
};

function getCountyByNameAndState(name, state) {
  return _constants.COUNTIES.find(function (c) {
    return c.name === name && (!state || c.area.state.name === state);
  });
}

function makeGuid() {
  var length = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;

  var possible = 'abcdefghjkmnpqrstuvwxyz123456789';
  var text = '';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }return text;
}

function flattenObject(obj) {
  var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (obj === undefined || obj == null) {
    return;
  }
  switch (obj.constructor.name) {
    case 'Array':
      {
        obj.forEach(function (element) {
          // Just flatten out each element
          flattenObject(element, element);
        });
      }
    // eslint-disable-next-line no-fallthrough
    case 'Object':
      {
        Object.keys(obj).forEach(function (key) {
          var keyVal = obj[key];

          if (keyVal && keyVal.edges) {
            // pull the edges value up one level so { blah: edges: [node, node] }
            // becomes { blah : [ node, node ] }
            keyVal = keyVal.edges;
            // Then flatten out [{ node: foo }] to just [foo]
            keyVal = keyVal.map(function (_ref) {
              var node = _ref.node;
              return node;
            });
          }

          parent[key] = keyVal;
          flattenObject(keyVal, parent[key]);
        });
        return parent;
      }
  }
}

function getIsInPersonPickup(county) {
  if (county.inPersonPickup) {
    return true;
  }

  if (county.area.inPersonPickup) {
    return true;
  }
  return false;
}

function getDeathCertificatePriceForCounty(county) {
  if (county.deathCertPrice) {
    return county.deathCertPrice;
  }

  if (county.area.deathCertPrice) {
    return county.area.deathCertPrice;
  }

  return _constants.PRICE_LIST.defaultDeathCert;
}

function getDeathCertificatePriceForCountyName(countyName, state) {
  var county = getCountyByNameAndState(countyName, state);

  if (!county) {
    return _constants.PRICE_LIST.defaultDeathCert;
  }

  return getDeathCertificatePriceForCounty(county);
}

var cleanPhoneNumber = exports.cleanPhoneNumber = function cleanPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/[^\d]/g, '');
};

function getNoninitialDeathCertificatePriceForCounty(county) {
  if (county.noninitialDeathCertPrice) {
    return county.noninitialDeathCertPrice;
  }

  if (county.area.noninitialDeathCertPrice) {
    return county.area.noninitialDeathCertPrice;
  }

  return null;
}

function getNoninitialDeathCertificatePriceForCountyName(countyName, state) {
  var county = getCountyByNameAndState(countyName, state);

  if (!county) {
    return null;
  }

  return getNoninitialDeathCertificatePriceForCounty(county);
}