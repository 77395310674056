import { validation } from '/util';
import _ from 'lodash/fp';

import React, { useState, useEffect } from 'react';

import { StepProps } from '../StepWrapper';
import Input from '../Input';
import { SimpleKV } from '../types';
import Buttons from '../Buttons';
import { QuestionText, QuestionWrapper } from '../styled';

const STEP_ID = 'PurchaserName';

const PurchaserName = (props: StepProps) => {
  const {
    updateQuoteData,
    bulkUpdateQuoteData,
    recordStepStarted,
    quoteData,
  } = props;

  const initialFieldsState: SimpleKV = {};
  const [fields, setFields] = useState(initialFieldsState);

  const updateFieldValue = (id: string, value: string) => {
    setFields({ ...fields, [id]: value });
  };

  useEffect(recordStepStarted, []);

  const inputs = [
    {
      id: 'purchaserFirstName',
      label: 'First name',
      isValidCheck: validation.isName,
    },
    {
      id: 'purchaserLastName',
      label: 'Last name',
      isValidCheck: validation.isName,
    },
  ];

  const mainContent = inputs.map(({ id, label, isValidCheck }) => {
    const valueFromQuoteForm = _.getOr(undefined, id)(quoteData);
    const inputValue = fields[id];
    return (
      <Input
        key={id}
        id={id}
        value={inputValue === undefined ? valueFromQuoteForm : inputValue}
        label={label}
        onBlur={() => {
          const isPurchaserBeneficiary = _.getOr(false, [
            'quoteData',
            'isPurchaserBeneficiary',
          ])(props);

          if (!isPurchaserBeneficiary) {
            updateQuoteData(id, fields[id], STEP_ID);
          } else {
            // since preneed insurance flow asks for isPurchaserBeneficiary in an
            // earlier step, we should update beneficiary name here
            if (id === 'purchaserFirstName') {
              bulkUpdateQuoteData(
                {
                  beneficiaryFirstName: fields[id],
                  purchaserFirstName: fields[id],
                },
                STEP_ID
              );
            }
            if (id === 'purchaserLastName') {
              bulkUpdateQuoteData(
                {
                  beneficiaryLastName: fields[id],
                  purchaserLastName: fields[id],
                  purchaserRelationship: 'Recipient',
                },
                STEP_ID
              );
            }
          }
        }}
        isValidCheck={isValidCheck}
        onChange={updateFieldValue}
      />
    );
  });

  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>What’s your name?</QuestionText>
        {mainContent}
      </QuestionWrapper>
      <Buttons />
    </React.Fragment>
  );
};

PurchaserName.displayName = STEP_ID;

export default PurchaserName;
