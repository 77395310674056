import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash/fp';
import styled from 'styled-components';

import getPurchaseOptionsByPlaceId from '../../api/getPurchaseOptionsByPlaceId';

import { QuestionText } from './styled';

class PreneedHeader extends React.Component {
  static propTypes = {
    placeId: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isImmediateAvailable: false,
      countyName: null,
    };
  }

  componentDidMount() {
    const { placeId } = this.props;
    return getPurchaseOptionsByPlaceId(placeId).then(response => {
      const isImmediateAvailable = _.getOr(false, [
        'data',
        'geography',
        'county',
        'immediately',
        'isAvailable',
      ])(response);

      const countyName = _.getOr(null, ['data', 'geography', 'county', 'name'])(
        response
      );

      this.setState({ isImmediateAvailable, countyName });
      return {
        isImmediateAvailable,
        countyName,
      };
    });
  }

  render() {
    const { isImmediateAvailable, countyName } = this.state;

    // List of counties needing custom message.
    // NOTE: UPDATE DISABLED MESSAGE BELOW BEFORE ADDING COUNTIES TO LIST.
    const countyList = [
      // 'Ventura',
      'San Diego',
      'San Bernadino',
      'Riverside',
      // 'Los Angeles',
      // 'Orange',
    ];

    const disabled = countyList.includes(countyName);

    return (
      <QuestionText className={this.props.className}>
        {isImmediateAvailable ? (
          <div>
            {disabled ? (
              <ServiceAreaQuote>
                Thank you for your interest in Tulip. Unfortunately, due to the
                ongoing health emergency in Southern California and our
                commitment to providing the highest level of care, we are unable
                to accept any new customers in your area until Friday, March
                19th.
              </ServiceAreaQuote>
            ) : (
              <div>
                <ServiceAreaQuote>
                  We currently offer immediate or near-term services (in the
                  next six months) in your area.
                </ServiceAreaQuote>
                <div>
                  If you are planning for the future, please check back soon.
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {disabled ? (
              <div>
                Thank you for your interest in Tulip. Unfortunately, due to the
                ongoing health emergency in Southern California and our
                commitment to providing the highest level of care, we are unable
                to accept any new customers in your area until Friday, March
                19th.
              </div>
            ) : (
              <div>We are not yet in your area. Please check back soon.</div>
            )}
          </div>
        )}
      </QuestionText>
    );
  }
}

const ServiceAreaQuote = styled('div')`
  padding-bottom: 10px;
`;

export default PreneedHeader;
