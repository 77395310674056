// @flow
import * as React from 'react';
import styled from 'styled-components';

type radioButtonProps = {
  onClick?: () => mixed,
  selected: boolean,
};

class RadioButton extends React.Component<radioButtonProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <RadioOutline {...this.props}>
        <RadioBlob {...this.props} />
      </RadioOutline>
    );
  }
}

const RadioBlob = styled('div')`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${props =>
    props.selected ? props.theme.baseBlue : 'transparent'};
  width: 8px;
  height: 8px;
`;

const RadioOutline = styled('div')`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px ${props => props.theme.grey} solid;
  background-color: white;
  width: 16px;
  height: 16px;
  margin-right: 16px;
`;

export default RadioButton;
