// @flow
import wrapDisplayName from 'util/wrapDisplayName';

import * as React from 'react';

const withContext = (Context, mapValueToProps) => ComposedComponent => {
  const WithContext = props => (
    <Context.Consumer>
      {value => {
        const contextProps = value ? mapValueToProps(value) : {};
        return <ComposedComponent {...props} {...contextProps} />;
      }}
    </Context.Consumer>
  );

  WithContext.displayName = wrapDisplayName(ComposedComponent, 'withContext');

  WithContext.getInitialProps = ctx =>
    ComposedComponent.getInitialProps
      ? ComposedComponent.getInitialProps(ctx)
      : {};

  return WithContext;
};

export default withContext;
