// @flow
import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../components';
import { BREAKPOINTS } from '../../globalStyles';

type Props = {
  title?: String,
  items?: Array,
  className?: String,
  gtmTag?: String,
};

export default function InlineList({ title, items, className, gtmTag }: Props) {
  const StyledHeading = styled(Heading)`
    font-size: 23px;
    line-height: 1.4;
    margin-bottom: 48px;
    margin-left: 20px;

    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      margin-bottom: 36px;
    }
  `;

  const List = styled.ul`
    list-style-type: none;
    padding: 0;
  `;

  const ListItem = styled.li`
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.faintBlue};
  `;

  const StyledLink = styled.a`
    width: 100%;
    font-size: 16px;
    line-height: 1.625;
    color: ${({ theme }) => theme.grayBlue};
    text-decoration: none;
  `;

  const Arrow = styled.img`
    margin-left: 12px;
  `;

  return items.length ? (
    <div className={className}>
      <StyledHeading>{title}</StyledHeading>
      <List>
        {items.map((item, i) => (
          <StyledLink
            href={item.url}
            key={i}
            target="_blank"
            onClick={() => window.dataLayer.push({ event: gtmTag })}
          >
            <ListItem>
              {item.label}
              <Arrow src="/img/right-arrow.svg" />
            </ListItem>
          </StyledLink>
        ))}
      </List>
    </div>
  ) : null;
}
