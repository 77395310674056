'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.patchUrl = exports.fromQuoteUrl = exports.cookieClearUrl = exports.cookieAssumeUrl = undefined;

var _models = require('../models');

var cookieAssumeUrl = exports.cookieAssumeUrl = function cookieAssumeUrl(id) {
  return '/cases/assume/' + id;
};
var cookieClearUrl = exports.cookieClearUrl = function cookieClearUrl() {
  return '/cases/clear';
};
var fromQuoteUrl = exports.fromQuoteUrl = function fromQuoteUrl() {
  return '/cases/from-quote';
};
var patchUrl = exports.patchUrl = function patchUrl(id) {
  return '/cases/' + id;
};