// @flow
import * as React from 'react';
import styled from 'styled-components';

export type Props = {
  visible: boolean,
  onDismiss?: () => void,
  vBreakpoint?: number,
  children: React.Node,
  maxWidth: number,
};

const H_BREAKPOINT = 600;

export default function Modal(props: Props) {
  const { visible, onDismiss, vBreakpoint = 600, maxWidth, children } = props;
  if (!visible) {
    return null;
  }

  const bodyHackCss = 'body { overflow: hidden; }';

  return (
    <Mask onClick={onDismiss}>
      <style maxWidth={maxWidth} type="text/css">
        {bodyHackCss}
      </style>
      <Wrapper
        maxWidth={maxWidth}
        onClick={e => e.stopPropagation()}
        vBreakpoint={vBreakpoint}
      >
        <div style={{ textAlign: 'right' }}>
          <Close onClick={onDismiss} vBreakpoint={vBreakpoint}>
            ✕
          </Close>
        </div>
        {children}
      </Wrapper>
    </Mask>
  );
}

const Mask = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(107, 113, 125, 0.8);
  padding: 50px;
`;

const Wrapper = styled('div')`
  background: #fff;
  box-sizing: border-box;
  max-width: ${({ maxWidth = 840 }) => maxWidth}px;
  margin: 50px auto;
  border-radius: 10px;
  padding: 15px 30px 25px;
  display: flex;
  flex-direction: column;
  justify-content: justify-content;

  @media (max-width: ${H_BREAKPOINT}px), (max-height: ${p => p.vBreakpoint}px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border-radius: 0;
    max-width: none;
    overflow-y: auto;
    padding: 15px 15px 25px;
  }
`;

const Close = styled('a')`
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;

  @media (max-width: ${H_BREAKPOINT}px), (max-height: ${p => p.vBreakpoint}px) {
    display: block;
    text-align: right;
    margin-bottom: 10px;
  }
`;
