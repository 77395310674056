// @flow
import * as React from 'react';
import withContext from 'hoc/withContext';

import { PHONE_FORMATTED, PHONE_NORMAL } from '../config'; //default numbers
import { CallTrackingContext } from '../context';

type Number = {
  formatted: string,
  normal: string,
};

type OwnProps = {
  render?: (number: Number & { href: string }) => React.Node,
  label?: (number: string) => string,
  className?: string,
  style?: Object,
  phoneNumber?: Number,
};

type Props = OwnProps & {
  googleNumber: null | Number,
};

/**
 * Show the main company phone number.  Will either be the default, or may be
 * swapped out for a forwarding number by AdWords.
 *
 * Can be used as an <a> rendering component directly, or wrap a more complex
 * component by setting the `render` prop with a function.
 */
class PhoneNumber extends React.PureComponent<Props> {
  render() {
    const {
      render,
      label = x => x,
      googleNumber,
      className,
      style,
      phoneNumber,
    } = this.props;
    const number = phoneNumber ||
      googleNumber || {
        formatted: PHONE_FORMATTED,
        normal: PHONE_NORMAL,
      };

    const href = `tel:${number.normal}`;

    if (render) {
      return render({ ...number, href });
    }

    return (
      <a href={href} style={style} className={className}>
        {label(number.formatted)}
      </a>
    );
  }
}

const connector = withContext(CallTrackingContext, ({ state }) => ({
  googleNumber: state,
}));

export default connector(PhoneNumber);
