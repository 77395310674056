import React, { useEffect } from 'react';
import { StepProps } from '../StepWrapper';
import { PURCHASE_TYPE, PURCHASER_RELATIONSHIP } from 'tulip-core';
import { QuestionText, QuestionWrapper, StepSpinner } from '../styled';
import MultipleChoice from '../MultipleChoice';
import * as utils from '/util/registerEvent';
import Buttons from '../Buttons';

interface Option {
  value: any;
  name: string;
}
const STEP_ID = 'PurchaserRelationship';
const PurchaserRelationship = (props: StepProps) => {
  const {
    skip,
    presets,
    recordStepStarted,
    recordStepPresetSkipped,
    updateQuoteData,
    stepForward,
  } = props;
  const {
    purchaseType,
    isPurchaserBeneficiary,
    purchaserRelationship,
  } = props.quoteData;
  if (
    purchaseType !== PURCHASE_TYPE.PRENEED_INSURANCE ||
    isPurchaserBeneficiary
  ) {
    skip(true);
    return <StepSpinner />;
  }
  useEffect(() => {
    if (
      !presets.isPurchaserBeneficiary &&
      purchaseType === PURCHASE_TYPE.PRENEED_INSURANCE
    ) {
      recordStepStarted();
    } else if (presets.isPurchaserBeneficiary) {
      // Set pre-recorded step
      recordStepPresetSkipped({
        id: 'isPurchaserBeneficiary',
        value: presets.isPurchaserBeneficiary,
      });
    }
  }, []);
  const options: Option[] = [
    {
      value: PURCHASER_RELATIONSHIP.ATTORNEY,
      name: 'Power of Attorney',
    },
    {
      value: PURCHASER_RELATIONSHIP.SPOUSE,
      name: 'Spouse or Registered Domestic Partner',
    },
    {
      value: PURCHASER_RELATIONSHIP.SIBLING_CHILD,
      name: 'Adult Sibling or Child',
    },
    {
      value: PURCHASER_RELATIONSHIP.PARENT,
      name: 'Parent',
    },
    {
      value: PURCHASER_RELATIONSHIP.NIECE_NEPHEW_AUNT_UNCLE,
      name: 'Niece/Nephew/Aunt/Uncle',
    },
    {
      value: PURCHASER_RELATIONSHIP.NO_RELATIONSHIP,
      name: 'None of the above',
    },
  ];
  const onSelect = (newValue: string) => {
    updateQuoteData('purchaserRelationship', newValue, STEP_ID);
    utils.sendStepCompletedEvent(STEP_ID, newValue);
    stepForward();
  };
  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>
          What is your relationship to your loved one?
        </QuestionText>
        <MultipleChoice
          activeOptionValue={purchaserRelationship}
          options={options}
          onSelect={onSelect}
        />
      </QuestionWrapper>
      <Buttons hideContinue={purchaserRelationship === null} />
    </React.Fragment>
  );
};

PurchaserRelationship.displayName = STEP_ID;

export default PurchaserRelationship;
