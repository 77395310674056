// @flow
export const isRequired = value =>
  typeof value !== 'undefined' && value !== null && value.length !== 0;

export const isEmpty = value =>
  typeof value === 'undefined' || value === null || value.trim().length === 0;

export const isEmail = value =>
  /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.trim());

/*eslint-disable */
//suppress all warnings between comments
export const isEmailOrNull = value =>
  /(^N\/A)$|^([\w\.\-]+)@([\w\-]+)((\.(\w){2,})+)$/i.test(value.trim());
/*eslint-disable */

export const isPhoneNumber = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value.trim());

export const isName = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  /^(?=.{1,50}$)[′’a-zÀ-ž-]+(?:['_.\s][′’a-zÀ-ž-]+)*$/i.test(value.trim());

export const isSsn = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/i.test(value.trim());

export const isZip = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  /^[0-9]{5}(?:-[0-9]{4})?$/i.test(value.trim());

export const isTime = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i.test(value.trim());

const validator = (value, validation) => {
  const isValidationArray = Array.isArray(validation);
  if (!isValidationArray) {
    const { validate = () => {}, error } = validation;
    if (!validate(value)) {
      return error;
    }
  } else {
    return validation.reduce((acc, currentValue) => {
      const { validate = () => {}, error } = currentValue;
      if (!validate(value)) {
        return acc ? acc : error;
      }
    }, undefined);
  }
};

export default validator;
