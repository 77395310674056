// @flow
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const ReadMoreButton = styled.span`
  color: ${({ theme }) => theme.textSpecial};
  text-decoration: underline;
  cursor: pointer;
`;

type Props = {
  text: string,
  length: number,
};

type State = {
  showDetails: boolean,
};

class CollapsibleText extends React.PureComponent<Props, State> {
  state = {
    showDetails: false,
  };

  handleShowDetails = () => this.setState({ showDetails: true });

  render() {
    const { text, length } = this.props;
    const { showDetails } = this.state;

    const t =
      text.length > length
        ? showDetails
          ? text
          : `${text.substring(0, length)}...`
        : text;

    return (
      <Wrapper>
        <span>{t}</span>
        {text.length > length && !showDetails && (
          <ReadMoreButton onClick={this.handleShowDetails}>
            Read More
          </ReadMoreButton>
        )}
      </Wrapper>
    );
  }
}

export default CollapsibleText;
