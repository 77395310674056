import React from 'react';
import { useState, useEffect } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { CustError } from '/types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { theme } from '../../globalStyles';

import { paperworkMaterialTheme } from '../../materialStyles';
import moment from 'moment';

interface DateInputProps {
  label?: string;
  value: any;
  onChange?: (date: any) => void;
  onBlur?: (date: any) => void;
  error?: CustError;
  inputValue?: string;
  style?: object;
  fullWidth?: boolean;
}

const styles = {
  label: {
    fontFamily: 'Brown',
    fontSize: 16,
    color: theme.darkGrey,
    fontWeight: 200,
    marginBottom: '-14px',
  },
  error: {
    color: theme.red,
    fontFamily: 'Brown',
    fontWeight: 100,
    fontSize: 14,
    marginTop: '-7px',
  },
};

const dateFormat = 'YYYY-MM-DD';

export default function DateInput({
  label = 'Birth Date',
  value = null,
  onChange = () => {},
  onBlur = () => {},
  error,
  style = {},
}: DateInputProps) {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const hasError = !!error && value;

  function handleDateChange(date) {
    // Setting this so that value captures the saved date when the user
    // options the popup calendar and clicks "CANCEL" or "OK".
    // We don't want the validation error to show while the user is filling
    // out the date input for the first time, so this will save only valid inputs
    if (date && date._isValid) {
      setSelectedDate(date);
      onChange(date);
    }
  }

  function handleBlur(e) {
    onBlur(e);
    const date = e.currentTarget.value;
    const formattedDate = date ? moment(date).format(dateFormat) : null;
    setSelectedDate(formattedDate);
  }

  return (
    <div>
      {label && (
        <label
          style={
            hasError ? { ...styles.label, color: theme.red } : styles.label
          }
        >
          {label}
        </label>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={paperworkMaterialTheme}>
          <KeyboardDatePicker
            // margin="normal"
            fullWidth
            style={style}
            id="date-picker-dialog"
            format="MM/DD/YYYY"
            value={selectedDate}
            onChange={handleDateChange}
            onBlur={handleBlur}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            emptyLabel="MM/DD/YYYY"
            invalidDateMessage={null}
            minDateMessage={null}
            maxDateMessage={null}
            error={hasError}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {error && <span style={styles.error}>{error.default}</span>}
    </div>
  );
}
