// @flow
import React from 'react';

type Props = {
  color?: string,
};

const Star = ({ color = '#FFD72E' }: Props) => (
  <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1">
    <defs />
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Reviews-v2-(No-Photos)"
        transform="translate(-599.000000, -1969.000000)"
        fill={color}
      >
        <g id="Group-2" transform="translate(311.000000, 1959.000000)">
          <g id="Stars" transform="translate(212.000000, 9.000000)">
            <g id="Star" transform="translate(75.000000, 0.000000)">
              <path d="M10.0701503,15.891689 L5.5427056,18.2383808 C5.05237372,18.4925325 4.44885053,18.3010706 4.19469882,17.8107388 C4.09532728,17.6190224 4.06068352,17.4002903 4.09594786,17.1872498 L4.92872705,12.1562263 L1.29783314,8.57553815 C0.904599616,8.18774202 0.90019174,7.55459238 1.28798787,7.16135886 C1.43961344,7.00760731 1.63693453,6.90706719 1.85044542,6.87477242 L6.89257599,6.11212076 L9.17600486,1.55244195 C9.42330506,1.05861905 10.024104,0.858772911 10.5179269,1.10607311 C10.7110082,1.20276575 10.8676031,1.35936066 10.9642958,1.55244195 L13.2477247,6.11212076 L18.2898552,6.87477242 C18.8359287,6.95736922 19.2116507,7.46700715 19.1290539,8.0130806 C19.0967592,8.22659149 18.9962191,8.42391258 18.8424675,8.57553815 L15.2115736,12.1562263 L16.0443528,17.1872498 C16.1345445,17.7321203 15.7659546,18.24694 15.221084,18.3371317 C15.0080435,18.3723961 14.7893114,18.3377523 14.597595,18.2383808 L10.0701503,15.891689 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Star;
