// @flow
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

type Props = {
  label?: string,
  textStyle?: string,
  name?: string,
  id?: string,
  placeholder?: string,
  options?: array,
  value?: string,
  changeHandler?: Function,
  blurHandler?: Function,
  border?: string,
  background?: string,
};

export default function SelectInput({
  label,
  textStyle,
  name,
  id,
  options,
  placeholder,
  value,
  changeHandler,
  blurHandler,
  border,
  background,
}: Props) {
  const selectStyles = {
    menu: provided => ({
      ...provided,
      margin: 0,
    }),
    option: provided => ({
      ...provided,
      textAlign: 'left',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#BDBDBD',
      margin: 0,
      fontSize: textStyle === 'lg' ? 16 : 12,
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: 16,
      margin: 0,
      color: '#687491',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#747474',
      '&:hover': {
        color: '#747474',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 16,
      paddingRight: 16,
    }),
    separator: provided => ({
      ...provided,
      display: 'none',
    }),
    control: provided => ({
      ...provided,
      border: border || '1px solid #d5d7db',
      backgroundColor: background || '#fff',
    }),
  };

  const SelectWrapper = styled('div')`
    width: 100%;
    box-sizing: border-box;
  `;

  const Label = styled('label')`
    margin-bottom: 4px;
    margin-left: 12px;
    font-size: ${textStyle === 'lg' ? '14px' : '12px'};
    display: block;
  `;

  return (
    <SelectWrapper>
      <Label htmlFor={id}>{label}</Label>
      <Select
        name={name}
        id={id}
        options={options}
        styles={selectStyles}
        placeholder={placeholder}
        value={value}
        onChange={e => changeHandler(e)}
        onBlur={blurHandler}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </SelectWrapper>
  );
}
