// @flow
import React from 'react';
import styled from 'styled-components';
import { Bullet } from '../../components';

type Props = {
  items?: Array,
};

export default function InlineList({ items }: Props) {
  const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0;
  `;

  const ListItem = styled.li`
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.grayBlue};
    display: flex;
    align-items: center;
  `;

  const StyledBullet = styled(Bullet)`
    margin: 0 8px;
  `;

  return items.length ? (
    <List>
      {items.map((item, i) => (
        <ListItem key={i}>
          {item} {i < items.length - 1 ? <StyledBullet /> : null}
        </ListItem>
      ))}
    </List>
  ) : null;
}
