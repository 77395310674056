// @flow
import * as React from 'react';
import { withTheme } from 'styled-components';
import { withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';

type Coord = {
  lat: number,
  lng: number,
};

type Props = {
  zoom: number,
  center: Coord,
  polygons: Array<Coord>,
  theme: any,
};

const CoverageMap = ({ zoom, center, polygons, theme }: Props) => (
  <GoogleMap
    defaultZoom={zoom}
    defaultCenter={center}
    defaultOptions={{
      disableDefaultUI: true,
      fullscreenControl: false,
      scrollwheel: false,
      streetViewControl: false,
      zoomControl: true,

      // Start styling to make the logo white.
      // https://mapstyle.withgoogle.com/
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels.text.stroke',
          stylers: [],
        },
      ],
    }}
  >
    {polygons.map((polygon, index) => (
      <Polygon
        key={index}
        paths={polygon}
        options={{
          strokeColor: theme.textSpecial,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: theme.textSpecial,
          fillOpacity: 0.25,
        }}
      />
    ))}
  </GoogleMap>
);

export default withGoogleMap(withTheme(CoverageMap));
