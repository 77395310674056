import React from 'react';
import styled from 'styled-components';

import { StepProps } from '../StepWrapper';
import { PURCHASE_TYPE, PURCHASER_RELATIONSHIP } from 'tulip-core';
import { QuestionWrapper, StepSpinner } from '../styled';
import Buttons from '../Buttons';
const STEP_ID = 'InsuranceUnavailable';
const InsuranceUnavailable = (props: StepProps) => {
  const { skip } = props;
  const {
    purchaserRelationship,
    isPurchaserBeneficiary,
    purchaseType,
  } = props.quoteData;
  if (
    isPurchaserBeneficiary ||
    purchaseType !== PURCHASE_TYPE.PRENEED_INSURANCE ||
    (purchaserRelationship &&
      purchaserRelationship !== PURCHASER_RELATIONSHIP.NO_RELATIONSHIP)
  ) {
    skip(true);
    return <StepSpinner />;
  }
  return (
    <React.Fragment>
      <QuestionWrapper>
        <UnavailableText>
          Due to insurance regulations, we’re unfortunately unable to make
          arrangements on behalf of this type of relationship.
        </UnavailableText>
      </QuestionWrapper>
      <Buttons hideContinue />
    </React.Fragment>
  );
};

const UnavailableText = styled('div')`
  padding-top: 10px;
`;
InsuranceUnavailable.displayName = STEP_ID;
export default InsuranceUnavailable;
