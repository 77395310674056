import React, { useState } from 'react';
import { QuestionText, DateContainer, FooterText } from '../styled';
import DateInput from 'components/DateInput';
const STEP_ID = 'Date';
import Buttons from '../Buttons';
import { StepProps } from 'components/QuoteForm/StepWrapper';
import { PURCHASE_TYPE } from 'tulip-core';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const Date = (props: StepProps) => {
  const { skip, updateQuoteData, stepForward, quoteData } = props;
  const { purchaseType, dateOfBirth, isPurchaserBeneficiary } = quoteData;
  const [selectedDob, setSelectedDob] = useState('');
  if (
    purchaseType !== PURCHASE_TYPE.PRENEED &&
    purchaseType !== PURCHASE_TYPE.PRENEED_INSURANCE
  ) {
    skip();
  }
  const isValidDate = () => {
    if (!dateOfBirth) return false;
    const dob = dateOfBirth.split('-');
    const year = dob[0];
    // continue to hide "continue" button if user has not completed typing in years. "0199"
    return parseInt(year[0]) > 0;
  };

  const questionText = isPurchaserBeneficiary
    ? 'What is your Date of Birth?'
    : "What is your loved one's Date of Birth?";

  return (
    <React.Fragment>
      <QuestionText>{questionText}</QuestionText>
      <DateContainer>
        <DateInput
          label={''}
          value={selectedDob || dateOfBirth}
          onBlur={() => {
            if (isValidDate()) {
              stepForward();
            }
          }}
          onChange={date => {
            const formattedDate = moment(date).format(dateFormat);

            updateQuoteData('dateOfBirth', formattedDate, STEP_ID);
            setSelectedDob(formattedDate);
          }}
        />
      </DateContainer>
      <div style={{ paddingTop: 10 }}>
        <FooterText>
          We require a date of birth to provide you with our lowest price
        </FooterText>
      </div>
      <Buttons hideContinue={!isValidDate()} />
    </React.Fragment>
  );
};

Date.displayName = STEP_ID;
export default Date;
