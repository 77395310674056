import React from 'react';

import {
  ContinueButton,
  BackButton,
  UnderlinedButtonLabel,
  ButtonsWrapper,
} from './styled';
import { FormNavigationContext } from './providers/NavigationProvider';

export interface ButtonProps {
  continueText?: string;
  continueAction: () => void;
  backText: string | undefined;
  continueDisabled: boolean | undefined;
  hideContinue: boolean | undefined;
  hideBack: boolean | undefined;
  stepForward: () => void;
  stepBack: () => void;
  currentStep: number;
  noPathBack: boolean;
}

interface TextButtonProps {
  action: () => void;
  text?: string;
  disabled?: boolean;
}

const Buttons = (props: ButtonProps) => {
  const {
    continueText,
    continueAction,
    backText,
    hideContinue,
    hideBack,
    stepForward,
    stepBack,
    currentStep,
    continueDisabled,
    noPathBack,
  } = props;

  let shouldHideBackButton = hideBack;

  // if no special rules for hiding back are declared, just use the default
  // which is hiding on the first step
  if (hideBack === undefined) {
    shouldHideBackButton = currentStep === 0 || noPathBack;
  }

  return (
    <ButtonsWrapper>
      {!hideContinue && (
        <Continue
          text={continueText}
          action={continueAction || stepForward}
          disabled={continueDisabled}
        />
      )}
      {!shouldHideBackButton && <Back text={backText} action={stepBack} />}
    </ButtonsWrapper>
  );
};

const Back = ({ text, action, disabled }: TextButtonProps) => {
  return (
    <BackButton
      className={'tulicon-icon_arrow-forward'}
      onClick={action}
      disabled={disabled}
    >
      <UnderlinedButtonLabel>{text || 'Back'}</UnderlinedButtonLabel>
    </BackButton>
  );
};

const Continue = ({ text, action, disabled }: TextButtonProps) => {
  return (
    <ContinueButton disabled={disabled} onClick={action}>
      {text || 'Continue'}
    </ContinueButton>
  );
};

const ButtonsWithNavigationContext = props => {
  return (
    <FormNavigationContext.Consumer>
      {contextProps => <Buttons {...props} {...contextProps} />}
    </FormNavigationContext.Consumer>
  );
};

export default ButtonsWithNavigationContext;
