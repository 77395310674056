import SentryManager from '/util/SentryManager';

import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { PURCHASE_TYPE } from 'tulip-core';

import Buttons from '../Buttons';
import { StepProps } from '../StepWrapper';
import LocationAutoComplete from '../../LocationAutoComplete';
import initApollo from '../../../apollo-wrapper/init-apollo';

import * as utils from '/util/registerEvent';

import {
  QuestionText,
  QuestionWrapper,
  LocationCompleteContainer,
  StepSpinner,
} from '../styled';

const STEP_ID = 'LocationAutoComplete';

export let query = gql`
  query Location(
    $placeId: String
    $countyId: ID
    $option: PurchaseOption
    $type: PurchaseType
  ) {
    geography {
      county(googlePlaceId: $placeId, id: $countyId) {
        purchaseOption(option: $option, purchaseType: $type) {
          isAvailable
        }
        name
        id
        did
        region {
          state {
            name
          }
        }
      }
    }
  }
`;

export const serviceOptionsToEnum = {
  [PURCHASE_TYPE.SOON]: 'PACKAGE',
  [PURCHASE_TYPE.IMMEDIATELY]: 'PACKAGE',
  [PURCHASE_TYPE.PRENEED]: 'PRE_NEED',
  [PURCHASE_TYPE.PRENEED_INSURANCE]: 'PRE_NEED_INSURANCE',
};

async function locationSelected(
  countyLookup: any,
  bulkUpdateQuoteData: (bulk: { [key: string]: any }, source: string) => void,
  purchaseType: string
) {
  const client = initApollo();
  const option = serviceOptionsToEnum[purchaseType];
  const type = purchaseType;
  const result = await client.query({
    query: query,
    variables: { ...countyLookup, option, type },
  });
  const { geography } = result.data;
  const { county } = geography;

  try {
    let countyName = county && county.name;
    let serviceAvailable = county ? county.purchaseOption.isAvailable : false;
    let countyRef = county ? county.did : null;
    let stateName = county && county.region.state.name;

    const newValues = {
      county: countyName,
      countyRef: countyRef,
      state: stateName,
      serviceAvailable,
    };

    bulkUpdateQuoteData(newValues, STEP_ID);
    utils.sendStepCompletedEvent(STEP_ID, newValues);
  } catch (e) {
    SentryManager.captureException(e);
  }
}

function getQuestionTextForPurchaseType(
  purchaseType: PURCHASE_TYPE,
  isPurchaserBeneficiary?: boolean
) {
  switch (purchaseType) {
    case PURCHASE_TYPE.PRENEED:
    case PURCHASE_TYPE.PRENEED_INSURANCE:
    case PURCHASE_TYPE.SOON:
      if (isPurchaserBeneficiary) {
        return 'Which city do you live in?';
      } else {
        return 'Which city is your loved one in?';
      }
    case PURCHASE_TYPE.IMMEDIATELY:
      return 'In what city did your loved one pass?';
  }
}

export function getAddress(
  county: string | undefined,
  state: string | undefined
) {
  if (county && state) {
    return `${county}, ${state}`;
  }
}

const Location = (props: StepProps) => {
  const [isFetching, updateIsFetching] = useState(false);

  const {
    bulkUpdateQuoteData,
    stepForward,
    presets,
    skip,
    recordStepStarted,
    recordStepPresetSkipped,
  } = props;
  const {
    county,
    state,
    purchaseType,
    isPurchaserBeneficiary,
  } = props.quoteData;

  const question = getQuestionTextForPurchaseType(
    purchaseType,
    isPurchaserBeneficiary
  );
  const address = getAddress(county, state);

  if (!presets.county && presets.countyId) {
    if (!isFetching) {
      updateIsFetching(true);
      locationSelected(
        { countyId: presets.countyId },
        bulkUpdateQuoteData,
        purchaseType
      ).then(() => {
        updateIsFetching(false);
        skip(true);
      });
    }
    return <StepSpinner />;
  }

  // the presets check is only for county because in glorious GraphQL + Hades
  // future county_ref will be removed
  if (presets.county) {
    skip(true);
    return <StepSpinner />;
  }

  useEffect(() => {
    if (!presets.county) {
      recordStepStarted();
    } else {
      // Set pre-recorded step
      recordStepPresetSkipped({ id: 'county', value: presets.county });
    }
  }, []);

  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>{question}</QuestionText>
        <LocationCompleteContainer>
          <LocationAutoComplete
            placeholder={'e.g.San Francisco'}
            purchaseType={purchaseType}
            address={address}
            placeSelected={async placeId => {
              utils.sendDataEnteredEvent(STEP_ID, placeId);
              await locationSelected(
                { placeId: placeId },
                bulkUpdateQuoteData,
                purchaseType
              );
              stepForward();
            }}
          />
        </LocationCompleteContainer>
      </QuestionWrapper>
      <Buttons hideContinue={county === undefined} />
    </React.Fragment>
  );
};

Location.displayName = STEP_ID;

export default Location;
