import React from 'react';
import styled from 'styled-components';
import { mediaQueries, Button, H3, Text } from 'tulip-ui';
import FieldInput from '/formik/FieldInput';

import { columns } from '../../components/Grid';

import SimpleSpinner from '/components/SimpleSpinner';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
`;

export const StepSpinner: React.SFC = () => (
  <SpinnerContainer>
    <StyledSpinner />
  </SpinnerContainer>
);

const StyledSpinner = styled(SimpleSpinner)`
  font-size: 2px;
`;

export const QuoteFormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.plainColor};
  box-shadow: 0 13px 20px -16px #717171;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 448px;
  max-width: 435px;
  padding: 19px 25px 24px;
  width: 100%;
  @media (min-width: ${columns.medium}px) {
    padding: 24px 62px;
    width: 435px;
  }
`;

export const DisclaimerText = styled(Text)`
  text-align: center;
`;

export const DateContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

export const LocationCompleteContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 250px;
`;

export const QuoteFormTitle = styled(H3)`
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.boxBorderColor}; */
  font-size: 24px;
  padding-bottom: 16px;
  text-align: center;
`;

export const QuoteFormProgressWrapper = styled.div`
  border: 1px solid #e0e4e7;
  height: 8px;
  width: calc(100% + 16px);
  border-radius: 4px;
  align-self: center;

  position: relative;
`;

interface QFProgress {
  progress: number;
}

export const QuoteFormProgress = styled.div<QFProgress>`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(${({ progress }) => progress * 100}% + 2px);
  border-radius: 4px;
  height: calc(100% + 2px);
  margin: -1px;
  background-color: #c9f1f0;
`;

export const QuoteFormContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

export const QuestionWrapper = styled.div`
  position: relative;
  max-height: 400px;
  @media (max-width: ${columns.small}px) {
    max-height: 400px;
  }
  padding: 0;
`;

export const QuestionWrapperVisibilityHidden = styled.div`
  visibility: hidden;
  position: relative;
  max-height: 400px;
  @media (max-width: ${columns.small}px) {
    max-height: 400px;
  }
  padding: 0;
`;

export const QuestionText = styled(Text)`
  ${({ theme }) => theme.fonts.text.fontWeight.light};

  color: ${({ theme }) => theme.colors.headerBase};
  font-size: 20px;
  line-height: 1.2;
  margin: 16px 0;
  padding: 0 16px;
  text-align: center;

  ${mediaQueries.large`
    ${({ theme }) => theme.fonts.text.fontWeight.regular};
    font-size: 16px;
    line-height: 1.5;
    padding: 0;
  `};
`;

export const FooterText = styled(Text)`
  font-weight: 100;
  text-align: center;
  font-size: 15px;
`;

export const QuoteInput = styled(FieldInput).attrs({
  hideErrors: true,
})`
  ${({ theme }) => theme.fonts.text.fontWeight.light};

  margin-bottom: 6px;

  input {
    font-size: 16px;
    padding: 9px 16px;

    box-sizing: border-box;

    &::placeholder {
      ${({ theme }) => theme.fonts.text.fontWeight.light};
    }
  }

  label {
    display: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

export const MessageWrapper = styled.div`
  align-items: center;
  bottom: 0;
  color: ${({ theme }) => theme.colors.headerBase};
  display: flex;
  font-size: 18px;
  left: 0;
  justify-content: center;
  line-height: 1.2;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 90px;
  margin-top: 15px;
`;

export const ContinueButton = styled(Button)`
  font-size: 16px;
  display: flex;

  ${mediaQueries.large`
    font-size: 20px;
  `};
`;

export const CancelArrangement = styled('h3')`
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.textSoft};
  opacity: 0.4;
`;

export const BackButton = styled(Button)`
  ${({ theme }) => theme.fonts.text.fontWeight.light};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.baseBlue};
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  padding-right: 10px;
  transiton: color 250ms ease;

  &:hover {
    color: ${({ theme }) => theme.colors.headerBase};
  }

  &:focus {
    outline: 0;
  }

  :before {
    transform: scale(-1, 1);
    text-decoration: none;
  }
`;

export const UnderlinedButtonLabel = styled(Text)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.baseBlue};
`;

export const SignUpBtnSpacer = styled('div')`
  margin-top: 40px;
`;
