// @flow
import styled, { css } from 'styled-components';

const IconSvg = styled.div`
  width: 20px;
  height: 20px;
  ${p =>
    p.size &&
    css`
      width: ${p.size}px;
      height: ${p.size}px;
    `} background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${p => p.src});
`;

export default IconSvg;
