import { validation } from '/util';

import SimpleSpinner from 'components/SimpleSpinner';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QuoteFlowContext } from '/context';

import { StepProps } from '../StepWrapper';
import Input from '../Input';
import { SimpleKV } from '../types';
import Buttons from '../Buttons';
import { QuestionText, QuestionWrapper } from '../styled';
import { PURCHASE_TYPE } from 'tulip-core';

export const serviceOptionsToEnum = {
  [PURCHASE_TYPE.SOON]: 'PACKAGE',
  [PURCHASE_TYPE.IMMEDIATELY]: 'PACKAGE',
  [PURCHASE_TYPE.PRENEED]: 'PRE_NEED',
  [PURCHASE_TYPE.PRENEED_INSURANCE]: 'PRE_NEED_INSURANCE',
};

const ScaledSpinner = styled(SimpleSpinner)`
  font-size: 2px;
`;

const Centered = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

interface CreateQuoteProps extends StepProps {
  actions: {
    createQuote: (values: SimpleKV) => void;
  };
}

const STEP_ID = 'CreateQuote';

const CreateQuote = (props: CreateQuoteProps) => {
  const {
    updateQuoteData,
    getQuoteRequestData,
    actions,
    recordStepStarted,
    // bulkUpdateQuoteData,
  } = props;

  const { createQuote } = actions;

  const [submitting, setSubmitting] = useState(false);

  const initialFieldsState: SimpleKV = {};
  const [fields, setFields] = useState(initialFieldsState);

  const updateFieldValue = (id: string, value: string) => {
    setFields({ ...fields, [id]: value });
  };

  useEffect(recordStepStarted, []);

  const inputs = [
    {
      id: 'purchaserEmail',
      label: 'Email address',
      isValidCheck: validation.isEmail,
    },
    {
      id: 'purchaserPhone',
      label: 'Phone number',
      isValidCheck: validation.isPhoneNumber,
    },
  ];

  const continueDisabled = !inputs.every(({ id, isValidCheck }) => {
    const value = fields[id];
    return value && isValidCheck(value);
  });

  let mainContent;

  if (submitting) {
    mainContent = (
      <Centered>
        <ScaledSpinner />
      </Centered>
    );
  } else {
    mainContent = inputs.map(({ id, label, isValidCheck }) => (
      <Input
        key={id}
        id={id}
        value={fields[id] || ''}
        label={label}
        onBlur={() => {
          updateQuoteData(id, fields[id], STEP_ID);
        }}
        isValidCheck={isValidCheck}
        onChange={updateFieldValue}
      />
    ));
  }

  return (
    <React.Fragment>
      <QuestionWrapper>
        <QuestionText>What are your contact details?</QuestionText>
        {mainContent}
      </QuestionWrapper>
      <Buttons
        continueDisabled={continueDisabled}
        continueAction={async () => {
          setSubmitting(true);
          const formattedQuote = getQuoteRequestData();
          formattedQuote.decedentImplant = false;
          formattedQuote.decedentOverweight = false;
          formattedQuote.decedentLocationType = 'hospital';
          createQuote(formattedQuote);
        }}
      />
    </React.Fragment>
  );
};

const CreateQuoteWithQuoteFlow = (props: any) => (
  <QuoteFlowContext.Consumer>
    {(values: any) => <CreateQuote {...values} {...props} />}
  </QuoteFlowContext.Consumer>
);

CreateQuoteWithQuoteFlow.displayName = STEP_ID;

export default CreateQuoteWithQuoteFlow;
