// @flow
import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import RadioButton from '/components/RadioButton';

import { css } from '../util';

type AccordionProps = {
  id: string,
  // if true, will render radioButtons as long as there's more than one
  // step in the accordion
  radioButtons: boolean,
  headerText: string,
  children: React.Node[],
  stepBackgroundColor?: string,
  // The step you want to have selected by default.
  // If there is only 1 step available, that will automatically be chosen,
  // Otherwise, if this prop is undefined, no step
  // will be open on initial mount. Steps are 1 indexed
  defaultStep?: number,
  stepChangedCallback?: number => mixed,
  containerStyle: any,
  headerStyle: any,
  stepContainerStyle: any,
  stepHeaderStyle: any,
  stepBodyStyle: any,
};

class Accordion extends React.Component<AccordionProps> {
  constructor(props) {
    super(props);

    this.state = {
      chosenStep: this._getDefaultStep(props),
    };

    this._validChildren = this._validChildren.bind(this);
    this._getDefaultStep = this._getDefaultStep.bind(this);
    this._changeStep = this._changeStep.bind(this);
    this._renderSteps = this._renderSteps.bind(this);
  }

  _validChildren = (children = this.props.children) => {
    // React includes undefined values / arrays in props.children, which often
    // are the result of normal conditional rendering patterns.
    // We should ignore those!
    return _.flatten(children).filter(child => child);
  };

  _getDefaultStep = (props: AccordionProps): ?number => {
    // If we have no children, we have no steps at all!
    if (!props.children) {
      return null;
    }

    const children = this._validChildren();

    // In situations where we've been handed less than 2 complete steps
    // we've selected an option already!
    if (children && children.length < 3) {
      return 1;
    }

    return props.defaultStep || null;
  };

  _changeStep = (stepNumber: number) => () => {
    this.setState({ chosenStep: stepNumber });
    if (this.props.stepChangedCallback) {
      this.props.stepChangedCallback(stepNumber);
    }
  };

  _renderSteps = () => {
    if (!this.props.children) {
      return;
    }
    const children = this._validChildren();

    // We should be passed an even number of child nodes!
    if (children.length === 0 || children.length % 2 !== 0) {
      return;
    }

    // Iterate through child components creating steps by alternating
    // between Header and Body
    const steps = [];
    let isHeader = true;
    let lastHeader;

    children.forEach((node, i) => {
      if (isHeader) {
        lastHeader = node;
      } else {
        const stepNumber = (i + 1) / 2;
        const key = `Accordion_${this.props.id}_Step_${stepNumber}`;
        const isSelected = this.state.chosenStep === stepNumber;
        const displayRadioButton =
          this.props.radioButtons && this._validChildren().length > 2;

        steps.push(
          <StepContainer style={this.props.stepContainerStyle} key={key}>
            <StepHeader
              style={this.props.stepHeaderStyle}
              onClick={this._changeStep(stepNumber)}
            >
              {displayRadioButton && <RadioButton selected={isSelected} />}
              {lastHeader}
            </StepHeader>
            <StepBody style={this.props.stepBodyStyle} visible={isSelected}>
              {node}
            </StepBody>
          </StepContainer>
        );
      }
      isHeader = !isHeader;
    });

    return steps;
  };

  render() {
    return (
      <AccordionContainer style={this.props.containerStyle} id={this.props.id}>
        <AccordionHeader style={this.props.headerStyle}>
          {this.props.headerText}
        </AccordionHeader>
        {this._renderSteps()}
      </AccordionContainer>
    );
  }
}

export default Accordion;

const AccordionContainer = styled('div')`
  margin-bottom: 30px;
  border-radius: 2px;
  border: 1px ${({ theme }) => theme.lightBlue} solid;
  border-bottom: none;
  ${css.fontSize(14)};
`;

const AccordionHeader = styled('h4')`
  padding-left: 11px;
  padding-top: 14px;
  color: ${({ theme }) => theme.darkBlue};
  ${css.fontSize(22)};
  margin: 0;
  font-weight: normal;
`;

const StepContainer = styled('div')`
  padding: 0px;
  border-bottom: 1px ${({ theme }) => theme.lightBlue} solid;
  ${css.fontSize(14)};
`;

const StepHeader = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-botton: 10px;
  ${css.fontSize(14)};
`;

const StepBody = styled('div')`
  display: none;
  background-color: ${({ theme }) => theme.lightBlue12};
  justify-content: flex-start;
  padding: 12px;
  border-top: 1px ${({ theme }) => theme.lightBlue} solid;
  ${css.fontSize(14)};
  ${props => props.visible && `display: flex;`};
`;
