function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    font-size: 40px;\n    line-height: 1.33;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      line-height: 1.5;\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n        font-size: 24px;\n        line-height: 1.33;\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n        font-size: 24px;\n        line-height: 1.33;\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n        font-size: 32px;\n        line-height: 1.33;\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n        font-size: 40px;\n        line-height: 1.33;\n      "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import NextLink from 'next/link';
import styled, { css } from 'styled-components';
import { space, textAlign, fontSize, width, maxWidth } from 'styled-system';
import { mediaQueries, BREAKPOINTS } from '../../variables';

var getHeaderColor = function getHeaderColor(theme, type) {
  switch (type) {
    case 'dark':
      return theme.colors.headerDarken;

    case 'neutral':
      return theme.colors.headerNeutral;

    default:
      return theme.colors.headerBase;
  }
};

var getSubheaderColor = function getSubheaderColor(theme, type) {
  switch (type) {
    case 'neutral':
      return theme.colors.subheaderNeutral;

    case 'base':
      return theme.colors.subheaderBase;

    default:
      return theme.colors.subheaderBaseDark;
  }
};

var getBodyTextColor = function getBodyTextColor(theme, type) {
  switch (type) {
    case 'neutral':
      return theme.colors.bodyTextBaseNeutral;

    case 'base':
      return theme.colors.bodyTextBase;

    case 'warning':
      return theme.colors.bodyTextWarning;

    case 'success':
      return theme.colors.bodyTextSuccess;

    default:
      return theme.colors.bodyTextBaseDark;
  }
};

export var H1 = styled.h1.withConfig({
  displayName: "Typography__H1",
  componentId: "sc-1qv71mz-0"
})(["", ";", ";", ";", ";"], function (_ref) {
  var theme = _ref.theme,
      type = _ref.type;
  return css(["", ";", ";color:", ";font-size:32px;line-height:1.2;", ";margin:0;"], theme.fonts.headers.fontFamily, theme.fonts.headers.fontWeight.regular, getHeaderColor(theme, type), mediaQueries.medium(_templateObject()));
}, space, textAlign, maxWidth);
export var H2 = styled.h2.withConfig({
  displayName: "Typography__H2",
  componentId: "sc-1qv71mz-1"
})(["", ";", ";", ";", ";"], function (_ref2) {
  var theme = _ref2.theme,
      type = _ref2.type;
  return css(["", ";", ";color:", ";font-size:24px;font-weight:500;line-height:1.2;", ";margin:0;"], theme.fonts.headers.fontFamily, theme.fonts.headers.fontWeight.regular, getHeaderColor(theme, type), mediaQueries.medium(_templateObject2()));
}, space, textAlign, maxWidth);
export var H3 = styled.h3.withConfig({
  displayName: "Typography__H3",
  componentId: "sc-1qv71mz-2"
})(["", ";", ";", ";", ";"], function (_ref3) {
  var theme = _ref3.theme,
      type = _ref3.type;
  return css(["", ";", ";color:", ";font-size:20px;line-height:1.2;", ";margin:0;"], theme.fonts.headers.fontFamily, theme.fonts.headers.fontWeight.regular, getHeaderColor(theme, type), mediaQueries.medium(_templateObject3()));
}, space, textAlign, maxWidth);
export var H4 = styled.h4.withConfig({
  displayName: "Typography__H4",
  componentId: "sc-1qv71mz-3"
})(["", ";", ";", ";", ";"], function (_ref4) {
  var theme = _ref4.theme,
      type = _ref4.type;
  return css(["", ";", ";color:", ";font-size:20px;line-height:1.2;", ";margin:0;"], theme.fonts.text.fontFamily, theme.fonts.text.fontWeight.light, getSubheaderColor(theme, type), mediaQueries.medium(_templateObject4()));
}, space, textAlign, maxWidth);
export var Text = styled.span.withConfig({
  displayName: "Typography__Text",
  componentId: "sc-1qv71mz-4"
})(["", ";", ";", ";", ";", ";"], function (_ref5) {
  var theme = _ref5.theme,
      type = _ref5.type;
  return css(["", ";", ";color:", ";font-size:16px;line-height:1.33;display:block;strong{", ";}ol li{margin-bottom:6px;}", ";"], theme.fonts.text.fontFamily, theme.fonts.text.fontWeight.light, getBodyTextColor(theme, type), theme.fonts.text.fontWeight.regular, mediaQueries.medium(_templateObject5()));
}, space, fontSize, textAlign, maxWidth);
export var H1Special = styled(H1).withConfig({
  displayName: "Typography__H1Special",
  componentId: "sc-1qv71mz-5"
})(["font-weight:500;font-size:24px;", ";"], mediaQueries.medium(_templateObject6()));
export var H2Special = styled(H2).withConfig({
  displayName: "Typography__H2Special",
  componentId: "sc-1qv71mz-6"
})(["font-weight:300;@media screen and (max-width:", "px){", ";}"], BREAKPOINTS.MEDIUM, function (_ref6) {
  var theme = _ref6.theme;
  return css(["", ";font-size:20px;text-align:center;"], theme.fonts.text.fontFamily);
}); // todo move tulip-customer/srccomponents/Link to tulip-ui

var RouterLink = function RouterLink(_ref7) {
  var to = _ref7.to,
      props = _objectWithoutProperties(_ref7, ["to"]);

  return React.createElement(NextLink, {
    href: to
  }, React.createElement("a", props));
};

export var Link = styled.a.attrs({
  as: function as(props) {
    return props.to ? RouterLink : 'a';
  }
}).withConfig({
  displayName: "Typography__Link",
  componentId: "sc-1qv71mz-7"
})(["", ";", ";", ";", ";", ";"], function (_ref8) {
  var theme = _ref8.theme;
  return css(["", ";", ";color:", ";cursor:pointer;font-size:16px;text-decoration:underline;&:hover,&:active{color:", ";}"], theme.fonts.text.fontFamily, theme.fonts.text.fontWeight.light, theme.colors.link, theme.colors.linkHover);
}, space, width, textAlign, maxWidth);