// @flow
import * as React from 'react';
import styled from 'styled-components';

import { columns } from '../../../components/Grid';
import { BREAKPOINTS } from '../../../globalStyles';

const BREAKPOINT = 800;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
  @media (max-width: ${columns.small}px) {
    margin-bottom: 16px;
  }
`;

const Title = styled.h2`
  max-width: 325px;
  font-family: Brown;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.31;
  text-align: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.boldBlue};
`;

const Logo = styled('div')`
  margin-left: auto;
  & > img {
    height: 26px;
    @media (max-width: ${BREAKPOINT}px) {
      height: 25px;
    }
  }
`;

const Name = styled('span')`
  display: block;
  color: ${({ theme }) => theme.textHard};
`;

const Star = styled('span')`
  margin-right: 8px;
`;

const Avatar = styled('img')`
  display: block;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
`;

type Props = {
  name: string,
  avatar?: string,
  quote: string,
  location?: string,
  className?: string,
  page: number,
  activePage: number,
  link?: string,
  title: string,
  testimonialItemTheme: string,
};

type State = {
  pageNum: number,
};

class TestimonialItem extends React.Component<Props, State> {
  _getLogoImageSource = () => {
    switch (this.props.link) {
      case 'yelp':
        return '/img/yelp-logo2.png';
      case 'google':
        return '/img/googlelogo-small.png';
      default:
        return '/img/logo-square.png';
    }
  };

  render() {
    const {
      name,
      title,
      quote,
      location,
      avatar,
      testimonialItemTheme,
    } = this.props;
    const cleanTheme = testimonialItemTheme === 'clean';

    const Wrapper = styled('div')`
      background: #fff;
      border-radius: ${cleanTheme ? '5px' : '2px'};
      box-shadow: ${cleanTheme ? '0px 4px 30px rgba(0, 0, 0, 0.05)' : 'none'};
      padding: ${cleanTheme ? '32px 32px' : '20px 20px'};
      margin: 0 10px;
      box-sizing: border-box;
      height: 100%;

      flex-grow: 1;
      display: flex;
      flex-direction: column;
      @media (max-width: ${BREAKPOINT}px) {
        padding: ${cleanTheme ? '32px' : '15px'};
      }
      @media (max-width: ${BREAKPOINTS.SMALL}px) {
        margin-bottom: 16px;
      }
    `;

    const Location = styled('div')`
      color: ${({ theme }) => (cleanTheme ? theme.turquoise : theme.textSoft)};
    `;

    const CustomerInfo = styled('div')`
      font-size: 1em;
      font-weight: ${cleanTheme ? '400' : '300'};
      text-align: left;
    `;

    const Rating = styled.div`
      width: 100%;
      display: flex;
      justify-content: ${cleanTheme ? 'flex-start' : 'center'};
      & > svg {
        height: 19px;
        width: 19px;
        margin-right: 8px;
      }
    `;

    const P = styled.p`
      font-size: ${cleanTheme ? '16px' : '1.6rem'};
      font-weight: 300;
      text-align: left;
      line-height: 1.61;
      color: ${({ theme }) => (cleanTheme ? theme.grayBlue : theme.boldBlue)};
      margin-bottom: ${cleanTheme ? '32px' : '22px'};
      margin-top: 16px;
    `;

    return (
      <Wrapper>
        {!cleanTheme ? <Title>{title}</Title> : null}
        <Rating>
          {[1, 2, 3, 4, 5].map(index => (
            <Star key={index} className="tulicon-icon_star" />
          ))}
        </Rating>
        <P>{quote}</P>
        <Details>
          <Avatar src={avatar} />
          <CustomerInfo>
            <Name>{name}</Name>
            <Location>{location}</Location>
          </CustomerInfo>
          <Logo>
            <img src={this._getLogoImageSource()} />
          </Logo>
        </Details>
      </Wrapper>
    );
  }
}

export default TestimonialItem;
