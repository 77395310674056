// @flow
import * as React from 'react';
import styled from 'styled-components';

import { css } from '../util';

export const List = styled('ul')`
  margin: 26px 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
`;

export const Item = styled('li')`
  padding: 0;
  padding-left: 35px;
  margin-bottom: 25px;
  position: relative;
  font-family: Brown;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.31;
  color: #4f6378;
  ${p => p.fontSize === 'leading' && `${css.fontSize(20)};`};

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: ${p =>
      p.imgUrl ? `url(${p.imgUrl})` : "url('/img/bullet2.png')"};
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: ${p => (p.marginTop ? '8' : '-4')}px;
    left: 0px;
  }

  @media (max-width: 520px) {
    padding-left: 30px;
  }
`;

type Props = {
  items: React.Node[],
  fontSize?: 'leading',
  className?: string,
  style?: Object,
  imgUrl: string,
};

class BulletListV2 extends React.Component<Props> {
  _generateKey = item => {
    switch (typeof item) {
      case 'string':
        return item.substring(0, Math.min(5, item.length));
      case 'object':
        return item.key;
      default:
        // eslint-disable-next-line no-console
        console.error('BulletListV2 item neither string nor object');
    }
  };

  render() {
    const { items, fontSize, className, style, imgUrl } = this.props;
    return (
      <List className={className} style={style}>
        {items.map(item => (
          <Item
            key={this._generateKey(item)}
            imgUrl={imgUrl}
            fontSize={fontSize}
          >
            {item}
          </Item>
        ))}
      </List>
    );
  }
}

export default BulletListV2;
