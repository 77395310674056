// @flow
import * as React from 'react';
import styled from 'styled-components';

import { css } from '../util';

type Props = {
  tag?: string,
};

const e = React.createElement;

/**
 * A sub heading which should go under a main Heading, and won't use the formal
 * serif font.
 */
const SubHeading: React.ComponentType<Props> = styled(
  ({ tag = 'h3', ...props }) => e(tag, props)
)`
  ${css.fontSize(24)};
  font-weight: normal;
  color: ${({ theme }) => theme.textHard};
  ${css.rem('margin-top', 30)};
  ${css.rem('margin-bottom', 30)};
`;

export default SubHeading;
