import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const SimpleSpinner = styled('div')`
  border: 4em solid ${({ theme }) => theme.lightGrey};
  border-top: 4em solid ${({ theme }) => theme.darkBlue};
  border-radius: 50%;
  width: 30em;
  height: 30em;
  animation: ${spin} 2s linear infinite;
`;

export default SimpleSpinner;
