'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listUrl = undefined;

var _models = require('../models');

var listUrl = exports.listUrl = function listUrl(filter) {
  return '/admin/purchases/filtered/' + filter;
};