// This exists so there's a typesafe way to obtain a reference to Sentry
interface Sentry {
  captureException: (e: any) => void;
  captureMessage: (m: any) => void;
}

interface Window {
  Sentry?: Sentry;
  [key: string]: any;
}

class SentryManager {
  private readonly _sentry: Sentry | undefined;

  public constructor() {
    if (typeof window !== 'undefined') {
      const windowRef: Window = window;
      this._sentry = windowRef.Sentry;
    }
  }

  // Return the sentry Object if it exists
  public sentry = (): Sentry | undefined => this._sentry;

  public captureException = (e: any) => {
    if (this._sentry) {
      this._sentry.captureException(e);
    }
  };

  public captureMessage = (m: any) => {
    if (this._sentry) {
      this._sentry.captureException(m);
    }
  };
}

const manager = new SentryManager();
export default manager;
