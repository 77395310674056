import React from 'react';
import { withTheme } from 'styled-components';

var Pin = function Pin(_ref) {
  var color = _ref.color,
      height = _ref.height,
      theme = _ref.theme,
      width = _ref.width;
  return React.createElement("svg", {
    width: width,
    height: height,
    viewBox: "0 0 17 23",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M8.121.2C3.674.2.057 3.85.057 8.338c0 1.618.493 2.879 1.278 4.112l6.317 9.935a.555.555 0 0 0 .939 0l6.317-9.935c.785-1.233 1.278-2.494 1.278-4.112C16.186 3.85 12.569.2 8.12.2zm0 1.123c3.846 0 6.952 3.134 6.952 7.015 0 1.398-.386 2.38-1.103 3.507L8.12 21.044l-5.848-9.199c-.717-1.128-1.104-2.11-1.104-3.507 0-3.881 3.106-7.015 6.952-7.015zm0 3.086c-2.143 0-3.893 1.766-3.893 3.929s1.75 3.928 3.893 3.928c2.144 0 3.894-1.765 3.894-3.928 0-2.163-1.75-3.929-3.894-3.929zm0 1.123c1.543 0 2.781 1.25 2.781 2.806a2.785 2.785 0 0 1-2.78 2.806A2.785 2.785 0 0 1 5.34 8.338a2.785 2.785 0 0 1 2.78-2.806z",
    color: color || theme.colors.headerDarken,
    fill: color || theme.colors.headerDarken,
    fillRule: "nonzero"
  }));
};

export default withTheme(Pin);