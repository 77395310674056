'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readableType = readableType;
exports.getDescription = getDescription;
exports.getItemTotal = getItemTotal;
var defaults = exports.defaults = {
  customDescription: null,
  quantity: 1,
  unitFeeRate: 0,
  unitFee: 0
};


var capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function readableType(type) {
  if (type === 'CORONER_FEE') return "Coroner's fees";
  if (type === 'DEATH_CERT') return 'Death certificate';
  if (type === 'IMPLANT') return 'Implant removal';
  if (type === 'OVERWEIGHT') return 'Weight surcharge';
  if (type === 'PACKAGE') return 'Cremation package';
  if (type === 'GLOBAL_COVERAGE') return 'Worldwide package';
  if (type === 'PRE_NEED_INSTALLMENT') return 'Package installment';
  if (type === 'SCATTER_AT_SEA') return 'Scatter at sea';
  if (type === 'COVERAGE_INSTALLMENT') return 'Package Installment - Global Coverage';
  if (type === 'package_mandatory_cert') return 'Package with death certificate';
  if (type === 'QUOTED_FEE') return 'County Fee';
  if (type === 'EXPEDITED_SHIPPING_DEATH_CERT') return 'Expedited shipping for death certificates';
  return type.split('_').map(function (word) {
    return word.toLowerCase();
  }).map(function (word) {
    return capitalize(word);
  }).join(' ');
}

function getDescription(li) {
  if ((li.type === 'custom' || li.type === 'URN' || li.type === 'TREE' || li.type === 'KEEPSAKE') && li.customDescription) return li.customDescription;
  if (li.type) return readableType(li.type);
  return 'Uncategorized';
}

function getItemTotal(li) {
  return li.quantity * (li.unitPrice + li.unitFee);
}