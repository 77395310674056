function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      font-size: 20px;\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import * as React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { mediaQueries } from '../../variables';
var Center = styled.div.withConfig({
  displayName: "Button__Center",
  componentId: "z4j5s7-0"
})(["width:100%;height:100%;display:flex;justify-content:center;align-items:center;"]);
var e = React.createElement; // $FlowFixMe

var Wrapper = styled(function (_ref) {
  var tag = _ref.tag,
      props = _objectWithoutProperties(_ref, ["tag"]);

  return e(tag, props);
}).withConfig({
  displayName: "Button__Wrapper",
  componentId: "z4j5s7-1"
})(["", ";transition:250ms ease;", ";"], function (_ref2) {
  var theme = _ref2.theme,
      secondary = _ref2.secondary,
      tertiary = _ref2.tertiary,
      disabled = _ref2.disabled;
  return css(["border-radius:2px;border:2px solid;padding:0 32px;cursor:pointer;box-sizing:border-box;height:50px;font-size:16px;font-weight:normal;color:#093969;", ";display:inline-block;background-color:", ";color:", ";border-color:", ";", ";"], mediaQueries.medium(_templateObject()), secondary ? theme.colors.transparent : tertiary ? theme.colors.tertiaryButtonBackground : theme.colors.primaryButtonBackground, secondary ? theme.colors.secondaryButtonText : tertiary ? theme.colors.tertiaryButtonText : theme.colors.primaryButtonText, secondary ? theme.colors.secondaryButtonBackground : tertiary ? theme.colors.tertiaryButtonBackground : theme.colors.primaryButtonBackground, disabled ? css(["cursor:default;opacity:0.7;"]) : css(["&:hover{background-color:", ";color:", ";}&:focus{outline:0;}"], secondary ? theme.colors.secondaryButtonHoverBackground : theme.colors.transparent, secondary ? theme.colors.plainColor : tertiary ? theme.colors.tertiaryButtonBackground : theme.colors.primaryButtonText));
}, space);

var getTagType = function getTagType(props) {
  if (props.tag) {
    return props.tag;
  }

  if (props.href || props.to) {
    return 'a';
  }

  return 'button';
};

var Button = function Button(_ref3) {
  var label = _ref3.label,
      children = _ref3.children,
      rest = _objectWithoutProperties(_ref3, ["label", "children"]);

  return React.createElement(Wrapper, _extends({
    tag: getTagType(rest)
  }, rest, {
    disabled: rest.disabled || rest.loading
  }), React.createElement(Center, null, label ? label : children));
};

export default Button;