// @flow
import gql from 'graphql-tag';
import _ from 'lodash/fp';

import initApollo from '../apollo-wrapper/init-apollo';

export const events = {
  QUOTE_ACCEPTED: 'Quote Accepted',
  QUOTE_CLICKED: 'Quote Clicked',
  QUOTE_FLOW_COMPLETED: 'Quote Flow Completed',
  QUOTE_COMPLETED: 'Quote Completed',
  QUOTE_URGENCY: 'Quote Urgency',
  CHECKOUT_BEGIN: 'Checkout Begin',
  CHECKOUT_COMPLETED: 'Checkout Completed',
  PAYMENT_STARTED: 'Payment Started',
  DECEDENT_COMPLETED: 'DecedentCompleted',
  PREPAYMENT_STARTED: 'Prepayment Started',
  PAYMENT_COMPLETED: 'Payment Completed',
  PREPAYMENT_COMPLETED: 'Prepayment Completed',
  QUOTE_FLOW_CONTINUED: 'QuoteFlowContinued',
  QUOTE_FLOW_STARTED: 'QuoteFlowStarted',
  QUOTE_FLOW_STEP_STARTED: 'QuoteFlowStepStarted',
  QUOTE_FLOW_STEP_DATA_ENTERED: 'QuoteFlowStepDataEntered',
  QUOTE_FLOW_STEP_COMPLETED: 'QuoteFlowStepCompleted',
  QUOTE_FLOW_STEP_BACK: 'QuoteFlowStepBack',
  QUOTE_FLOW_STEP_SKIPPED_PRESET: 'QuoteFlowStepSkippedByPreset',
  QUESTIONNAIRE_STARTED: 'QuestionnaireStarted',
  QUESTIONNAIRE_SKIP: 'QuestionnaireSkip',
  QUESTIONNAIRE_BACK: 'QuestionnaireBack',
  QUESTIONNAIRE_CONTINUE: 'QuestionnaireContinue',
};
const client = initApollo();
const query = gql`
  query Customer {
    customer {
      did
      isStaff
    }
  }
`;
class EventManager {
  constructor() {
    this.events = events;
  }

  _isValidEvent(event) {
    return Object.values(this.events).indexOf(event) !== -1;
  }

  async registerEvent(event, data) {
    if (this._isValidEvent(event)) {
      const response = await client.query({ query: query });
      let userDid = _.getOr(null, ['data', 'customer', 'did'])(response);
      let isStaff = _.getOr(false, ['data', 'customer', 'isStaff'])(response);
      data['userId'] = userDid;
      data['isStaff'] = isStaff;
      window.dataLayer.push({ event: event, properties: data });
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Refused to register ${event} as it isn't pre-listed in eventManager.js`
      );
    }
  }
}

const manager = new EventManager();
export default manager;
