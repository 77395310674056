import React, { useState } from 'react';

export interface NavigationProps {
  stepForward: () => void;
  setCurrentStep: (_stepIndex: number) => void;
  stepBack: (_event: any, skipped: boolean) => void;
  skip: (lock?: boolean) => void;
  stepIds: string[];
  setStepIds: (stepIds: any) => void;
  currentStep: number;
  noPathBack?: boolean;
}

export enum Direction {
  Forward,
  Back,
}

const InitalLockedSteps: { [stepId: string]: boolean } = {};

export const FormNavigationContext = React.createContext({
  stepForward: () => {},
  stepBack: () => {},
  setCurrentStep: (_step: number) => {},
  skip: () => {},
  stepIds: [],
  setStepIds: (_ids: string[]) => {},
  currentStep: 0,
});

export const QuoteFormNavigationProvider = (props: any) => {
  const [stepIds, setStepIds] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [lastDirection, setLastDirection] = useState(Direction.Forward);
  const [lockedSteps, setLockedSteps] = useState(InitalLockedSteps);

  const stepForward = () => {
    if (currentStep !== stepIds.length - 1) {
      setCurrentStep(currentStep + 1);
      setLastDirection(Direction.Forward);
    }
  };

  const stepBack = (_event: any, skipped: boolean) => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
      setLastDirection(Direction.Back);

      if (!skipped) {
        // Log this event
        const fromStep = stepIds[currentStep];
        const toStep = stepIds[currentStep - 1];
        props.sendStepBackEvent && props.sendStepBackEvent(fromStep, toStep);
      }
    }
  };

  // If the first or first N steps all issue a skip then on N+1 steps the
  // back button shouldn't be visible as it could lead to the user being taken
  // to a locked step
  let noPathBack = true;
  for (let i = currentStep - 1; i >= 0; i--) {
    if (!lockedSteps[i]) {
      noPathBack = false;
      break;
    }
  }

  const value: NavigationProps = {
    setStepIds,
    currentStep,
    stepForward,
    stepBack,
    stepIds,
    noPathBack,
    setCurrentStep,
    skip: (lock = false) => {
      // Lock option should only be specified where the skip is due to a preset
      // or the value is not changeable at a later point.
      lockedSteps[currentStep] = lock;
      setLockedSteps({ ...lockedSteps });
      if (lastDirection === Direction.Forward) {
        stepForward();
      } else {
        stepBack(null, true);
      }
    },
  };

  return <FormNavigationContext.Provider value={value} {...props} />;
};

export default QuoteFormNavigationProvider;
