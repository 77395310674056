function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      display: none;\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled, { css } from 'styled-components';
import { mediaQueries } from '../../variables';
var MobileOnly = styled.div.withConfig({
  displayName: "MobileOnly",
  componentId: "tbcta2-0"
})(["", ";"], function (_ref) {
  var breakpoint = _ref.breakpoint,
      display = _ref.display;
  return css(["display:", ";", ";"], display || 'block', mediaQueries[breakpoint || 'medium'](_templateObject()));
});
export default MobileOnly;