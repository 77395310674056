// @flow
import * as React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
  position: relative;
  margin: 7px 0;
`;

const Label = styled('label')`
  position: absolute;
  left: 20px;
  top: 8px;
  color: ${({ theme }) => theme.textSofter};
  pointer-events: none;
  font-size: 12px;
  transition: all 0.1s;

  ${p =>
    p.hasError &&
    `
    color: #ff6364;
  `} ${p =>
    p.empty &&
    `
    font-size: 18px;
    top: 22px;
  `};

  ${p =>
    p.focused &&
    `
    color: ${({ theme }) => theme.textHard};
  `};
`;

const Input = styled('input')`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 26px 20px 18px;
  border: 1px #bbc3d4 solid;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.bodyTextBaseDark};
  font-size: 16px;
  transition: all 0.1s;
  // margin-bottom: 32px;

  ${p =>
    p.hasError &&
    `
    background-color: #ffeded;
    border-color: #ff6364;
  `} &:focus {
    background-color: #f6fafb;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.bodyTextBaseDark};
  }
`;

const Error = styled.div`
  color: #ff6364;
  margin-top: 4px;
  text-align: left;
`;

export type Props = {
  value?: string,
  error?: string,
  touched?: boolean,
  onChange?: () => void,
  onBlur?: () => void,
  placeholder?: string,
  name?: string,
  id?: string,
  type?: string,
  label?: string,
  hideErrors?: boolean,
  errorMessage?: string,
};

const FieldInput = ({
  value,
  error,
  touched,
  label,
  onChange,
  onBlur,
  placeholder,
  name,
  id,
  type,
  hideErrors,
  errorMessage,
  ...rest
}: Props) => {
  // console.log('error: ', error);
  // console.log('touched: ', touched);
  // console.log('!hideErrors: ', !hideErrors);
  return (
    <Container {...rest}>
      <Label empty={!value} hasError={!!error && touched}>
        {label}
      </Label>
      <Input
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        hasError={!!error && touched}
        placeholder={placeholder}
        type={type}
      />
      {error && touched && !hideErrors && <Error>{errorMessage}</Error>}
    </Container>
  );
};

export default FieldInput;
