// @flow
import getConfig from 'next/config';

// const { publicRuntimeConfig } = getConfig();

// bandaid for storybook
const { publicRuntimeConfig } = getConfig()
  ? getConfig()
  : { publicRuntimeConfig: {} };
export const HOST = publicRuntimeConfig.host;
export const PHONE_FORMATTED = '(844) 942-4909';
export const PHONE_NORMAL = '8449424909';
export const STRIPE_PUBLISHABLE_KEY = publicRuntimeConfig.stripePublishableKey;
export const BASE_URL = publicRuntimeConfig.baseUrl;
export const AFFIRM_PUBLIC_KEY = publicRuntimeConfig.affirmPublicKey;
export const AFFIRM_URL = publicRuntimeConfig.affirmUrl;
export const AFFIRM_SCRIPT_URL = publicRuntimeConfig.affirmScriptUrl;
export const RELEASE_VERSION = publicRuntimeConfig.releaseVersion;
export const ENVIRONMENT = publicRuntimeConfig.environment;
export const FARGATE = publicRuntimeConfig.fargate;
export const NODE_ENV = publicRuntimeConfig.node_env;
export const DOCKER_URL = `https://${HOST}`;
export const GOOGLE_TAG_MANAGER_ID = publicRuntimeConfig.gtmId;
export const KUSTOMER_HOOK_KEY = publicRuntimeConfig.kustomerHookKey;
export const USER_POOL_ID = publicRuntimeConfig.cognitoUserPoolId;
export const COGNITO_USER_CUSTOMER_CLIENT_ID =
  publicRuntimeConfig.cognitoUserCustomerClientId;
export const EFUNERAL_API_KEY = publicRuntimeConfig.eFuneralApiKey;
