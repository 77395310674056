// @flow
import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: String,
};

export default function Bullet({ className }: Props) {
  const StyledBullet = styled.div`
    height: 8px;
    width: 8px;
    background-color: ${({ theme }) => theme.mediumBlue};
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  `;

  return <StyledBullet className={className} />;
}
