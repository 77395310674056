import { BREAKPOINTS } from './variables';
var theme = {
  name: 'Main',
  breakpoints: [// `${BREAKPOINTS.SMALL}px`,
  "".concat(BREAKPOINTS.MEDIUM, "px"), "".concat(BREAKPOINTS.LARGE, "px"), "".concat(BREAKPOINTS.XLARGE, "px")],
  colors: {
    plainColor: '#FFF',
    transparent: 'transparent',
    primaryButtonBackground: '#FFD81A',
    primaryButtonText: '#093969',
    secondaryButtonText: '#126FCB',
    secondaryButtonHoverBackground: '#126FCB',
    tertiaryButtonBackground: '#093969',
    tertiaryButtonText: '#FFF',
    link: '#126FCB',
    linkHover: '#305881',
    linkBase: '#4F6378',
    linkDark: '#1B254E',
    headerBase: '#093969',
    headerDarken: '#073058',
    headerNeutral: '#FFF',
    headerShadow: 'rgba(0, 0, 0, 0.5)',
    subheaderBaseDark: '#4F6378',
    subheaderNeutral: '#FFF',
    subheaderBase: '#126FCB',
    bodyTextBaseDark: '#4F6378',
    bodyTextNeutral: '#FFF',
    bodyTextBase: '#126FCB',
    bodyTextWarning: '#BB1122',
    bodyTextSuccess: '#0D8927',
    bodyTextHighlighted: '#188884',
    heroBackground: '#F6FCFC',
    boxBorderColor: '#C2EFEE',
    darkBorderColor: '#093969',
    primaryFill: '#64C7C4',
    reviewQuoteBackground: '#E4F8F7',
    reviewQuoteFill: '#60C6C3',
    // TODO: Temporary Styling changes. Need to be implemented
    // in main css color themes
    lightBlue: '#5ec6c4',
    lightBlue12: 'rgba(96,198,195, 0.12)',
    lightBlue30: 'rgba(96,198,195, 0.30)',
    darkBlue: '#243965',
    grey: '#8690aa',
    lightGrey: '#b4bccf',
    lightGreyTranslucent: 'rgba(190, 199, 214, 0.2)',
    linkBlue: '#15a3eb',
    baseBlue: '#126fcb',
    // /TODO
    progressBarFill: '#24C9C4',
    boxShadow: 'rgba(79, 99, 129, 0.24)',
    greenTextColor: '#1EA9A5',
    customerFormBackground: '#E2EDF8',
    blueSectionBackground: '#F5F9FC',
    priceBoxBorder: '#7BDEDB',
    lightPriceBorder: '#C2EFEE',
    priceText: '#60C6C3',
    arrowColor: '#B3BCC4',
    neutralBorder: '#979797',
    faqTopicLine: '#7BDEDB',
    lightGrayBorder: '#B3BCC4'
  },
  fonts: {
    headers: {
      fontFamily: 'font-family: "Tiempos Headline"',
      fontWeight: {
        regular: 'font-weight: 500',
        bold: 'font-weight: 600'
      }
    },
    text: {
      fontFamily: 'font-family: Brown',
      fontWeight: {
        light: 'font-weight: 300',
        regular: 'font-weight: 500'
      }
    }
  }
};
export default theme;