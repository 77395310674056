import React from 'react';
import styled, { css } from 'styled-components';
import PlacesAutocomplete from 'react-places-autocomplete';

import SimpleSpinner from './SimpleSpinner';

interface Props {
  placeSelected: (placeId: string) => void;
  address?: string;
  purchaseType?: object;
  hideFade?: boolean;
  placeholder?: string;
  suggestionOverflow?: boolean;
  setFocus?: boolean;
  quotePageStyle?: boolean;
  className?: string;
  onSelected?: (address: string) => void;
  theme?: string;
  changeCallback?: (address: string) => void;
  hasError?: boolean;
}

interface State {
  address: string;
}

class LocationAutoComplete extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { address: props.address || '' };
  }

  public componentDidUpdate(prevProps: Props) {
    // Reset address upon change to purchase type.
    if (this.props.purchaseType !== prevProps.purchaseType) {
      this.setState({ address: '' });
    }
  }

  private handleInputChange = (address: string) => {
    this.setState({ address });
    if (this.props.changeCallback) {
      this.props.changeCallback(address);
    }
  };

  private handleSelect = (address: string, placeId: string) => {
    const { placeSelected, onSelected = () => {} } = this.props;
    if (placeSelected) {
      if (placeId !== null) {
        placeSelected(placeId);
      }
    }
    this.setState({ address });
    onSelected(address);
  };

  public render() {
    const { address } = this.state;
    const {
      placeholder,
      setFocus,
      className,
      suggestionOverflow,
      quotePageStyle,
      hideFade,
      theme,
      hasError,
    } = this.props;

    const placeholderText = placeholder || "Find your loved one's location ...";

    //console.log(suggestionOverflow);

    return (
      <Container className={className}>
        <PlacesAutocomplete
          value={address}
          onChange={this.handleInputChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            return (
              <StyledAutoComplete>
                <InputWrapper isSimpleQuoteTheme={theme === 'simpleQuote'}>
                  <div
                    style={{
                      position: 'absolute',
                      top: '15px',
                      left: '11px',
                    }}
                  >
                    <i className="tulicon-icon_magnifying-glass" />
                  </div>
                  <TextInput
                    isSimpleQuoteTheme={theme === 'simpleQuote'}
                    hasError={hasError}
                    {...getInputProps({
                      placeholder: placeholderText,
                      autoFocus: setFocus || false,
                    })}
                  />
                  <SpinnerContainer visible={loading}>
                    <SimpleSpinner />
                  </SpinnerContainer>
                </InputWrapper>
                <Suggestions
                  overflow={suggestionOverflow}
                  suggestions={suggestions}
                  getSuggestionItemProps={getSuggestionItemProps}
                  quotePageStyle={quotePageStyle}
                  theme={theme}
                />
                {!hideFade &&
                suggestions.length > 0 &&
                theme !== 'simpleQuote' ? (
                  <Fade />
                ) : null}
              </StyledAutoComplete>
            );
          }}
        </PlacesAutocomplete>
      </Container>
    );
  }
}

interface SuggestionsProps {
  suggestions: { description: string }[];
  getSuggestionItemProps: (_a: any) => void;
  overflow?: boolean;
  quotePageStyle?: boolean;
  theme?: string;
}

class Suggestions extends React.Component<SuggestionsProps> {
  public render() {
    const {
      suggestions,
      getSuggestionItemProps,
      quotePageStyle,
      theme,
      overflow,
    } = this.props;

    if (suggestions.length === 0) {
      return null;
    }

    const items = suggestions.map((suggestion, key) => {
      return (
        <Suggestion key={key} {...getSuggestionItemProps(suggestion)}>
          {suggestion.description}
        </Suggestion>
      );
    });

    return (
      <SuggestionList
        overflow={overflow}
        quotePageStyle={quotePageStyle}
        isSimpleQuoteTheme={theme === 'simpleQuote'}
      >
        {items}
      </SuggestionList>
    );
  }
}

const SpinnerContainer = styled.div<{ visible?: boolean }>`
  font-size: 0.7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-right: 8px;
  position: absolute;
  right: 0px;
  opacity: 0;
  flex-shrink: 0;
  transition: opacity 0.2s;
  ${({ visible }) => {
    if (visible) {
      return css`
        opacity: 1;
        display: flex;
      `;
    } else {
      return css`
        opacity: 0;
        display: none;
      `;
    }
  }}
`;

const Container = styled('div')<{ isSimpleQuoteTheme?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  :first-child {
    height: 100%;
  }
  ${({ isSimpleQuoteTheme }) => {
    if (isSimpleQuoteTheme) {
      return css`
        box-sizing: border-box;
      `;
    }
  }}
`;

const Fade = styled('div')`
  height: 50px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 77%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  bottom: 0px;
  z-index: 1000;
  pointer-events: none;
`;

const StyledAutoComplete = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  flex-grow: 1;
`;

const InputWrapper = styled.div<{ isSimpleQuoteTheme?: boolean }>`
  flex-direction: row;
  justify-content: stretch;
  border: 1px solid ${({ theme }) => theme.colors.lightGreyBorder};
  border-bottom-width: 1px;
  font-size: 16px;
  flex-shrink: 0;
  ${({ isSimpleQuoteTheme }) => {
    if (isSimpleQuoteTheme) {
      return css`
        display: block;
        border-radius: 5px;
        overflow: visible;
      `;
    } else {
      return css`
        display: flex;
        border-radius: 2px;
        overflow: visible;
      `;
    }
  }}
`;

const TextInput = styled.input<{
  isSimpleQuoteTheme?: boolean;
  hasError?: boolean;
}>`
  flex: 1;
  border: 0;
  border: ${({ hasError }) => (hasError ? '1px solid #ff6364' : 0)};
  background-color: ${({ hasError }) => (hasError ? '#ffeded' : 'transparent')};

  &::placeholder {
    color: #bdbdbd;
    font-weight: normal;
  }
  ${({ isSimpleQuoteTheme }) => {
    if (isSimpleQuoteTheme) {
      return css`
        font-size: 16px;
        font-weight: normal;
        height: 36px;
        padding: 2px 16px 2px 36px;
        width: 100%;
        box-sizing: border-box;
        &::placeholder {
          line-height: 1;
        }
      `;
    } else {
      return css`
        font-weight: 300;
        padding: 13px 16px 13px 36px;
      `;
    }
  }}
`;

const SuggestionList = styled.div<{
  overflow?: boolean;
  quotePageStyle?: boolean;
  isSimpleQuoteTheme?: boolean;
}>`
  ${({ overflow }) =>
    overflow
      ? css`
          position: absolute;
          top: 46px;
        `
      : ''};

  list-style-type: none;
  margin-top: -1px;
  padding: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100px;
  overflow-y: auto;
  :first-child {
    border-top-width: 0px;
  }

  ${({ quotePageStyle }) =>
    quotePageStyle
      ? css`
          position: absolute;
          top: 50px;
          min-height: 0px;
          background-color: blue;
        `
      : ''};

  ${({ isSimpleQuoteTheme }) =>
    isSimpleQuoteTheme
      ? css`
          position: absolute;
          top: 36px;
          min-height: 0px;
        `
      : ''};
`;

const Suggestion = styled.div`
  ${({ theme }) => theme.fonts.text.fontWeight.light};
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.plainColor};
  border: 1px solid ${({ theme }) => theme.colors.lightGreyBorder};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  padding: 12px 12px 12px 8px;
  flex-shrink: 0;

  &:not(:first-child) {
    border-top: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueSectionBackground};
  }
`;

export default LocationAutoComplete;
