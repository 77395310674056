// @flow
import * as React from 'react';
import styled from 'styled-components';

import Col from './Col';

type ReactCol = React.Element<typeof Col>;

type Props = {
  children: React.ChildrenArray<ReactCol>,
  /**
   * The maximum width at which columns are stacked on top of each other,
   * rather than being shown side by side.
   */
  breakpoint?: number /* | Array<{ [key: string]: number }>*/,
  /**
   * Default is inline-block, but table mode can be useful as it makes both
   * columns equal height.  Used in e.g. position:sticky.
   */
  mode?: 'inline-block' | 'table',
  /**
   * Any style to pass on to the wrapper component
   */
  style?: Object,
};

/**
 * A container for multiple columns (must be Col elements)
 */
export default class Cols extends React.PureComponent<Props> {
  render() {
    const { children, mode, style } = this.props;

    const n = React.Children.count(children);
    let breakpoint = this.props.breakpoint || n * 400;

    let flexTotal = 0;
    React.Children.forEach(
      children,
      (col: ReactCol) => (flexTotal += col.props.flex || 0)
    );

    return (
      <Wrapper mode={mode} style={style}>
        {React.Children.map(children, (col: ReactCol) => {
          const colWidth =
            Math.floor(
              flexTotal ? ((col.props.flex || 1) / flexTotal) * 100 : 100 / n
            ) + '%';
          return (
            <Col
              width={colWidth}
              breakpoint={breakpoint}
              mode={mode}
              {...col.props}
            >
              {col.props.children}
            </Col>
          );
        })}
      </Wrapper>
    );
  }
}

const Wrapper = styled('div')`
  display: ${p => (p.mode === 'table' ? 'table' : 'block')};
  width: 100%;
`;
