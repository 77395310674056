/* eslint-env node */
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';
import fetch from 'node-fetch';
import { HOST } from '../config';

import { isBrowser } from '../util';

let apolloClient = null;
const create = initialState => {
  let uri = `https://${HOST}/api/v2/graphql`;

  if (HOST.includes('localhost')) {
    uri = 'http://localhost:8000/api/v2/graphql';
  }

  return new ApolloClient({
    link: new HttpLink({ uri, fetch }),
    ssrMode: !isBrowser(),
    cache: new InMemoryCache().restore(initialState || {}),
  });
};

const initApollo = initialState => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
};

export default initApollo;
