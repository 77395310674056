import * as React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#7C88A3',
    color: 'white',
    fontSize: 12,
  },
}))(Tooltip);

const TooltipButton = ({ text = '' }) => {
  return (
    <CustomTooltip title={text}>
      <img src="/img/info-button.svg" />
    </CustomTooltip>
  );
};

TooltipButton.propTypes = {
  text: PropTypes.string,
};

export default TooltipButton;
