// @flow
import styled from 'styled-components';

export default styled.div`
  height: 70px;
  border-radius: 70px;
  background-color: #e9f1f3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;

  & > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
