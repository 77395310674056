function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      flex-direction: row;\n\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import * as React from 'react';
import styled from 'styled-components';
import { flexDirection, justifyContent, alignItems, space, flexWrap } from 'styled-system';
import { mediaQueries } from '../../variables';
var Wrapper = styled.section.withConfig({
  displayName: "Section__Wrapper",
  componentId: "f2260i-0"
})(["width:100vw;padding:40px 20px;box-sizing:border-box;& > div{display:flex;flex-direction:column;margin:0 auto;box-sizing:border-box;width:100%;max-width:1260px;", ";", ";", ";", ";", ";", ";}"], mediaQueries.medium(_templateObject(), flexDirection, justifyContent, alignItems, flexWrap), flexDirection, justifyContent, alignItems, space, flexWrap);

var Section = function Section(_ref) {
  var children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(Wrapper, rest, React.createElement("div", null, children));
};

export default Section;