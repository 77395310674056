// @flow
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  /**
   * Similar to CSS flexbox, give the column a flex width
   */
  flex?: number,
  /**
   * CSS that only applies when the columns are joined side by side
   */
  joinedCss?: string,
  /**
   * CSS that only applies when the breakpoint has been reached and columns are
   * stacked vertically.
   */
  brokenCss?: string,
  float?: 'left' | 'right',
  children?: React.Node,

  // All the following props come from Cols setting on direct children, and
  // should not be set directly
  width?: string,
  breakpoint?: number,
  mode?: 'inline-block' | 'table',
};

/**
 * A single column
 */
const Col: React.ComponentType<Props> = styled('div')`
  box-sizing: border-box;
  display: ${(p: Props) =>
    p.mode === 'table' ? 'table-cell' : 'inline-block'};
  vertical-align: top;
  width: ${(p: Props) => p.width || 'auto'};

  @media (min-width: ${(p: Props) => p.breakpoint + 1}px) {
    ${(p: Props) => p.joinedCss};
    ${(p: Props) => (p.float ? `float: ${p.float}` : '')};
  }

  @media (max-width: ${(p: Props) => p.breakpoint}px) {
    display: block;
    width: auto;
    ${(p: Props) => p.brokenCss};
  }
`;

export default Col;
