// @flow
import * as React from 'react';
import styled from 'styled-components';

import { css } from '../util';

const List = styled('ul')`
  margin: 30px 0;
  padding: 0;
  list-style-type: none;
`;

export const Item = styled('li')`
  padding: 0;
  padding-left: 50px;
  margin: 12px 0;
  position: relative;

  ${p => p.fontSize === 'leading' && `${css.fontSize(20)};`};

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(/img/bullet.png);
    position: absolute;
    top: ${p => (p.fontSize === 'leading' ? '8' : '4')}px;
    left: 0;
  }

  @media (max-width: 520px) {
    padding-left: 30px;
  }
`;

type Props = {
  items: React.Node[],
  fontSize?: 'leading',
  className?: string,
  style?: Object,
};

/**
 * Note that dynamically adding/removing items is not supported, due to keys
 * just being indexes.
 */
export default function BulletList({
  items,
  fontSize,
  className,
  style,
}: Props) {
  return (
    <List className={className} style={style}>
      {items.map((item, i) => (
        <Item key={i} fontSize={fontSize}>
          {item}
        </Item>
      ))}
    </List>
  );
}
