// @flow
import EventManager, { events } from '/util/eventManager';

import { hash } from '/util';

import { SimpleKV } from '/components/QuoteForm/types';

export type code = string | undefined;
export type path = string | undefined;

let partnerCode: code;
let referrer: path;

// We're not allowed to send personal information in Events.

const disallowedKeys = [
  'decedentFirstName',
  'decedentLastName',
  'purchaserEmail',
  'purchaserFirstName',
  'purchaserLastName',
  'purchaserAddress',
  'purchaserCity',
  'purchaserState',
  'purchaserPhone',
  'subscriberEmail',
  'subscriberFirstName',
  'subscriberCity',
  'cardholderName',
  'cardNumber',
  'cardExpiration',
  'cardSecurityCode',
  'zipcode',
];

function keyCanBeSubmitted(key: string): boolean {
  if (disallowedKeys.includes(key)) {
    return false;
  }
  return true;
}

// All Quote Form Events need 2 extra datapoints: Version and Varient
export const registerUserEvent = (event: string, data: SimpleKV) => {
  // WARNING - STUBBY VALUES!
  const version = 1;
  const variant = 'A';
  EventManager.registerEvent(event, {
    ...data,
    version,
    variant,
    partnerCode,
    referrer,
  });
};

export const sendFormContinuedEvent = (step: string) => {
  const eventData = { step };
  registerUserEvent(events.QUOTE_FLOW_CONTINUED, eventData);
};

export const sendFormStartedEvent = (
  sessionId: string,
  partner: code,
  ref: path
) => {
  partnerCode = partner;
  referrer = ref;
  const eventData = { sessionId };
  registerUserEvent(events.QUOTE_FLOW_STARTED, eventData);
};

export const sendStepStartedEvent = (step: string) => {
  const eventData = { step };
  // Using setTimeout here is a bit of a hack to get around the fact that
  // React resolves useEffects bottom-up, creating a situation where
  // stepStarted events were firing before the QuoteFlowStarted event
  setTimeout(() => {
    registerUserEvent(events.QUOTE_FLOW_STEP_STARTED, eventData);
  }, 0);
};

export const sendStepPresetSkippedEvent = (step, presetData) => {
  const eventData = { step, ...presetData };
  // Using setTimeout here is a bit of a hack to get around the fact that
  // React resolves useEffects bottom-up, creating a situation where
  // stepStarted events were firing before the QuoteFlowStarted event
  setTimeout(() => {
    registerUserEvent(events.QUOTE_FLOW_STEP_SKIPPED_PRESET, eventData);
  }, 0);
};

export const sendStepBackEvent = (step: string, stepTo: string) => {
  const eventData = { step, stepTo };
  registerUserEvent(events.QUOTE_FLOW_STEP_BACK, eventData);
};

export const sendDataEnteredEvent = (step: string, rawInput: any) => {
  const { id } = rawInput;
  const canBeSubmitted = keyCanBeSubmitted(id);

  if (canBeSubmitted) {
    const eventData = {
      step,
      input: rawInput,
    };
    registerUserEvent(events.QUOTE_FLOW_STEP_DATA_ENTERED, eventData);
  } else {
    return hash(id + '_tulip').then(formattedId => {
      const input = {
        id: formattedId,
        value: 'REDACTED',
      };
      const eventData = {
        step,
        input,
      };
      registerUserEvent(events.QUOTE_FLOW_STEP_DATA_ENTERED, eventData);
    });
  }
};

export const sendStepCompletedEvent = (step: string, rawInput: any) => {
  let input;
  if (typeof rawInput === 'object') {
    input = Object.keys(rawInput).reduce((acc: any, id: string) => {
      const canBeSubmitted = keyCanBeSubmitted(id);
      if (canBeSubmitted) {
        acc[id] = rawInput[id];
      }
      return acc;
    }, {});
  } else {
    input = rawInput;
  }
  const eventData = {
    step,
    input,
  };
  registerUserEvent(events.QUOTE_FLOW_STEP_COMPLETED, eventData);
};

export const sendDecedentComplemetedEvent = (rawInput: any) => {
  registerUserEvent(events.DECEDENT_COMPLETED, rawInput);
};

export const sendStartQuestionnaireEvent = () => {
  registerUserEvent(events.QUESTIONNAIRE_STARTED, {});
};

export const sendQuestionnaireEvent = (id, buttonClicked) => {
  if (buttonClicked === 'back') {
    registerUserEvent(events.QUESTIONNAIRE_BACK, { id });
  } else if (buttonClicked === 'skip') {
    registerUserEvent(events.QUESTIONNAIRE_SKIP, { id });
  } else {
    registerUserEvent(events.QUESTIONNAIRE_CONTINUE, { id });
  }
};
