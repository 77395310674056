// @flow
import React from 'react';
import styled from 'styled-components';

type Props = {
  content?: Array,
};

const Wrapper = styled.div`
  margin-top: 32px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.span`
  color: ${({ theme }) => theme.textHard};
  font-size: 16px;
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-top: 4px;
`;

const P = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.grayBlue};
  line-height: 26px;
  margin: 0;
`;

export default function ContactCard({ content }: Props) {
  return content.length
    ? content.map((item, i) => (
        <Wrapper key={i}>
          {item.title && (
            <Row>
              <IconContainer>
                <img src="/img/contact.svg" />
              </IconContainer>
              <Heading>{item.title}</Heading>
            </Row>
          )}
          {item.address && (
            <Row>
              <IconContainer>
                <img src="/img/address.svg" />
              </IconContainer>
              <P dangerouslySetInnerHTML={{ __html: item.address }} />
            </Row>
          )}
          {item.phone && (
            <Row>
              <IconContainer>
                <img src="/img/call.svg" />
              </IconContainer>
              <P>{item.phone}</P>
            </Row>
          )}
        </Wrapper>
      ))
    : null;
}
