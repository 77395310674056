function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    height: 70px;\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
import { width, space } from 'styled-system';
import { mediaQueries } from '../../variables';
var Input = styled.input.withConfig({
  displayName: "Input",
  componentId: "oaf3p1-0"
})(["background:#ffffff;border:1px solid #b3bcc4;border-radius:2px;box-sizing:border-box;font-size:16px;color:#4f6378;line-height:21px;height:50px;padding:0 20px;", ";", ";&:focus{box-shadow:0 2px 4px 0;outline:0;}", ";"], width, space, mediaQueries.medium(_templateObject()));
export default Input;