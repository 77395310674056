import * as React from 'react';

var Arrow = function Arrow(_ref) {
  var _ref$rotation = _ref.rotation,
      rotation = _ref$rotation === void 0 ? 0 : _ref$rotation,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? '#4F6378' : _ref$color,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? '28px' : _ref$width,
      _ref$height = _ref.height,
      height = _ref$height === void 0 ? '14px' : _ref$height;
  return React.createElement("svg", {
    width: width,
    height: height,
    viewBox: "0 0 28 14",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd",
    strokeLinecap: "square"
  }, React.createElement("g", {
    transform: "translate(-319.000000, -1207.000000)",
    fillRule: "nonzero",
    stroke: color
  }, React.createElement("g", {
    transform: "translate(0.000000, 1078.000000)"
  }, React.createElement("g", {
    transform: "translate(319.000000, 129.000000)"
  }, React.createElement("g", {
    transform: "rotate(".concat(rotation, ", 14, 7)")
  }, React.createElement("path", {
    d: "M0.5,0.5 L13.726462,12.7090418",
    id: "Line-2"
  }), React.createElement("path", {
    d: "M14.5,0.5 L27.726462,12.7090418",
    transform: "translate(21.113231, 6.604521) scale(-1, 1) translate(-21.113231, -6.604521) "
  })))))));
};

export default Arrow;