// @flow
import * as React from 'react';
import Link from 'components/Link';
import styled from 'styled-components';
import { withRouter } from 'next/router';
import { mediaQueries, Button } from 'tulip-ui';

import PhoneNumber from './PhoneNumber';
import QuoteButton from './QuoteButton';

const Container = styled.div`
  position: fixed;
  z-index: 90;
  top: -100%;
  bottom: 100%;
  left: 0;
  right: 0;
  transition: top 0.2s ease, opacity 0.2s ease;
  background-color: #e9f1f2;
  opacity: 0;

  overflow: auto;

  ${p =>
    p.visible &&
    `
    top: 65px;
    bottom: 0;
    opacity: 1;
  `};

  ${mediaQueries.xlarge`
    top: -100% !important;
    bottom: 100%;
  `};
`;

const BaseItem = styled.div`
  height: 105px;
  box-sizing: border-box;
  padding: 0 20px;
`;

const LinkItem = styled(BaseItem)`
  border-bottom: 1px #d3e4e5 solid;
  background-color: #fff;
  display: table;
  width: 100%;
  text-align: center;

  > a {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    transition: color 0.1s;

    &:hover {
      color: ${({ theme }) => theme.textSpecial};
    }
  }
`;

const Arrow = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  border: solid 2px ${({ isOpen }) => (isOpen ? '#24c9c4' : '#7c88a3')};
  border-top: 0;
  border-right: 0;
  position: absolute;
  right: 30px;
`;

const Expandible = styled.div`
  height: ${({ isOpen, children }) => (isOpen ? children.length * 105 : 0)}px;
  transition: height 500ms ease;

  overflow: hidden;
`;

const ExpandItem = styled(LinkItem)`
  display: flex;

  justify-content: center;
  align-items: center;
`;

const SubItem = styled(LinkItem)`
  background-color: #e9f1f2;
`;

const ButtonItem = styled.div`
  background-color: #e9f1f2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  &:last-child {
    margin-bottom: 20px;
  }

  > a {
    display: block;
  }
`;

type SpanishMenuProps = {
  visible: boolean,
  onDismiss: () => void,
};

export const SpanishMenu = ({ visible, onDismiss }: SpanishMenuProps) => (
  <Container visible={visible} onClick={onDismiss}>
    <ButtonItem>
      <PhoneNumber
        phoneNumber={{ formatted: '(844) 238-3448', normal: '8442383448' }}
        render={({ formatted, href }) => (
          <Button href={href} label={`Hablenos a ${formatted}`} />
        )}
      />
    </ButtonItem>
    <ButtonItem>
      <Button href="/en" tertiary label="English" />
    </ButtonItem>
  </Container>
);

type Props = {
  visible: boolean,
  onDismiss: () => void,
  router: {
    pathname: string,
  },
};

type State = {
  showPricingOptions: boolean,
  showPlanAheadOptions: boolean,
  showResourcesOptions: boolean,
};

class FullScreenMenu extends React.PureComponent<Props, State> {
  state = {
    showPricingOptions: false,
    showPlanAheadOptions: false,
    showResourcesOptions: false,
  };

  toggleShowPricingOptions = () =>
    this.setState(s => ({ showPricingOptions: !s.showPricingOptions }));

  toggleShowPlanAheadOptions = () =>
    this.setState(s => ({ showPlanAheadOptions: !s.showPlanAheadOptions }));

  toggleShowResourcesOptions = () =>
    this.setState(s => ({ showResourcesOptions: !s.showResourcesOptions }));

  render() {
    const {
      visible,
      onDismiss,
      router: { pathname },
    } = this.props;
    return (
      <Container visible={visible} onClick={onDismiss}>
        <LinkItem>
          <Link to="/our-service">Our service</Link>
        </LinkItem>
        <LinkItem>
          <Link to="/pricing">Pricing</Link>
        </LinkItem>
        <ExpandItem
          onClick={e => {
            e.stopPropagation();
            this.toggleShowPlanAheadOptions();
          }}
        >
          <div />
          <a>Plan ahead</a>
          <Arrow isOpen={this.state.showPricingOptions} />
        </ExpandItem>
        <Expandible isOpen={this.state.showPlanAheadOptions}>
          <SubItem>
            <Link to="/plan-ahead">Prepaid plans</Link>
          </SubItem>
          <SubItem>
            <Link to="/soon">Imminent need</Link>
          </SubItem>
        </Expandible>
        <LinkItem>
          <Link to="/locations">Service area</Link>
        </LinkItem>
        <ExpandItem
          onClick={e => {
            e.stopPropagation();
            this.toggleShowResourcesOptions();
          }}
        >
          <div />
          <a>Resources</a>
          <Arrow isOpen={this.state.showResourcesOptions} />
        </ExpandItem>
        <Expandible isOpen={this.state.showResourcesOptions}>
          <SubItem>
            <Link to="/resources/">Tulip Cares</Link>
          </SubItem>
          <SubItem>
            <Link to="/resources/professionals/">Tulip Cares Professional</Link>
          </SubItem>
          <SubItem>
            <Link to="/faq">FAQs</Link>
          </SubItem>
        </Expandible>

        <LinkItem>
          <Link to="/reviews">Reviews</Link>
        </LinkItem>
        <LinkItem>
          <Link to="https://www.tulip.joinobit.com/">Obituaries</Link>
        </LinkItem>
        <ExpandItem
          onClick={e => {
            e.stopPropagation();
            this.toggleShowPricingOptions();
          }}
        >
          <div />
          <a>Resources</a>
          <Arrow isOpen={this.state.showPricingOptions} />
        </ExpandItem>
        <Expandible isOpen={this.state.showPricingOptions}>
          <SubItem>
            <Link to="/faq">FAQs</Link>
          </SubItem>
          <SubItem>
            <a href="https://resources.tulipcremation.com">Articles</a>
          </SubItem>
          <SubItem>
            <a href="/cremation-guide/cremation-cost">Cremation costs</a>
          </SubItem>
          <SubItem>
            <a href="/cremation-guide/death-certificates">
              Death certificate information
            </a>
          </SubItem>
          <SubItem>
            <a href="/cremation-guide/direct-cremation">
              Direct cremation guide
            </a>
          </SubItem>
        </Expandible>
        <ButtonItem>
          <PhoneNumber
            phoneNumber={
              pathname === '/home'
                ? {
                    formatted: '(844) 445-3655',
                    normal: '8444453655',
                  }
                : null
            }
            render={({ formatted, href }) => (
              <Button href={href} secondary label={`Call ${formatted}`} />
            )}
          />
        </ButtonItem>
        <ButtonItem>
          <QuoteButton source="FullScreenMenu" />
        </ButtonItem>
      </Container>
    );
  }
}

export default withRouter(FullScreenMenu);
