import React, { useState } from 'react';

import * as utils from '/util/registerEvent';

import { SimpleKV, QuoteData } from '../types';

export interface FormDataProps {
  presets: { [key: string]: any };
  quoteData: QuoteData;
  bulkUpdateQuoteData: (bulk: { [key: string]: any }, source: string) => void;
  updateQuoteData: (key: string, value: any, source: string) => void;
  getQuoteRequestData: () => QuoteData;
}

export const DefaultQuoteData: SimpleKV = {
  isPurchaserBeneficiary: undefined,
  purchaseType: undefined,
  county: undefined,
  countyRef: undefined,
  serviceAvailable: undefined,
  purchaserEmail: undefined,
  purchaserFirstName: undefined,
  purchaserLastName: undefined,
  purchaserPhone: undefined,
  decedentLocationType: undefined,
  decedentOverweight: undefined,
  decedentImplant: undefined,
  state: undefined,
  dateOfBirth: undefined,
  serviceTimelineImminent: false,
  purchaserRelationship: undefined,
};

type PresetValues = SimpleKV;

function getInitialQuoteData(props: any = {}) {
  return Object.keys(DefaultQuoteData).reduce((acc: SimpleKV, key) => {
    if (props[key] !== undefined) {
      acc[key] = props[key];
    } else {
      acc[key] = DefaultQuoteData[key];
    }
    return acc;
  }, {});
}

function recordUpdate(id: string, value: any, source: string) {
  utils.sendDataEnteredEvent(source, { id, value });
}

export const QuoteFormDataContext = React.createContext({
  presets: {},
  quoteData: DefaultQuoteData,
  updateQuoteData: (_a: string, _b: any, _c: string) => {},
  bulkUpdateQuoteData: (_a: SimpleKV, _b: string) => {},
  getQuoteRequestData: () => {
    return {};
  },
});

// Props are used to populate Presets
export const QuoteFormFormDataProvider = (props: PresetValues) => {
  const initialQuoteData = getInitialQuoteData(props);
  const [quoteData, setQuoteData] = useState(initialQuoteData);

  const [presets] = useState(props);
  const formDataProps: FormDataProps = {
    quoteData,
    updateQuoteData: (key: string, value: any, source: string) => {
      if (typeof value === 'string') value = value.trim();
      let newQuoteData = Object.assign({}, quoteData, { [key]: value });
      setQuoteData(newQuoteData);
      // We fall back to using the key as a source for event tracking
      const updateSource = source || key;
      recordUpdate(key, value, updateSource);
    },
    // it's also possible to set multiple keys at the same time
    bulkUpdateQuoteData: (bulkData: SimpleKV, source: string) => {
      let newQuoteData = Object.assign({}, quoteData, bulkData);
      setQuoteData(newQuoteData);

      Object.keys(bulkData).forEach(key => {
        const value = bulkData[key];
        utils.sendDataEnteredEvent(source || key, { id: key, value });
      });
    },
    // Not all QuoteData is pertinent to our request for a new Quote and the
    // endpoint will refuse irrelevant data.
    getQuoteRequestData: () => {
      let formattedRequest: QuoteData = { ...quoteData };
      delete formattedRequest.serviceAvailable;
      return formattedRequest;
    },
    // Presets can be used by steps to automatically skip pre-answered questions
    presets,
  };

  return <QuoteFormDataContext.Provider value={formDataProps} {...props} />;
};

export default QuoteFormFormDataProvider;
