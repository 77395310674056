// @flow
import React, { useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, Polygon } from 'react-google-maps';

type Props = {
  center?: Object,
  zoom?: Number,
  countyPolygons?: Array,
};

const RegionMap = ({ center, zoom, countyPolygons }: Props) => {
  const [mapHeight, setMapHeight] = useState('700px');

  useEffect(() => {
    // Set map height to viewport minus fixed header.
    const header = document.getElementsByTagName('header')[0];
    const headerHeight = header ? header.offsetHeight : 0;
    const windowHeight = window.innerHeight;
    setMapHeight(`${windowHeight - headerHeight}px`);
  }, []);

  const GoogleMapExample = withGoogleMap(() => (
    <GoogleMap
      defaultCenter={center}
      defaultZoom={zoom}
      defaultOptions={{
        disableDefaultUI: true,
        fullscreenControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: true,
        // Styling to make the logo white.
        // https://mapstyle.withgoogle.com/
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels.text.stroke',
            stylers: [],
          },
        ],
      }}
    >
      {countyPolygons.map((polygon, index) => (
        <Polygon
          key={index}
          paths={polygon}
          options={{
            strokeColor: '#ffffff',
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: '#333333',
            fillOpacity: 0.4,
          }}
        />
      ))}
    </GoogleMap>
  ));

  return (
    <div>
      <GoogleMapExample
        containerElement={<div style={{ height: mapHeight, width: '100%' }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default RegionMap;
