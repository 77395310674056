// @flow
import React from 'react';
import styled from 'styled-components';
import { AccordionItem, Heading } from '../../components';

import { BREAKPOINTS } from '../../globalStyles';
import BulletListV2 from '../BulletListV2';

type Props = {
  title?: string,
  anchor?: string,
  titleStyle?: String,
  textAlign?: string,
  body?: string,
  image?: object,
  items?: Array,
  boldParagraph?: Boolean,
  bulletList?: Boolean,
  weTakeCareOf?: Boolean,
  secondParagraph?: Boolean,
  regionName?: String,
};

export default function AccordionSection({
  textAlign,
  title,
  anchor,
  titleStyle,
  body,
  image,
  boldParagraph,
  bulletList,
  weTakeCareOf,
  secondParagraph,
  items,
  regionName,
}: Props) {
  const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: ${textAlign === 'left' ? 'row-reverse' : 'row'};
    justify-content: space-between;
    padding: 80px 70px 20px 70px;
    max-width: 1600px;
    margin: 0 auto 120px auto;

    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      margin: 0 auto 0 auto;
      display: flex;
      padding: 96px 16px;
      flex-direction: ${textAlign === 'left' ? 'column-reverse' : 'column'};
    }
  `;

  const BoldParagraph = styled.div`
    font-family: 'Brown';
    font-size: 27px;
    font-weight: 400;
    color: #243965;
    margin: 55px 0;
  `;

  const FeaturedImage = styled.img`
    width: 100%;
  `;

  const ImageContainer = styled.div`
    width: 600px;
    margin-left: ${textAlign === 'left' ? '100px' : '0'};
    margin-right: ${textAlign === 'left' ? '0' : '100px'};

    @media (max-width: ${BREAKPOINTS.XXLARGE}px) {
      max-width: 500px;
    }

    @media (max-width: ${BREAKPOINTS.XLARGE}px) {
      max-width: 410px;
    }

    @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
      margin-top: ${textAlign === 'left' ? '64px' : '0'};
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  `;

  const TextWrapper = styled.div`
    flex-basis: 49%;
    margin-top: 60px;
    padding-right: 100px;

    @media (max-width: ${BREAKPOINTS.XLARGE}px) {
      margin-top: ${textAlign === 'left' ? '0' : '32px'};
      padding-right: 0px;
    }
  `;

  const P = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 32px;
    color: #7c88a3;

    @media (max-width: ${BREAKPOINTS.XLARGE}px) {
      line-height: 26px;
    }
  `;

  const WeTakeCareOf = styled.p`
    font-family: 'Brown';
    font-size: 16px;
    font-weight: 400;
    color: #243965;
    margin: 20px 0;
  `;

  const H2 = styled(Heading)`
    margin: 0;
    font-size: 37px;
    margin-bottom: 30px;

    @media (max-width: ${BREAKPOINTS.LARGE}px) {
      font-size: 26px;
      margin-bottom: 30px;
    }
  `;

  const H5 = styled(Heading)`
    margin: 0;
    font-size: 37px;
    margin-bottom: 40px;

    @media (max-width: ${BREAKPOINTS.LARGE}px) {
      font-size: 23px;
      margin-bottom: 30px;
    }
  `;
  return body ? (
    <Wrapper id={anchor}>
      <ImageContainer>
        <FeaturedImage src={image.src} alt={image.alt ? image.alt : title} />
      </ImageContainer>
      <TextWrapper>
        {titleStyle === 'lg' ? (
          <H2 tag="h2">{title}</H2>
        ) : (
          <H5 tag="h5">{title}</H5>
        )}
        <P>{body}</P>
        {secondParagraph && regionName === 'Washington' ? (
          <>
            <P>
              We understand how difficult it is to lose a loved one, and at
              Tulip our top priority is ensuring your peace of mind. We provide
              the simplest and most dignified direct cremation service in{' '}
              {`${regionName}`}. Our licensed funeral directors and dedicated
              Family Care Team are here to help you every step of the way,
              whenever you need us.
            </P>
            <P>
              Whether you are planning for a loved one who has already passed,
              or researching pre-need services for yourself, we can help. With
              Tulip, you can make all arrangements online or over the phone, on
              your schedule and from the comfort of your own home, so you never
              need to come in to visit us or drive across town to meet with a
              funeral director. When you’re ready to learn more, call us and we
              can walk you through the process.
            </P>
          </>
        ) : secondParagraph &&
          (regionName === 'SoCal' || regionName === 'GulfCoast') ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind. We provide the
            simplest and most dignified direct cremation service in{' '}
            {`${regionName}`}. Our licensed funeral directorsand dedicated
            Family Care Team are here to help you every step of the way,
            whenever you need us. With Tulip, you can make all arrangements
            online or over the phone, on your schedule and from the comfort of
            your own home.
          </P>
        ) : secondParagraph && regionName === 'Colorado' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring yourpeace of mind. We provide the
            simplest and most dignified direct cremation service in Colorado.
            Our licensed funeral directors and dedicated Family Care Team are
            here to help you every step of the way, whenever you need us.
            Whether you are planning for a loved one who has already passed, or
            researching pre-need services for yourself, we can help. With Tulip,
            you can make all arrangements online or over the phone, on your
            schedule and from the comfort of your own home, so you never need to
            come into visit us or drive across town to meet with a funeral
            director. When you’re ready to learn more,call us and we can walk
            you through the process.
          </P>
        ) : secondParagraph && regionName === 'Oregon - Portland' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring yourpeace of mind. We provide the
            simplest and most dignified direct cremation service in Oregon. Our
            licensed funeral directors and dedicated Family Care Team are here
            to help you every stepof the way, whenever you need us. Whether you
            are planning for a loved one who has already passed, or researching
            pre-need services for yourself, we can help. With Tulip, you can
            make all arrangements online or over the phone, on your schedule and
            from the comfort of your own home, so you never need to come into
            visit us or drive across town to meet with a funeral director. When
            you’re ready to learn more, call us and we can walk you through the
            process.
          </P>
        ) : secondParagraph && regionName === 'Minnesota' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind while planning for
            the funeral. We provide the simplest and most dignified
            directcremation service in Minnesota. Our licensed funeral directors
            and dedicated Family Care Teamare here to help you every step of the
            way, whenever you need us. Whether you are planning cremation for a
            loved one who has already passed, or researching pre-need cremation
            for yourself, we can help. With Tulip, you can make all funeral
            arrangements online or over the phone, on your schedule and from the
            comfort of your own home, so you never need to come in to visit us
            or drive across town to meet with a funeral director or organize
            your service. Once the cremation is complete, we safely return your
            loved one’s remains to your home, so you never have to come pick
            them up.If you have any questions about cremation or the funeral
            service planning process, we are here for you day and night. When
            you’re ready to learn more or begin planning cremation, call us
            andwe can walk you through the process.
          </P>
        ) : secondParagraph && regionName === 'Northern California' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind. We provide the
            simplest and most dignified direct cremation service in California.
            Our licensed funeral directors and dedicated Family Care Team are
            here to help you every step of the way, whenever you need us.
            Whether you are planning for a loved one who has already passed, or
            researching pre-need services for yourself, we can help. With Tulip,
            you can make all arrangements online or over the phone, on your
            schedule and from the comfort of your own home, so you never need to
            come into visit us or drive across town to meet with a funeral
            director. When you’re ready to learn more, call us and we can walk
            you through the process.
          </P>
        ) : secondParagraph && regionName === 'Wisconsin' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind while planning for
            the funeral. We provide the simplest and most dignified direct
            cremation service in Wisconsin. Our licensed funeral directors and
            dedicated Family Care Team are here to help you every step of the
            way, whenever you need us. Whether you are planning cremation for a
            loved one who has already passed, or researching pre-need cremation
            for yourself, we can help. With Tulip, you can make all funeral
            arrangements online or over the phone, on your schedule and from the
            comfort of your own home, so you never need to come in to visit us
            or drive across town to meet with a funeral director or organize
            your service. Once the cremation is complete, we safely return your
            loved one’s remains to your home, so you never have to come pick
            them up. If you have any questions about cremation or the funeral
            service planning process, we are here for you day and night. When
            you’re ready to learn more or begin planning cremation, call us and
            we can walk you through the process.
          </P>
        ) : secondParagraph && regionName === 'Idaho' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind while planning for
            the funeral. We provide the simplest and most dignified direct
            cremation service in Idaho. Our licensed funeral directors and
            dedicated Family Care Team are here to help you every step of the
            way, whenever you need us. Whether you are planning cremation for a
            loved one who has already passed, or researching pre-need cremation
            for yourself, we can help. With Tulip, you can make all funeral
            arrangements online or over the phone, on your schedule and from the
            comfort of your own home, so you never need to come in to visit us
            or drive across town to meet with a funeral director or organize
            your service. Once the cremation is complete, we safely return your
            loved one’s remains to your home, so you never have to come pick
            them up. If you have any questions about cremation or the funeral
            service planning process, we are here for you day and night. When
            you’re ready to learn more or begin planning cremation, call us
            andwe can walk you through the process.
          </P>
        ) : secondParagraph && regionName === 'Arizona' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind. We provide the
            simplest and most dignified direct cremation service in Arizona. Our
            licensed funeral directors and dedicated Family Care Team are here
            to help you every step of the way, whenever you need us. Whether you
            are planning for a loved one who has already passed, or researching
            pre-need services for yourself, we can help. With Tulip, you can
            make all arrangements online or over the phone, on your schedule and
            from the comfort of your own home, so you never need to come in to
            visit us or drive across town to meet with a funeral director. When
            you’re ready to learn more, call us and we can walk you through the
            process.
          </P>
        ) : secondParagraph && regionName === 'Nevada' ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind. We provide the
            simplest and most dignified direct cremation service in Nevada. Our
            licensed funeral directors and dedicated Family Care Team are here
            to help you every step of the way, whenever you need us. If you are
            planning for a loved one who has already passed, or researching
            pre-need services for yourself, we can help. With Tulip, you can
            make all arrangements online or over the phone, on your schedule and
            from the comfort of your own home, so you never need to come in to
            visit us or drive across town to meet with a funeral director. When
            you’re ready to learn more, call us and we can walk you through the
            process.
          </P>
        ) : secondParagraph ? (
          <P>
            We understand how difficult it is to lose a loved one, and at Tulip
            our top priority is ensuring your peace of mind. We provide the
            simplest and most dignified direct cremation service in{' '}
            {`${regionName}`}. Our licensed funeral directors and dedicated
            Family Care Team are here to help you every step of the way,
            whenever you need us. Whether you are planning for a loved one who
            has already passed, or researching pre-need services for yourself,
            we can help. With Tulip, you can make all arrangements online or
            over the phone, on your schedule and from the comfort of your own
            home, so you never need to come in to visit us or drive across town
            to meet with a funeral director. When you’re ready to learn more,
            call us and we can walk you through the process.
          </P>
        ) : null}
        {items
          ? items.map((item, i) => <AccordionItem {...item} key={i} />)
          : null}
        {boldParagraph ? (
          <BoldParagraph>
            Our direct cremation process takes place in the days immediately
            following your loved one’s passing.
          </BoldParagraph>
        ) : null}
        {weTakeCareOf ? <WeTakeCareOf>We take care of:</WeTakeCareOf> : null}
        {bulletList ? (
          <BulletListV2
            fontSize={16}
            imgUrl={'/img/bullet.png'}
            items={[
              'Transporting your loved one into our care',
              'Filing all paperwork',
              'Performing the cremation at the crematory',
              'Safely returning the ashes to you',
            ]}
            marginRight={-15}
            marginTop={5}
            style={{ color: 'var(--dusk)' }}
          />
        ) : null}
      </TextWrapper>
    </Wrapper>
  ) : null;
}
