// @flow
import React from 'react';
import styled from 'styled-components';

import { css } from '../util';

import Hcont from './Hcont';
import Heading from './Heading';

type Props = {
  url: string,
  text: string,
  title?: string,
  subText?: string,
  gravity?: string,
  padding?: string,
};

const Wrapper = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 0 30px;
  margin-top: 50px;
  margin-bottom: 15px;

  @media (max-width: 1300px) {
    margin-top: 0;
  }
`;

const Image = styled('div').attrs({
  role: 'img',
  'aria-label': ({ label }) => label && label,
})`
  position: absolute;
  height: 425px;
  top: 0;
  left: 0;
  right: 0;
  background: url(${p => p.url}) ${p => p.gravity};
  background-size: cover;
  background-color: ${({ theme }) => theme.bgBlue};

  @media (max-width: 660px) {
    height: 200px;
  }
`;

const HeadingBox = styled('div').attrs({
  className: 'heading-box',
})`
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background: #fff;
  margin: 265px auto 0;
  max-width: 620px;
  padding: ${props => props.padding};
  text-align: center;

  @media (max-width: 660px) {
    padding: 15px 30px;
    margin-top: 164px;
  }
`;

const SubHeading = styled('p')`
  ${css.fontSize(20)};
`;

export default function PicHeading({
  url,
  text,
  subText,
  title,
  padding = '20px 60px',
  gravity = 'center center',
}: Props) {
  return (
    <Hcont hPad={0}>
      <Wrapper>
        <Image label={title} url={url} gravity={gravity} />
        <HeadingBox padding={padding}>
          <Heading tag="h1" style={{ marginBottom: 0 }}>
            {text}
          </Heading>
          {subText && <SubHeading>{subText}</SubHeading>}
        </HeadingBox>
      </Wrapper>
    </Hcont>
  );
}
